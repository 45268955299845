import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import './clientdetail.css';
import queryString from 'query-string';
import PageTitle from '../page.title';
import { confirmAlert } from 'react-confirm-alert'; // Import
import Select from 'react-select'
import DatePicker from "react-datepicker";
import makeAnimated from 'react-select/animated';
import EnterOTP from '../enterotp/enter-otp.component'

const animatedComponents = makeAnimated();

class ClientDetail extends Component {

   state = {

      pageTitle: "Forgot Password",
      open: false,
      loginerrormsg: "",
      loginerrormsg: "",
      sendusername: "",
      modal: true,
      handleClose: false,
      modalIsOpen: false,
      setIsOpen: false,
      errorMessage: "",
      modalopen: false,
      otp: "",
      clientname: "",
      gstno: "",
      startdate: "",
      enddate: "",

      user: { username: "", password: "", token: "" },
      confirmPassword: "",
      successMessage: "",

   }
   componentDidMount() {
      this.getClientList();
      const pathname = this.props.location.pathname
      if (pathname.startsWith("/portal/clientdetail")) {
         //  const usern = this.props.match.params.use    <br />rname;
         //   console.log(usern);
         let url = this.props.location.search;
         let params = queryString.parse(url);
         //console.log(params["username"]);
         let user = { ...this.state.user }
         user.username = params["username"]
         user.token = params["token"]

         this.setState({ user });
      }


      /* if(userName && token){
        let user = { ...this.state.user }
         user.username=userName["username"]
         user.token=token
         console.log(user.token);
         this.setState({user});
       }
      
        */
   }
   handleOnChangePassword = (event) => {
      let user = { ...this.state.user }
      user.password = event.target.value;
      this.setState({ user });

   }
   handleOnChangeconfirmPassword = (event) => {
      const confirmPassword = event.target.value;
      this.setState({ confirmPassword: confirmPassword })

   }
   /* handleOnChangeoldPassword=(event)=>{
     let user = { ...this.state.user }
     user.oldpassword = event.target.value
     this.setState({ user });
     this.setState({ loginerrormsg: "" });
  //handle change events)
    }*/

   ResetPassword = (event) => {

      event.preventDefault();
      if (this.state.user["password"] != this.state.confirmPassword) {
         const errorMessage = "Please enter confirm_password same as new_password !"
         this.setState({ errorMessage });

      }
      else {
         const requestOptions = {
            method: 'POST',
            body: JSON.stringify(this.state.user),
            headers: {
               'Content-Type': 'application/json; charset=utf-8',
               'Accept': "application/json",
               'Authorization': "JWT-" + localStorage.getItem("ros_token")

            }
         };

         fetch(process.env.REACT_APP_ROS_BASE_URL + '/token/resetforgotpwd', requestOptions)
            .then(res => res.json())
            .then(res => this.afterSave(res))
            .catch(err => console.error(err));

      }
   }
   afterSave = (res) => {

      if (res["success"]) {
         sessionStorage.clear();
         return (
            confirmAlert({
               title: "Success", message: "Data Saved Successfully",
               buttons: [{ label: "ok", className: "confirm_btn_popup", onClick: () => this.closeModal() }]
            })
         );


      }
      else if (res["message"] === "TOKEN_ALREADYUSED") {
         const errorMessage = "Link Already Used!"
         this.setState({ errorMessage });
      }
   }
   closeModal() {
      this.props.history.push("portal/login")
   }

   render() {

      return (

         <React.Fragment>
            <PageTitle title={this.state.pageTitle} />


            {this.state.errorMessage && <h5 className="alert-danger mt-0 ml-2 mr-4"> {this.state.errorMessage} <span className="float-right pointer-cursor" onClick={() => { this.setState({ errorMessage: "" }) }}>&#x2716;</span> </h5>}
            {this.state.successMessage && <h5 className="alert-success mt-0 ml-2 mr-4"> {this.state.successMessage} <span className="float-right pointer-cursor" onClick={() => { this.setState({ successMessage: "" }) }}>&#x2716;</span> </h5>}

            <div className=" container h-100" >

               <div className=" d-flex justify-content-center h-100  ">
                  <div align="center" className="card row col-lg-8 mt-4 mb-4 pt-4 mt-0  pr-0 mr-0 pl-0 ml-0 d-flex justify-content-center">

                     <span className="  col-lg-12   pt-5 mt-0 mb-0  pr-0 mr-0 pl-0 ml-0 ">
                        {this.HideClientDetial()}
                        {this.ShowClientDetial()}

                        <br /><br />
                        <span className=" d-flex justify-content-center pt-2 mt-5 home_welcome ">Powered By Linuxmantra!</span>
                     </span>
                  </div>
               </div>

            </div>
         </React.Fragment>

      );
   }
   HideClientDetial = () => {
      let htmlele = [];
      console.log(this.state.showclientDetail)
      //this.setState({showclientDetail:'success'})
      if (!this.state.showclientDetail || this.state.showclientDetail === "unsuccess") {
         htmlele.push(
            <span>
               <div className='col-lg-6 pt-1'>
                  <span className=" col-lg-12 small h-30px pt-0 mt-0 pb-0 mb-0 pr-0 mr-0 pl-0 ml-0 ">

                     <label className="col-lg-12 row small text-bold" >Clients</label>
                     <Select
                        components={animatedComponents}

                        options={this.options} // Options to display in the dropdown
                        //maxSelectedLabels={3}
                        //options={[this.state.selectAllOption, ...this.state.client]} // Options to display in the dropdown
                        // isMulti
                        value={this.optionsSelect}
                        //options={this.state.client}
                        closeMenuOnSelect={false}
                        onSearch={this.searchClient}
                        onChange={this.clientsubmitChange}
                        // selectedValues={this.state.selectedClients} // Preselected value to persist in dropdown
                        // onSelect={this.onSelectClient} // Function will trigger on select event
                        // onRemove={this.onRemoveClient} // Function will trigger on remove event
                        // displayValue= "clientName" // Property name to display in the dropdown options
                        //showCheckbox={true}
                        //closeOnSelect={false}
                        //keepSearchTerm={true}
                        //showArrow={true}
                        // onChange={(e) => this.changeClientValue(e)}
                        //caseSensitiveSearch={true}
                        //resetSelectedValues={this.resetValues}
                        //ref={this.multiselectRef} 

                        className="  pt-0 pb-0 "
                     />
                  </span>
               </div>
               <div className="d-flex justify-content-center col-lg-12 mt-4 ">
                  {this.state.disableButton === false ? (<div className='col-lg-6 pt-4'>
                     <button className="btn login_btn " disabled onClick={(event) => this.SubmitbyClientName(event)}>Submit</button>
                  </div>) : (<div className='col-lg-6 pt-4'>
                     <button className="btn login_btn " onClick={(event) => this.SubmitbyClientName(event)}>Submit</button>
                  </div>)}

               </div>
            </span>
         )
      }
      return htmlele;
   }
   ShowClientDetial = () => {
      let htmlele = [];
      console.log(this.state.showclientDetail)
      //this.setState({showclientDetail:'success'})
      if (this.state.showclientDetail === 'success') {
         htmlele.push(
            <span>
               <div className='col-lg-12'><h3>AirIndia Client Details</h3></div>
               <div className=' row col-lg-12 mt-5'>
                  <div className='col-lg-6 pt-1'>
                     <span className=" col-lg-12 small h-30px pt-0 mt-0 pb-0 mb-0 pr-0 mr-0 pl-0 ml-0 ">

                        <label className="col-lg-12 row small text-bold" >Clients</label>
                        <Select
                           components={animatedComponents}

                           options={this.options} // Options to display in the dropdown
                           //maxSelectedLabels={3}
                           //options={[this.state.selectAllOption, ...this.state.client]} // Options to display in the dropdown
                           // isMulti
                           value={this.optionsSelect}
                           //options={this.state.client}
                           closeMenuOnSelect={false}
                           onSearch={this.searchClient}
                           onChange={this.clientsubmitChange}
                           // selectedValues={this.state.selectedClients} // Preselected value to persist in dropdown
                           // onSelect={this.onSelectClient} // Function will trigger on select event
                           // onRemove={this.onRemoveClient} // Function will trigger on remove event
                           // displayValue= "clientName" // Property name to display in the dropdown options
                           //showCheckbox={true}
                           //closeOnSelect={false}
                           //keepSearchTerm={true}
                           //showArrow={true}
                           // onChange={(e) => this.changeClientValue(e)}
                           //caseSensitiveSearch={true}
                           //resetSelectedValues={this.resetValues}
                           //ref={this.multiselectRef} 

                           className="  pt-0 pb-0 "
                        />
                     </span>
                  </div>
                  <div className='col-lg-6'>
                     <span className="col-lg-12 pt-0 mt-0 mb-0 pb-0 pr-0 mr-0 pl-1 ml-0">
                        <label className="col-lg-12 small text-bold ">OTP</label>
                        <input type="text" name="" className="form-control input_user"
                           placeholder="Please Enter OTP. " value={this.state.otp}
                           onChange={(event) => this.submitChange(event)}
                        />
                     </span>
                  </div>
               </div>


               <div className='col-lg-12 row mt-4'>
                  <div className='col-lg-4'>
                     <span className="col-lg-12 pt-0 mt-0 mb-0 pb-0 pr-0 mr-0 pl-1 ml-0">
                        <label className="col-lg-12 small text-bold ">Client GST Number</label>
                        <input type="text" name="" className="form-control input_user"
                           placeholder="Please Enter GST No. " value={this.state.gstno}
                           onChange={(event) => this.gstnosubmitChange(event)}
                        />
                     </span>
                  </div>
                  <div className='col-lg-4'>
                     <span className="col-lg-12 pt-0 mt-0 mb-0 pb-0 pr-0 mr-0 pl-1 ml-0">
                        <label className="col-lg-12 small text-bold">From</label>
                        <DatePicker className="form-control col-lg-11" dateFormat="dd-MM-yyyy" selected={this.state.startdate} datePickerType="single" popperPlacement="left-start" value={this.state.startdate} name="startdate" onChange={this.startdatesubmitChange} scrollableYearDropdown showYearDropdown />

                     </span>
                  </div>
                  <div className='col-lg-4'>
                     <span className="col-lg-12 pt-0 mt-0 mb-0 pb-0 pr-0 mr-0 pl-0 ml-0">
                        <label className="col-lg-12 small text-bold ">To</label>
                        <DatePicker className="form-control col-lg-11" dateFormat="dd-MM-yyyy" selected={this.state.enddate} datePickerType="single" popperPlacement="left-start" value={this.state.enddate} name="stopdate" onChange={this.stopdatesubmitChange} scrollableYearDropdown showYearDropdown />

                     </span>
                  </div>
               </div>
               <div className="d-flex justify-content-center col-lg-12 mt-4 ">
                  <div className='col-lg-6 pt-4'>
                     <button className="btn login_btn " onClick={(event) => this.SubmitRequest(event)}>Submit</button>
                  </div>
               </div>
            </span>
         )
      }

      return htmlele;
   }

   /////////////////////////////////////////////////////////


   state = {

      user: { username: "", password: "" },
      loginerrormsg: "",
      sendusername: "",
      modal: true,
      handleClose: false,
      modalIsOpen: false,
      setIsOpen: false,
      errorMessage: "",
      modalopen: false,
      request: "",


   }

   handleUserChangeEvents = (event) => {
      let user = { ...this.state.user }
      user.username = event.target.value
      this.setState({ user });
      this.setState({ loginerrormsg: "" });
      //handle change events
   }
   formatStartDate = (dateString) => {
      const dateObj = new Date(dateString);
      const formattedDate = dateObj.toLocaleDateString('en-GB', {
         day: '2-digit',
         month: 'short',
         year: 'numeric'
      });
      return formattedDate;
   };

   formatEndDate = (dateString) => {
      const dateObj = new Date(dateString);
      const formattedDate = dateObj.toLocaleDateString('en-GB', {
         day: '2-digit',
         month: 'short',
         year: 'numeric'
      });
      return formattedDate;
   };
   formatDate = (dateString) => {
      const dateParts = dateString.split(' ');
      const day = dateParts[0];
      const month = dateParts[1];
      const year = dateParts[2];

      const formattedDate = `${day}-${month}-${year}`;
      return formattedDate;
   };
   SubmitbyClientName(event) {
      this.setState({ disableButton: true })
      var formData = new FormData();
      formData.append("clientname", this.state.clientname?.label);

      let data = formData
      const requestOption = {
         method: "POST",
         body: data,
         headers: {
            // 'Authorization': "JWT-" + localStorage.getItem("ros_token"),
            // 'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json"
         }
      }
      let submit = ''
      if (this.state.clientname?.label === "Vistara") {
         submit = 'http://localhost:5001/vistarasubmit'

      } else {
         submit = 'http://localhost:5000/aisubmit'
      }
      // fetch(process.env.REACT_APP_ROS_BASE_URL + "/aisubmit" , requestOption)
      fetch(submit, requestOption)
         .then(response => response.json())
         .then(response => {
            // const filename =  response.headers.get('Content-Disposition').split('filename=')[1];
            console.log(response)

            // console.log(blob)
            if (response === 'Form submitted successfully') {
               console.log(this.state.clientname?.label)
               if (this.state.clientname?.label === "Vistara") {

                  this.props.history.push("/portal/enterotp");
               } else {
                  this.setState({ showclientDetail: 'success' })
                  console.log(this.state.showclientDetail)
                  this.setState({ errorMessage: "", successMessage: response });
                  this.setState({ disableButton: false })
               }


            } else {
               this.setState({ errorMessage: response, successMessage: "" });
               this.setState({ disableButton: false })
               this.setState({ showclientDetail: 'unsuccess' })
            }


         })
         .catch(err => console.error(err), this.setState({ disableButton: true }), this.formData = {});



      event.preventDefault();
      // handle submit events
   }
   SubmitRequest(event) {


      const requestOption = {
         method: "GET",
         body: JSON.stringify(),
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json"
         }
      }

      console.log(this.state.clientname?.label)
      if (this.state.clientname?.label === "") {
         this.state.clientname.label = undefined
      }
      if (this.state.otp === "") {
         this.state.otp = undefined
      }
      if (this.state.enddate === null) {
         this.state.enddate = undefined
      }
      if (this.state.startdate === null) {
         this.state.startdate = undefined
      }
      if (this.state.gstno === "") {
         this.state.gstno = undefined
      }
      if (this.state.clientname?.label === undefined) {
         var clientname = "&"
      } else {
         var clientname = "&clientname="
      }

      if (this.state.otp === undefined) {

      } else {
         var otp = "otp="
      }
      if (this.state.startdate === undefined) {
         var startdate = "&"
      } else {
         var startdate = "&startdate="
         var StartDate = this.formatStartDate(this.state.startdate)
         var startdates = this.formatDate(StartDate)




      }
      if (this.state.enddate === undefined) {
         var enddate = "&"

      } else {
         var enddate = "&enddate="
         var endDate = this.formatEndDate(this.state.enddate)
         var enddates = this.formatDate(endDate)
      }
      if (this.state.gstno === undefined) {
         var gstno = "&"

      } else {
         var gstno = "&gstno="
      }

      const queryString = otp + this.state.otp + clientname + this.state.clientname?.label + gstno + this.state.gstno + startdate + startdates + enddate + enddates

      fetch(process.env.REACT_APP_ROS_BASE_URL + "/Airindiadetails?" + queryString, requestOption)
         .then(response => {
            // const filename =  response.headers.get('Content-Disposition').split('filename=')[1];
            console.log(response)
            response.blob().then(blob => {
               console.log(queryString)
               if (response.status == 200) {
                  this.setState({ errorMessage: "", successMessage: "Details Successfully Submitted" });

               }
               if (response.status == 400) {
                  this.setState({ errorMessage: "failed to submitted", successMessage: "" });

               }
            });

         });


      event.preventDefault();
      // handle submit events
   }
   handleSubmitevents(event) {

      const requestOption = {
         method: "POST",
         body: JSON.stringify(this.state.user),
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json"
         }
      }
      fetch(process.env.REACT_APP_ROS_BASE_URL + "/token/generate-token", requestOption)
         .then(response => response.json())
         .then(res => this.setLoginInfo(res))
         .catch(err => {
            const loginerrormsg = "Server error!";
            this.setState({ loginerrormsg });
            console.log(err);
         });
      // handle submit events
   }

   openModal() {
      this.state.setIsOpen = !this.state.setIsOpen;
   }



   closeModal = (event) => {

      this.setState({ sendusername: "" });
      this.setState({ modalIsOpen: false })
      this.setState({ modalopen: false })
      this.setState({ errorMessage: "" })

   }
   setLoginInfo(res) {
      if (res["success"]) {
         const result = res["result"];
         localStorage.setItem("user", JSON.stringify({ username: result.username, name: result.name }));
         localStorage.setItem("ros_token", result.token);
         this.props.history.push("/portal/home")
      } else {
         const loginerrormsg = "Invalid user or password";
         this.setState({ loginerrormsg });
      }

   }

   submitChange = (event) => {

      this.state.otp = event.target.value
      this.setState({});
   }
   clientsubmitChange = (event) => {

      this.state.clientname = event
      console.log(this.state.clientname.label)
      this.setState({});
   }
   gstnosubmitChange = (event) => {

      this.state.gstno = event.target.value
      this.setState({});
   }
   startdatesubmitChange = (event) => {

      this.state.startdate = event
      this.setState({});
   }
   stopdatesubmitChange = (event) => {

      this.state.enddate = event
      this.setState({});
   }
   getClientList = () => {
      const requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json",
            // 'Authorization': "JWT-" + localStorage.getItem("ros_token")
         }
      };

      fetch(process.env.REACT_APP_ROS_BASE_URL + '/getclientName', requestOptions)
         .then(response => response.json())
         .then(res => this.refreshDataClientList(res))
         .catch(err => console.error(err));
   }

   refreshDataClientList = (res) => {
      console.log(res);
      let client = [];

      if (res["success"]) {

         client = res["results"];
         this.options = res["results"].map(c => ({ label: c.clientName, value: c.clientId }))

         // console.log(clients);
         // this.setPage(res["result"]);
         this.setState({ client });
      }
   }
   handlePasswordChange = (event) => {
      let user = { ...this.state.user }
      user.password = event.target.value
      this.setState({ user });
      this.setState({ loginerrormsg: "" });
   }
   handleChangeUsername = (event) => {
      event.preventDefault();
      let sendusername = event.target.value
      this.setState({ sendusername: sendusername });


   }


}

export default ClientDetail;