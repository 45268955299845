import React, { Component } from 'react';
import {Modal} from 'react-bootstrap';
import "./recon.css";
import { withRouter } from 'react-router'
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
/*import {tofix_2} from '../../common/utility.jsx'
import { currency } from '../../common/util';
import { MdCloudUpload } from "react-icons/md";

import { confirmAlert } from 'react-confirm-alert'; // Import
import { Multiselect } from 'multiselect-react-dropdown';
import Toggle from 'react-toggle'
import moment from "moment";
import Checkbox from '@material-ui/core/Checkbox';
import { each } from 'jquery';
import PageTitle from '../page.title'
import ReactTooltip from "react-tooltip";
import { Document, Page, pdfjs } from "react-pdf";
*/
class PDFViewComponent extends Component { 
   blankItem = {
      itemId: undefined, itemName: undefined,mrpPrice:undefined, itemImage:null,itemImageName: undefined, price: undefined,max:undefined,type:undefined,
      description: undefined, itemMetadata:undefined,category: { itemCategoryId: undefined }, subItems : [],
      mandatory : false, restaurantId:undefined, customized:undefined, options:undefined,TypeValue:undefined,availibilty:"A"
   }
   blankCate = {
      itemCategoryId : undefined
      
   }
   blakSubItem = {
      subItemName : undefined,price : 0, description : undefined 
   }

   state = {
      filerecord:{},
      pageTitle:"",
      successMessage:"",
      processStatus:false,
      enablenotification:"",
      sendnotificationvalue:"",
      source:"",
      pdfcsvfile:"",
      filename:"",
      fileType:"DSR",
      customization:false,
      selection:false,
      errorMessage:"",
      popUpModalShow:false,
      initRecord : this.blankItem,
      record: this.blankItem,
      _data:[],
      recordItemCategory: this.blankCate,
      recordSubItem: this.blakSubItem,
      recordSubItems: [],
      pdfFile:"",
      fileURL:""
   }
   popUpModalClose =() => {
      this.setState({popUpModalShow:false});
      this.props.onHide();  
   }


   componentDidMount() {
      this.onFileChange();
      this.openPDF(this.props.filename)
 //  console.log(this.props.data)
   }

   onFileChange =(event)=> { 
      // Update the state 
     // console.log(this.props);
    let files  = this.props.pdffilelink
     // console.log( files );
  /*let reader = new FileReader();
    reader.readAsDataURL(this.props.pdffilelink);
     // this.setState({ selectedFile:  }); 

   reader.onload=(e)=>{
    let pdf = this.state.pdf;

    let itemImageraw=e.target.result
    console.log(itemImageraw);
    var itemImagerawarray=itemImageraw.split(";");
     let  itemImagearray = itemImagerawarray[1].split(",");
      pdf = itemImagearray[1];
      console.log(pdf)*/
 this.setState({pdffile:files});
       
    } 
   render() { 
     
      return ( 
         <Modal {...this.props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <span className="clo-lg-12">{this.state.errorMessage && <h5 className="alert-danger mt-0 ml-2 mr-4"> {this.state.errorMessage} <span className="float-right pointer-cursor" onClick={() => { this.setState({ errorMessage: "" }) }}>&#x2716;</span> </h5>} </span>
         <Modal.Header closeButton>
         <span className="row col-lg-10 pt-1 pb-0 ml-0 pl-0 pr-0 mt-1 mb-0 mr-0 d-flex justify-content-center"> 
           <h5 className="text-dark text-bold"  >{this.props.headerTitle}  </h5>
           </span>
         </Modal.Header>
         <Modal.Body className=" pt-1 pb-2 ml-0 pl-0 pr-0 mr-0 " >
            <div style={{minHeight : '75vh'  }} className="row pt-1 pb-2 ml-0 pl-0 pr-0 mr-0 ">
            <div className="row col-lg-12 pt-1 pb-0 ml-0 pl-0 pr-0 mt-2 mb-0 mr-0 d-flex justify-content-center">        
            <iframe src={this.state.pdfFile}frameBorder="0" scrolling="auto" height="100%" width="100%" >{this.state.pdffile}</iframe>
            </div>
            
            </div>
            </Modal.Body>
            <Modal.Footer className="row modal-footer pt-0 mt-0 mb-2 pb-2 mb-0 no-bg d-flex justify-content-center">              
               <button className="btn btn-sm  confirm_btn_upload" onClick={()=> this.props.onHide()}>Close</button>
            </Modal.Footer>
         
            
          </Modal>
         
       );
   }
  


   openPDF=(fileName)=>{
      let filename= fileName+".pdf";
      const requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json",
            'Authorization' : "JWT-" + localStorage.getItem("ros_token")
         }
      };
   
     const queryString="filename="+filename 
      fetch(process.env.REACT_APP_ROS_BASE_URL + '/getpdffile?'+queryString, requestOptions)
      .then(response => {
        // const filename =  response.headers.get('Content-Disposition').split('filename=')[1];
         response.blob().then(blob => {
            const pdfFile = new Blob([blob], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(pdfFile);
            this.setState({fileURL})
            //let url = window.URL.createObjectURL(blob);
           // window.open(url);
            //let a = document.createElement('a');
            //a.click();
          // a.download = val+".pdf";
          // console.log(a)
       });

    });
}

}
 
export default withRouter(PDFViewComponent);

