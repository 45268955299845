import React, { Component } from 'react';
import './logout.css';


class Logout extends Component {
    container = React.createRef();


    state = {
        open: false,
      
      
      };
      handleButtonClick = () => {
         this.setState(state => {
           return {
            open: !state.open,
          };
        });
      };
      componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
       
       
     
    }
    componentWillUnmount() {
      document.removeEventListener("mousedown", this.handleClickOutside);
    }
    handleClickOutside = event => {
        if (this.container.current && !this.container.current.contains(event.target)) {
          this.setState({
            open: false,
          });
        }
      };
      
    
      

     handleChangePassEvents(){

     }

     getUser() {
      const user=JSON.parse(localStorage.getItem("user"));
      let username = "";
      if(user) {
        username=user["name"];
      }
      return username;
     }


    render() { 
        return (
                        <div  ref={this.container}>
                        
                        <button type="button" className="btn dropdown-toggle pl-0 ml-0 text-bold" onClick={this.handleButtonClick} >
                           {this.getUser()}
                         </button>
                           {this.state.open && (
                              <div className="ml-0 pl-0 row mt-0 Rectangle pt-0 mt-0 mb-0 pb-0">
                            <span className="ml-1 pl-1 mt-0 logout_btn pt-0 mb-0 pb-0 mr-0 pr-0  btn-sm col-10"   onClick={(event) => this.props.PassChange(event)}>Change Password</span>
                            <span  className="ml-1 pl-1 mt-0 logout_btn pt-0 mb-0 pb-0 mr-0 pr-0  btn-sm col-10"   onClick={(event) => this.props.logoutClick(event)}> Logout</span>
                        </div>  
  )}
                     </div>
                
       
          );
     }

}
export default Logout;