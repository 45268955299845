
import React, { Component } from "react";
import {Modal, Button,Row,Col} from 'react-bootstrap';
import { BsX } from "react-icons/bs";
import { BsChevronDown,BsChevronRight } from "react-icons/bs";
import { confirmAlert } from 'react-confirm-alert';
import {tofix_2} from '../../common/utility.jsx'
import PageTitle from '../page.title';
import Checkbox from '@material-ui/core/Checkbox';
//import MenuItemComponent from './menu.item.component';


class DataRulesComponent extends Component {
    blankSource = {
      sftp: undefined,email: undefined,url: undefined,username: undefined,password: undefined}
    state = {
        addModalShow :false ,
        reconciledata:false,
        createdashboard:false,
        createrole:false,
        reconrulevalue:"<Rule><MatchField> PNR </MatchField> <ValidateField> Amount </ValidateField></Rule>",
        createdashboardvalue:"",
        createrolevalue:"",
        sourceData:[],
        field : "-",
        data: [],
        name:"",
        source : "-",
        record : {...this.blankSource},
        pageTitle : "Data Source",
        headerTitle: "Category Details",
    }
    render() { 
        return(
            <React.Fragment>   
                <div className="row pl-1 ml-1 pr-1 mr-1 justify-content-center">  
                <div className="row border bg_color col-lg-12 pt-1 pb-2 ml-0 pl-0 mr-0 pr-0 mt-2 mb-1 mr-0 justify-content-center">
                <h2 className="col-lg-12 mt-0 pl-0 ml-2 pt-0 text-center" > Data Rules </h2>
                <span className="col-lg-12 mt-0 pt-0 ml-0 pl-1 text-primary text-center"><h3 className="text-primary text-center"> Add new Data Set </h3>  </span>    
                </div>
                <div className="row col-lg-12 bg_color card pt-0 mt-0 pb-2">
                <div className="row col-lg-12 pt-1 pb-0 ml-1 pl-1 mt-2 mb-0 mr-0 justify-content-center">
                <div className=" row col-lg-4 pt-0 pb-0 ml-1 pl-1 mt-0 mb-0 mr-1 pr-1 justify-content-center">
                <span className="control-label text-bold text-left col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0">DataSet Name</span>
            <input type="text" className="h-30px form-control col-lg-12 col-md-12 mb-2 col-sm-12 col-12 small text-sm required" required
               value={this.state.name} onChange={(event) => this.handleOnChangeName(event)}></input>
                <span className="control-label text-bold col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0">Data Sources </span>
                        <select className="h-30px form-control col-lg-12 col-md-12 col-sm-12 col-12 small mb-0 text-sm required" required
                           value={this.state.source} onChange={(event) => this.handleonChangeSource(event)}>
                            <option>-</option>
                           <option value="Linuxmantra-Invoices">Linuxmantra-Invoices</option>
                           <option value="Linuxmantra-Tickets">Linuxmantra-Tickets</option>
                           </select>
                           {this.loadTemplatesField()}
                  </div> 
                 
                 <div className="row col-lg-12 pt-0 pb-0 ml-0 pl-0 mt-0 mb-0 mr-0 pr-0 justify-content-center">
                <div className="col-4 pt-1 pb-2 ml-0 pl-0 mt-2 mb-1 mr-0">
                <div className="col-lg-12 form form-group form-inline mt-0 mb-0 pl-0 ml-0 pt-0 mt-0 mb-0 pb-0">
                           <Checkbox className="col-lg-2 col-md-2 col-sm-2 col-2 ml-0 pl-0" checked={this.state.reconciledata} onClick={(event) => this.handlereconcileDataClick(event)}/>
                            
                              <span className="col-lg-8 col-md-8 col-sm-8 col-8 ml-0 pl-0 pr-0 mr-0 text-dark text-left">Reconcile Data </span>
                  </div>
                {this.loadReconcileField()}

                <div className="col-lg-12 form form-group form-inline mt-0 mb-0 pl-0 ml-0 pt-0 mt-0 mb-0 pb-0">
                           <Checkbox className="col-lg-2 col-md-2 col-sm-2 col-2 ml-0 pl-0" checked={this.state.createrole} onClick={(event) => this.handleCreateRoleClick(event)}/>
                            
                              <span className="col-lg-8 col-md-8 col-sm-8 col-8 ml-0 pl-0 pr-0 mr-0 text-dark text-left">Create Role </span>
                  </div>
                {this.loadCreateRoleField()}

                <div className="col-lg-12 form form-group form-inline mt-0 mb-0 pl-0 ml-0 pt-0 mt-0 mb-0 pb-0">
                           <Checkbox className="col-lg-2 col-md-2 col-sm-2 col-2 ml-0 pl-0" checked={this.state.createdashboard} onClick={(event) => this.handleCreateDashboardClick(event)}/>
                            
                              <span className="col-lg-8 col-md-8 col-sm-8 col-8 ml-0 pl-0 pr-0 mr-0 text-dark text-left">Create Dashboard </span>
                  </div>
                {this.loadCreateDashboardField()}
               
                <div className="row col-lg-12 pt-0 pb-0 ml-0 pl-0 mt-2 mb-0 mr-0 justify-content-center">
                <button onClick={() => this.onSubmitHandle()} className="col-lg-4 pl-0 ml-0 mr-0 pr-0 pt-0 pb-0 mt-0 mb-0 btn btn-sm confirm_btn">Save</button>
                </div>
                </div>
                </div>
                </div>
            
                </div>
                
                </div>

                
         </React.Fragment> 
        )
    }

    handlereconcileDataClick = (event) => {
      
      let reconciledata = this.state.reconciledata
      console.log(reconciledata);
      reconciledata = !reconciledata;
      this.setState({reconciledata});
    }

    handleCreateRoleClick= (event) => {
      
      let createrole = this.state.createrole;
      console.log(createrole);
      createrole = !createrole;
      this.setState({createrole});
    }

    handleCreateDashboardClick = (event) => {
      
      let createdashboard = this.state.createdashboard
      console.log(createdashboard);
      createdashboard = !createdashboard;
      this.setState({createdashboard});
    }

    popField=(field)=>{
      let sourceData = this.state.sourceData;
      if(sourceData.includes(field))
      {
        sourceData.splice(field,1);
      }
      this.setState({ sourceData,source:"-"});
 
    }

    loadTemplatesField=()=>{
      let sourceData=this.state.sourceData;
      let htmlele =[];
      if(sourceData.length>0){
        for(let i=0;i<sourceData.length;i++){
          htmlele.push(
            <div className="bg_color row pl-0 ml-0 mr-0 pr-0 mt-2 pt-0 card col-lg-12">
              <span className="pl-0 ml-0 mr-0 pr-0 mt-2 pt-0 col-lg-12">
              <label className="pl-2 ml-0 mr-0 pr-0 col-lg-11">{sourceData[i]}</label>
              <span className="pl-0 ml-0 pr-0 mr-0 col-lg-1" onClick={() => this.popField(sourceData[i])} ><BsX/> </span>
              </span>
            </div>
          )
        }
      }
      return htmlele;
    }
    handleonChangeFields = (event) => {
      let field = { ...this.state.field }
      field=event.target.value;
      let templateFields=[];
      templateFields= this.addToTemplates(field);
      this.setState({ field,templateFields}) 
  }

  addToTemplates=(field)=>{
    let templateFields = this.state.templateFields;
    if(templateFields.includes(field))
    this.itemAlert();
    else{
      templateFields.push(field)
    }
    return templateFields;
  }

  itemAlert=()=>{
    confirmAlert({
       title: "Alert", message: "Item already exist",
       buttons: [{ label: "Ok", className:"confirm_btn_popup" ,onClick : ()  => {return false} } 
       ],
       });
      }
    
  
  loadReconcileField=()=>{
    let htmlele =[];
    let reconciledata = this.state.reconciledata 
    if (reconciledata){
      htmlele.push(
          <div>
        <span className="control-label small  text-bold  text-left col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0">Reconciliation Rules</span>
        <textarea rows="5" className=" form-control col-lg-12 col-md-12 mb-2 col-sm-12 col-12 small text-sm required" required
           value={this.state.reconrulevalue} onChange={(event) => this.handleOnChangeReconRule(event)}></textarea>
         
    </div>
      )
    }
    return htmlele;
  }
  handleOnChangeReconRule=(event)=>{
    let reconrulevalue = this.state.reconrulevalue
    reconrulevalue =event.target.value;
    this.setState({ reconrulevalue }) ;
  }

  loadCreateRoleField=()=>{
    let htmlele =[];
    let createrole= this.state.createrole 
    if (createrole){
      htmlele.push(
          <div>
        <span className="control-label small  text-bold  text-left col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0">Access Mapped to Role</span>
        <input type="textbox" className="h-30px form-control col-lg-12 col-md-12 mb-2 col-sm-12 col-12 small text-sm required" required
           value={this.state.createrolevalue} onChange={(event) => this.handleOnChangeCreateRole(event)}></input>
         
    </div>
      )
    }
    return htmlele;

  }

  handleOnChangeCreateRole=(event)=>{
    let createrolevalue = this.state.createrolevalue
    createrolevalue =event.target.value;
    this.setState({ createrolevalue }) ;
  }

  loadCreateDashboardField=()=>{
    let htmlele =[];
    let createdashboard = this.state.createdashboard 
    if (createdashboard){
      htmlele.push(
          <div>
        <span className="control-label small  text-bold  text-left col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0">DashBoard Name</span>
        <input type="textbox" className="h-30px form-control col-lg-12 col-md-12 mb-2 col-sm-12 col-12 small text-sm required" required
           value={this.state.createdashboardvalue} onChange={(event) => this.handleCreateDash(event)}></input>
         
    </div>
      )
    }
    return htmlele;   
  }
  handleCreateDash=(event)=>{
    let createdashboardvalue = this.state.createdashboardvalue
    createdashboardvalue =event.target.value;
    this.setState({ createdashboardvalue }) ;
  }

  onSubmitHandle=()=>{
    let record = {...this.state.record};
    let source = this.state.source;     
    let name = this.state.name;
    console.log(record);
    if (name===""){
      confirmAlert({
        title: "Alert", message: "Please enter a dataset name",
        buttons: [{ label: "Ok", className:"confirm_btn_popup" ,onClick : ()  =>{return false}  } 
        ],
        });
    }
    else if(source==="-"){
      confirmAlert({
        title: "Alert", message: "Please select a Data Source ",
        buttons: [{ label: "Ok", className:"confirm_btn_popup" ,onClick : ()  =>{return false}  } 
        ],
        });
    }
    else if(this.state.reconciledata && this.state.reconrulevalue===""){
      confirmAlert({
        title: "Alert", message: "Please eneter a Reconciliation  Rules",
        buttons: [{ label: "Ok", className:"confirm_btn_popup" ,onClick : ()  =>{return false}  } 
        ],
        });
    }
    else if(this.state.createdashboard && this.state.createdashboardvalue===""){
      confirmAlert({
        title: "Alert", message: "Please enter a Dashboard Name ",
        buttons: [{ label: "Ok", className:"confirm_btn_popup" ,onClick : ()  =>{return false}  } 
        ],
        });
    }
    else if(this.state.createrole && this.state.createrolevalue===""){
      confirmAlert({
        title: "Alert", message: "Please enter  role mapping .",
        buttons: [{ label: "Ok", className:"confirm_btn_popup" ,onClick : ()  =>{return false}  } 
        ],
        });
    }
   
    else{
  confirmAlert({
     title: "Success", message: "Data Rule saved successfully",
     buttons: [{ label: "Ok", className:"confirm_btn_popup" ,onClick : ()  => {window.location.reload()} } 
     ],
     });
    }
    }

  handleOnChangeName = (event) => {
    let name = { ...this.state.name }
    name=event.target.value;
    this.setState({ name }) ;
}
handleonChangeSource = (event) => {
  let source = { ...this.state.source }
  source=event.target.value;
  let sourceData=[];
  sourceData= this.addToSourceData(source);
  this.setState({ source,sourceData}) 
}

addToSourceData=(field)=>{
  let sourceData = this.state.sourceData;
  if(sourceData.includes(field))
  this.itemAlert();
  else{
    sourceData.push(field)
  }
  
  return sourceData;

}



 
}

export default DataRulesComponent;