import React,{Component} from 'react'
import './SideDrawer.css'
import { FiChevronUp,FiChevronDown } from "react-icons/fi";
import { confirmAlert } from 'react-confirm-alert'; // Import
import Toolbar from '../Toolbar/toolbar'
import menuUrlMapping from '../../config/menu_url.json'
import {Link} from 'react-router-dom'

class SideDrawer extends Component {
   state = {
      sideDrawerOpen: false,
      activeMainMenu: "",
      activeMenu: "",
      clickedMainMenu : ""
   }
   backDropClickHandler = () => {
      this.setState({sideDrawerOpen : false})
   }
   drawerToggleClickHandler = () => {
      const sideDrawerOpen = !this.state.sideDrawerOpen
      this.setState({sideDrawerOpen : sideDrawerOpen})
   }

   PassChange=(event)=>{
      this.props.history.push({pathname : "/portal/ChangePass", redirectLoginFn : this.redirectLogin});
   }

   logoutHandler=(event) => {
      confirmAlert({
         title: "Confirm", message: "Are you sure  you want to Logout ?",
         buttons: [{ label: "Yes", className:"confirm_btn_popup", onClick : (event)  => this.handleLogoutevents(event) }, {
            label : "No",className:"confirm_btn_popup","onClick" : () => {return false}
         }]
      })
        
     
   }
   handleLogoutevents=(event)=>{
      sessionStorage.clear();
      localStorage.removeItem("User");
      sessionStorage.removeItem("restid");
      localStorage.removeItem("ros_token");
      this.props.history.push("/portal/login");
   }
   render() {
       let drawerClass = "side-drawer";
      if (this.props.show) {
         drawerClass = "side-drawer open";
      }
      
      return (
         
         <nav className={drawerClass}>
            <Toolbar drawerClickHandler={this.drawerToggleClickHandler} logoutHandler={this.logoutHandler} PassChange={this.PassChange} />
            <div  className="pl-0 ml-0 mr-0 pr-0 pt-0 mt-0 pb-0 mb-0">
            <div className="pl-2 ml-4 mt-5 pt-5 pb-0 mb-0 mt-0 mr-2 pl-2 navbarmenuscreen">
            <ul className="ml-0 pl-0  mr-2 mt-0">
               {/*<li><Link to={{ pathname: "/portal/itemcategory", item: "Test", exact: true }}> Item Category</Link></li>
               <li><Link to="/portal/item">Item</Link></li>*/}
               {this.createMenuList()}
            </ul> 
            </div>     
           </div>
           <div className="row ml-4 pl-1 mr-0 pr-0 mt-0 pt-0 mb-0 pb-0 ">
               <span className="pl-0 ml-0 col-lg-12"><img className=" max-height-10vh home_welcome" alt="" src=""/></span>
               <span className=" pt-2 mt-5 home_welcome ">Powered By Linuxmantra!</span>
             </div>
           
         </nav>
                
         
      )
   }

  getExpandCollapseText = (menu) => {
   if (menu.name === this.state.activeMainMenu) {
         return (<span className="mr-3"><FiChevronUp /> </span>)
      } else {
   return  (<span className="mr-3 text-bold"> <FiChevronDown /></span>);
}
      
   }
   createMenuList() {
      let htmlele = [];
      if (this.props.menus) {
         for (let i = 0; i < this.props.menus.length; i++) {
            const menu = this.props.menus[i];
            if (this.isChildExists(menu)) {
               htmlele.push(this.createMenuWithChild(menu));
            } else {
               htmlele.push(<li key={"menu-" + menu.name}><a href={this.gethref(menu.name)} onClick={() => this.setActiveMenu(menu,undefined)}>{menu.displayname}</a></li>)
            }
         }
      }
      return htmlele;
   }
      
   createMenuWithChild = (menu) => {
      let ulclsname = "list-unstyled "
      if (menu.name !== this.state.clickedMainMenu) {
         ulclsname = "collapse"
      }
      let clsName_a =""
      if (menu.name === this.state.activeMainMenu) {
         clsName_a = " main-menu-active"
      }
      return (
         <li className="row" key={"menu-" + menu.name}>
            <a   href={menu.name} onClick={(event) => this.onMainMenuClick(event,menu)} data-toggle="collapse"
               className={"no-background-color col-lg-12 pl-0 " + clsName_a}>{menu.displayname} <span  className={clsName_a+ " col-lg-2 float-right"}> {this.getExpandCollapseText(menu)}</span></a>
            <ul className={ulclsname +" col-lg-11 ml-0 pr-0"}>
               {this.createChildMenuItem(menu)}
            </ul>
         </li>
      )
   }
      
   createChildMenuItem = (menu) => {
      const htmlele = [];
      if (this.isChildExists(menu)) {
         for (let i = 0; i < menu.child.length; i++) {
            const childmenu = menu.child[i];
            const menuName = menu.name + "=>" + childmenu.name;
            let className_li = "";
            if (childmenu.name === this.state.activeMenu) {
               className_li += "child-menu-active"
            }
            htmlele.push(<li key={"menu-" + menu.name + "-" + childmenu.name} className={className_li} onClick={() => this.setActiveMenu(childmenu,menu)}>
                     <Link className="ml-2 no-background-color " to={{ pathname: this.gethref(menuName), menuName: menuName, exact: true }}>{childmenu.displayname}</Link>
                     </li>)
         }
      }
      return htmlele;
   }  

   gethref = menuName => {
      const href = menuUrlMapping[menuName];
      return href;
   }

  isChildExists = menu => {
    return menu.child && menu.child.length > 0;
  }

   setActiveMainMenu = (menu) => {
      this.setState({ activeMainMenu: menu.name });
      if (!this.isChildExists(menu)) {
         this.setActiveMenu(menu);
      }
  }
  setActiveMenu = (childmenu,menu) => {
      if (!this.isChildExists(childmenu)) {
       this.setState({ activeMenu: childmenu.name });
      } 
      if (menu) {
        this.setState({ activeMainMenu: menu.name });
     }
   }
   onMainMenuClick = (event,menu) => {
      event.preventDefault();
      this.setState({ clickedMainMenu: menu.name });
  }
   
}

export default SideDrawer;
