import React, { Component } from 'react';

import {Modal, Button,Row,Col} from 'react-bootstrap';
import { BsX } from "react-icons/bs";
import { withRouter } from 'react-router'
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Checkbox from '@material-ui/core/Checkbox';

/*import PageTitle from '../page.title'
import ReactTooltip from "react-tooltip";
import Toggle from 'react-toggle'
import { currency } from '../../common/util';
import { MdCloudUpload } from "react-icons/md";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { Multiselect } from 'multiselect-react-dropdown';
/import {tofix_2} from '../../common/utility.jsx'*/


class DataSourceChangeComponent extends Component { 
   blankSource = {
      name:undefined,sourceType:undefined,value: undefined,userName: undefined,password: undefined,documentType:undefined,state:"A"}
    state = {

        addModalShow :false ,
        popUpModalShow:false,
        errorMessage:"",
        successMessage:"",
        templateFields:[],
        field : "-",
        data: [],
        source : "-",
        record : {...this.blankSource},
        pageTitle : "Data Source",
        headerTitle: "Category Details",
        sftp:"",
        email:"",
        protocol:"",
        url:""
    }
  

   popUpModalClose =() => {
      this.setState({popUpModalShow:false});
      this.props.onHide();  
   }

   openPopUpModal=(event)=>{
      event.preventDefault();
      const pageTitle = "Upload File"
      this.setState({popUpModalShow:true});
      
     // console.log(this.state.addModalShow);
      }
   
   componentDidMount() {
      console.log(this.props);
      if (this.props.mode === "update") {
         if (this.props.sourceID) {
            this.fetchSourceData(this.props.sourceID)
         }
      } else if(this.props.mode==="add") {
         this.setState({ record: { ...this.blankSource }})
      }
   }

   render() { 
     // console.log(this.props);
      return ( 
         <Modal {...this.props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <span className="clo-lg-12">{this.state.errorMessage && <h5 className="alert-danger mt-0 ml-2 mr-0"> {this.state.errorMessage} <span className="float-right pointer-cursor" onClick={() => { this.setState({ errorMessage: "" }) }}>&#x2716;</span> </h5>} </span>
         <Modal.Header >
         <span className="row border_bottom col-lg-12 pt-1 pb-0 ml-0 pl-0 pr-0 mt-1 mb-0 mr-0 d-flex justify-content-center"> 
           <h5 className="text-dark text-bold"  >{this.props.headerTitle}  </h5>
           </span>
         </Modal.Header>
         <Modal.Body className=" pt-1 pb-2 ml-0 pl-0 pr-0 mr-0 " >
         <div className="row pl-1 ml-1 pr-1 mr-1 justify-content-center">  
             
                <div className="row col-lg-12 bg_color card pt-0 mt-0 pb-2">
                <div className="row col-lg-12 pt-1 pb-0 ml-1 pl-1 mt-2 mb-0 mr-0 justify-content-center">
                <div className=" row col-lg-12 pt-0 pb-0 ml-1 pl-1 mt-0 mb-0 mr-1 pr-1 justify-content-center">
                <span className="control-label text-bold text-left col-lg-6 col-md-12 col-sm-12 col-12 pl-0 pr-0 ml-0 mr-0">Name</span>
                <span className="control-label text-bold text-left  col-lg-6 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0">Fetch</span>
                
                <span className="control-label row text-bold col-lg-6 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0">
                <input type="text" className="form-control float-left col-lg-10 col-md-12 mb-2 col-sm-12 col-12 small text-sm mr-1 required" required
                 value={this.state.record.name} onChange={(event) => this.handleOnChangeName(event)}></input>
                </span>
                <span className="control-label row text-bold col-lg-6 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0">      
                <select className="form-control col-lg-10 col-md-12 col-sm-12 col-12 small ml-0 mr-0 mb-2 required" required
                           value={this.state.field} onChange={(event) => this.handleonChangeFields(event)}>
                            <option>-</option>
                           <option value="Airline Ticket">Airline Ticket</option>
                           <option value="Airline Invoice">Airline Invoice</option>
                           <option value="Service Provider Copy">Service Provider Copy</option>
                  </select>
                        {this.loadTemplatesField()}
                  </span>
                 
                <span className="control-label text-left text-bold col-lg-6 col-md-12 col-sm-12 col-12 pl-0 pr-0">Source Type</span>
                <span className="control-label text-bold col-lg-6 col-md-12 col-sm-12 col-12 pl-0 pr-0"></span>
                <span className="control-label row text-bold col-lg-6 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0">      
                        <select className="form-control float-left col-lg-10 col-md-12 col-sm-12 col-12 small mb-0 text-sm required" required
                           value={this.state.record.sourceType} onChange={(event) => this.handleonChangeSource(event)}>
                            <option>-</option>
                           <option value="Email">Email</option>
                           <option value="SFTP">SFTP</option>
                           <option value="Web">Web</option>
                           </select>
                  </span>
                  <span className="control-label text-bold col-lg-6 col-md-12 col-sm-12 col-12 pl-0 pr-0"></span>
                <div className="col-lg-12 pt-1 pb-2 ml-0 pl-0 mt-2 mb-1 mr-0">
                {this.loadSourceData()}
                </div>
                  </div> 
                 <div className="row col-lg-12 pt-0 pb-0 ml-0 pl-0 mt-0 mb-0 mr-0 pr-0 justify-content-center">
                
                </div>
                </div>
                </div>
                {this.showHidePopUp()} 
                </div>

            </Modal.Body>
            <Modal.Footer className="row modal-footer pt-0 mt-0 mb-2 pb-2 mb-0 no-bg d-flex justify-content-center">              
            <button className="btn btn-sm confirm_btn_upload" onClick={() => this.saveDataSource()} >Save</button>
            <button className="btn btn-sm confirm_btn_upload" onClick={()=> this.props.onHide()}>Exit</button>
            </Modal.Footer>
         
          
          </Modal>
         
       );
   }   
   
  handleOnChangeName = (event) => {
   let record = { ...this.state.record }
   record.name=event.target.value;
   this.setState({ record }) ;
}
handleonChangeSource = (event) => {
   let record = { ...this.state.record }
   record.sourceType=event.target.value;
   this.setState({ record }) 
}
 handleOnChangeURL = (event) => {
   let url = { ...this.state.record }
   url = event.target.value;
   console.log(url);
   this.setState({ url });
}
handleOnChangeEmail = (event) => {
 let email = { ...this.state.email }
 email = event.target.value;
 this.setState({ email });
}
handleOnChangeSFTP = (event) => {
 let sftp = { ...this.state.record }
 sftp = event.target.value;
 this.setState({ sftp });
}
handleOnChangePassword = (event) => {
   let record = { ...this.state.record }
   record.password = event.target.value;
   this.setState({ record });
}
handleOnChangeUserName = (event) => {
   let record = { ...this.state.record }
   record.userName = event.target.value;
   this.setState({ record })
}
handleOnChangeProtocol = (event) => {
   let protocol = { ...this.state.protocol }
   protocol = event.target.value;
   this.setState({ protocol
    });
}

   loadSourceData=()=>{
      let htmlele = [];
      let  record= this.state.record;
      if (record.sourceType === "Email"){
          htmlele.push(
         <div className="">
            <span className="col-lg-12 row pt-1 pb-0 ml-0 pl-0 mt-2 mb-0 mr-0">
               <span className="control-label small  text-bold  text-left col-lg-6 col-md-12 col-sm-12 col-12 pl-0 pr-0" required>
               <span className="control-label row text-bold col-lg-12 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0"> SMTP Server</span>
               <span className="control-label row text-bold col-lg-12 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0">      
                  <input type="text" className="h-30px form-control col-lg-11 col-md-12 mb-2 col-sm-12 col-12 small text-sm required" required
                     value={this.state.email || ""} onChange={(event) => this.handleOnChangeEmail(event)}></input>
               </span>
               </span>
               <span className="control-label small  text-bold  text-left col-lg-6 col-md-12 col-sm-12 col-12 pl-0 pr-0" required>
               <span className="control-label row text-bold col-lg-12 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0"> Protocol</span>
               <span className="control-label row text-bold col-lg-12 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0">      
                  <input type="text" className="h-30px form-control col-lg-11 col-md-12 mb-2 col-sm-12 col-12 small text-sm required" required
                     value={this.state.protocol || ""} onChange={(event) => this.handleOnChangeProtocol(event)}></input>
               </span>
               </span>
               <span className="control-label small  text-bold  text-left col-lg-6 col-md-12 col-sm-12 col-12 pl-0 pr-0" required>
               <span className="control-label row text-bold col-lg-12 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0"> Username</span>
               <span className="control-label row text-bold col-lg-12 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0">      
                  <input type="text" className="h-30px form-control col-lg-11 col-md-12 mb-2 col-sm-12 col-12 small text-sm required" required
                     value={this.state.record.userName|| ""} onChange={(event) => this.handleOnChangeUserName(event)}></input>
               </span>
               </span>
               <span className="control-label small  text-bold  text-left col-lg-6 col-md-12 col-sm-12 col-12 pl-0 pr-0" required>
               <span className="control-label row text-bold col-lg-12 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0"> Password</span>
               <span className="control-label row text-bold col-lg-12 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0">      
                  <input type="text" className="h-30px form-control col-lg-11 col-md-12 mb-2 col-sm-12 col-12 small text-sm required" required
                     value={this.state.record.password || ""} onChange={(event) => this.handleOnChangePassword(event)}></input>
               </span>
               </span>
               
            </span>
           
        </div>
          )

      }else if(record.sourceType === "Web"){
        htmlele.push(
         <div className="">
         <span className="col-lg-12 row pt-1 pb-0 ml-0 pl-0 mt-2 mb-0 mr-0">
          <span className="control-label small  text-bold  text-left col-lg-6 col-md-12 col-sm-12 col-12 pl-0 pr-0">Portal URL</span>
          <span className="control-label small  text-bold  text-left col-lg-4 col-md-12 col-sm-12 col-12 pl-0 pr-0">UserName</span>
          <span className="control-label small  text-bold  text-left col-lg-2 col-md-12 col-sm-12 col-12 pl-0 pr-0">Password</span>
       </span>
       <span className="col-lg-12 row pt-1 pb-2 ml-0 pl-0 mt-0 mb-1 mr-0">
         <span className="control-label row text-bold col-lg-6 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0">      
          <input type="text" className="h-30px form-control col-lg-11 col-md-12 mb-2 col-sm-12 col-12 small text-sm required" required
             value={this.state.url || ""} onChange={(event) => this.handleOnChangeURL(event)}></input>
       </span>
           <span className="control-labeltext-bold col-lg-4 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0">      
           
         <input type="text" className="h-30px form-control col-lg-11 col-md-12 mb-2 col-sm-12 col-12 small text-sm required" required
             value={this.state.record.userName || ""} onChange={(event) => this.handleOnChangeUserName(event)}></input>
            </span>
             <span className="control-labeltext-bold col-lg-2 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0">      
              
           <input type="password" className="h-30px form-control col-lg-12 col-md-12 mb-2 col-sm-12 col-12 small text-sm required" required
             value={this.state.record.password || ""} onChange={(event) => this.handleOnChangePassword(event)}></input>
             </span>
        </span> 
      </div>
        )

      }else if (record.sourceType === "SFTP"){
        htmlele.push(
         <div className="">
         <span className="col-lg-12 row pt-1 pb-0 ml-0 pl-0 mt-2 mb-0 mr-0">
          <span className="control-label small  text-bold  text-left col-lg-6 col-md-12 col-sm-12 col-12 pl-0 pr-0">SFTP Server</span>
          <span className="control-label small  text-bold  text-left col-lg-4 col-md-12 col-sm-12 col-12 pl-0 pr-0">UserName</span>
          <span className="control-label small  text-bold  text-left col-lg-2 col-md-12 col-sm-12 col-12 pl-0 pr-0">Password</span>
          </span>
       <span className="col-lg-12 row pt-1 pb-2 ml-0 pl-0 mt-0 mb-1 mr-0">
         <span className="control-label row text-bold col-lg-6 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0">      
          <input type="text" className="h-30px form-control col-lg-11 col-md-12 mb-2 col-sm-12 col-12 small text-sm required" required
             value={this.state.sftp || ""} onChange={(event) => this.handleOnChangeSFTP(event)}></input>
             </span>
           <span className="control-labeltext-bold col-lg-4 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0">      
          
             <input type="text" className="h-30px form-control col-lg-11 col-md-12 mb-2 col-sm-12 col-12 small text-sm required" required
             value={this.state.record.userName || ""} onChange={(event) => this.handleOnChangeUserName(event)}></input>
             
             </span>
             <span className="control-labeltext-bold col-lg-2 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0">      
               <input type="password" className="h-30px form-control col-lg-12 col-md-12 mb-2 col-sm-12 col-12 small text-sm required" required
             value={this.state.record.password || ""} onChange={(event) => this.handleOnChangePassword(event)}></input>
       </span>
        </span> 
      </div>
        )

      }
      return htmlele;
  }
  loadTemplatesField=()=>{
   let templateFields=this.state.templateFields;
   let htmlele =[];
   if(templateFields.length>0){
     for(let i=0;i<templateFields.length;i++){
       htmlele.push(
         <div className="bg_color row pl-0 ml-0 mr-0 pr-0 mt-2 pt-0 card col-lg-12">
           <span className="pl-0 ml-0 mr-0 pr-0 mt-2 pt-0 col-lg-12">
           <label className="pl-2 ml-0 mr-0 pr-0 col-lg-11">{templateFields[i]}</label>
           <span className="pl-0 ml-0 pr-0 mr-0 col-lg-1" onClick={() => this.popField(templateFields[i])} ><BsX/> </span>
           </span>
         </div>
       )
     }
   }
   return htmlele;
 }
 handleonChangeFields = (event) => {
   let field = { ...this.state.field }
   field=event.target.value;
   let templateFields=[];
   templateFields= this.addToTemplates(field);
   this.setState({ field,templateFields}) 
}
addToTemplates=(field)=>{
let templateFields = this.state.templateFields;
if(templateFields.includes(field))
this.itemAlert();
else{
  templateFields.push(field)
}
return templateFields;
}
popField=(field)=>{
   let templateField = this.state.templateFields;
   if(templateField.includes(field))
   {
     templateField.splice(field,1);
   }
   this.setState({ templateField,field:"-"});

 }
 
   showHidePopUp=() => {
    //console.log("cusotmize function called")
    if(this.state.popUpModalShow && !this.state.processStatus) {
       //console.log("addModalShow")
       return (
          <Modal {...this.props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered >
       <Modal.Header closeButton>
           <h5 className="pb-0 mb-0 modalTitle text-dark text-bold"> Confirmation  </h5>
       </Modal.Header>
       <Modal.Body className=" mt-0 pt-0 pb-0 mb-0 ml-0 pl-0 pr-0 mr-0 " >
          <p>Data Source has been saved successfully</p>
       </Modal.Body>
       <Modal.Footer className="pt-0 mt-0">
       <button className="btn btn-sm  w-100px confirm_btn_popup" onClick= {(event) => this.popUpModalClose()}>Ok</button> 
       </Modal.Footer>
          </Modal>
       ); }
       else if(this.state.popUpModalShow && this.state.processStatus) {
         //console.log("addModalShow")
         return (
            <Modal {...this.props} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
         <Modal.Header closeButton>
         <h5 className="pb-0 mb-0 modalTitle text-dark text-bold"> Notification  </h5>
         </Modal.Header>
         <Modal.Body className=" mt-0 pt-0 pb-0 mb-0 ml-0 pl-0 pr-0 mr-0 " >
         <div className="col-lg-12 form form-group form-inline mt-0 mb-0 pl-0 ml-0 pt-0 mr-0 pr-0 mt-0 mb-1 pb-2">
                           <Checkbox className="col-lg-2 col-md-2 col-sm-2 col-2 ml-0 pl-0" checked={this.state.enablenotification} onClick={(event) => this.handleSelectNotifiation(event)}/>
                              <span className="col-lg-8 col-md-8 col-sm-8 col-8 ml-0 pl-0 pr-0 mr-0 text-dark text-left">Post Processing Notification</span>
                             
                  </div>
                  {this. showHidePopUp()} 
         </Modal.Body>
         <Modal.Footer className="pt-0 mt-0">
         <div className="col-lg-12 form form-group form-inline mt-0 mb-0 pl-2 ml-0 pt-0 mt-0 mb-0 pb-0 d-flex justify-content-center">
        <span className="col-lg-6 form form-group form-inline mt-0 mb-0 pl-2 ml-0 pt-0 mt-0 mb-0 pb-0 d-flex justify-content-center"> <button className="btn btn-sm col-lg-8 w-100px confirm_btn_popup" onClick= {(event) => this.popUpModalClose()}>Exit</button> </span>
        <span className="col-lg-6 form form-group form-inline mt-0 mb-0 pl-2 ml-0 pt-0 mt-0 mb-0 pb-0 d-flex justify-content-center"> <button className="btn btn-sm  col-lg-8  w-100px confirm_btn_popup" onClick= {(event) => this.processFile()}>Submit</button></span>
          </div>
         </Modal.Footer>
            </Modal>
         ); }
 }
 saveDataSource=()=>{
   let record = {...this.state.record }
   let sftp= this.state.sftp;
   let email= this.state.email
   let web= this.state.url;
   if(web!=""){
      record.value=this.state.url;
   }else if(sftp!=""){
      record.value=this.state.sftp;
   } else if(email!=""){
      record.value=this.state.email;
   }
   if (!record.name){
      this.setState({errorMessage:"Please enter a valid Name!"});
      return false;
   }
   else if (record.sourceType==="-"){
      this.setState({errorMessage:"Please select a valid sourceType!"});
      return false;
   }
   else if (record.sourceType==="Email" && record.value===""){
      this.setState({errorMessage:"Please enter a valid Email!"});
      return false;
   }
   else if (record.sourceType==="SFTP" && !record.value){
      this.setState({errorMessage:"Please enter a valid hostname/ServerIP!"});
      return false;
      
   }
   else if (record.sourceType==="Web" && !record.value){
      this.setState({errorMessage:"Please enter a valid URL!"});      
      return false;
   }
   else if (!record.userName){
      this.setState({errorMessage:"Please enter a valid username!"});
      return false;
   }
   else if (!record.password){
      this.setState({errorMessage:"Please enter a valid password!"});
      return false;
   }
   else if (record.documentType=""){
      this.setState({errorMessage:"Please select at least 1 documentType!"});
      return false;
   }
  
  
  // console.log(this.state.templateFields)
  let RawTypeValue = this.state.templateFields.join(",");
  record.documentType =  RawTypeValue.replace(/^,/g,'');
     // this.setState({record})
   console.log(record);

   const requestOptions = {
      method: 'POST',
      body : JSON.stringify(record),
      headers: {
         'Content-Type': 'application/json; charset=utf-8',
         'Accept': "application/json",
         'Authorization' : "JWT-" + localStorage.getItem("ros_token")
      }
   };
   fetch(process.env.REACT_APP_ROS_BASE_URL + '/savedatasource', requestOptions)
      .then(response => response.json())
      .then(res => this.handleSaveDataSource(res))
      .catch(err => console.error(err));
}
itemView = () => {
   window.location.reload(); 
}
handleSaveDataSource(res) {
   if (res["success"]) {
         this.setState({popUpModalShow:true}); 
         this.props.refreshData();
      }
} 
 popUpModalClose =() => {
    this.setState({popUpModalShow:false});
    this.props.onHide();  
    window.location.reload();
 }

 openPopUpModal=(event)=>{
    event.preventDefault();
    this.setState({popUpModalShow:true});
   // console.log(this.state.addModalShow);
    }

    fetchSourceData(sourceId) {
      const requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json",
            'Authorization' : "JWT-" + localStorage.getItem("ros_token")
         }
      };
      let queryString='dataSourceId='+sourceId
      fetch(process.env.REACT_APP_ROS_BASE_URL + '/getdatasourcebyid?' + queryString, requestOptions)
         .then(response => response.json())
         .then(res => this.refreshSourceData(res))
         .catch(err => console.error(err));    
   }
  

   refreshSourceData(res) {
      if (res["success"]) {
         const record = res["result"];
         if(record) {
            let templateFields = record.documentType.split(",");
            this.setState({record,templateFields});
            if(record.sourceType==="Web" && record.value ){
               this.setState({url: record.value});
            }else if(record.sourceType==="Email" && record.value){
               this.setState({email: record.value});
            }else if(record.sourceType==="SFTP" && record.value){
               this.setState({sftp: record.value});
            }
           } 
   }
}
    showHidePopUp=() => {
      //console.log("cusotmize function called")
      if(this.state.popUpModalShow) {
         //console.log("addModalShow")
         return (
         <Modal {...this.props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered >
         <Modal.Header closeButton>
             <h5 className="pb-0 mb-0 modalTitle text-dark text-bold"> Confirmation  </h5>
         </Modal.Header>
         <Modal.Body className=" mt-0 pt-0 pb-0 mb-0 ml-0 pl-0 pr-0 mr-0 " >
            <p>Data Source has been saved successfully </p>
         </Modal.Body>
         <Modal.Footer className="pt-0 mt-0">
         <button className="btn btn-sm  w-100px confirm_btn_popup" onClick= {(event) => this.popUpModalClose()}>Ok</button> 
          
         </Modal.Footer>
            </Modal>
         ); }
   }
}

 
export default withRouter(DataSourceChangeComponent);

