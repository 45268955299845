import React from 'react';
import './toolbar.css';
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';
import Logout from '../Logout/logout.component'


const User = sessionStorage.getItem("currentUser")

const Toolbar = (props) => {
   const restid = JSON.parse(sessionStorage.getItem('restid'))
   return (

      <div className="toolbar mr-0 pr-0">
         <nav className="toolbar_navigation ml-0 pl-0 mr-0 pr-0">
            <div className="toolbar__logo ml-0 pl-0 mr-0 pr-0">
               <a href="/portal/home"  ><img className="img_logo" alt="" src={process.env.PUBLIC_URL + "/assets/images/logo/" + restid + ".png"} /></a>
            </div>
            <div className="pl-0 ml-0 row">

               <span className="col-2"> <DrawerToggleButton click={props.drawerClickHandler} /></span>
               <span className="col-8"> <Logout logoutClick={props.logoutHandler} PassChange={props.PassChange} /></span>
            </div>

         </nav>
      </div >
   )
};


export default Toolbar;

