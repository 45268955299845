import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './enterotp.css';
import queryString from 'query-string';
import PageTitle from '../page.title';
import { confirmAlert } from 'react-confirm-alert'; // Import



class EnterOTP extends Component {

   state = {
      pageTitle: "Forgot Password",
      open: false,
      loginerrormsg: "",



      loginerrormsg: "",
      sendusername: "",
      modal: true,
      handleClose: false,
      modalIsOpen: false,
      setIsOpen: false,
      errorMessage: "",
      modalopen: false,
      request: "",



      user: { username: "", password: "", token: "" },
      confirmPassword: "",
      successMessage: "",

   }
   componentDidMount() {
      const pathname = this.props.location.pathname
      if (pathname.startsWith("/portal/enterotp")) {
         //  const usern = this.props.match.params.username;
         //   console.log(usern);
         let url = this.props.location.search;
         let params = queryString.parse(url);
         //console.log(params["username"]);
         let user = { ...this.state.user }
         user.username = params["username"]
         user.token = params["token"]

         this.setState({ user });
      }


      /* if(userName && token){
        let user = { ...this.state.user }
         user.username=userName["username"]
         user.token=token
         console.log(user.token);
         this.setState({user});
       }
      
        */
   }
   handleOnChangePassword = (event) => {
      let user = { ...this.state.user }
      user.password = event.target.value;
      this.setState({ user });

   }
   handleOnChangeconfirmPassword = (event) => {
      const confirmPassword = event.target.value;
      this.setState({ confirmPassword: confirmPassword })

   }
   /* handleOnChangeoldPassword=(event)=>{
     let user = { ...this.state.user }
     user.oldpassword = event.target.value
     this.setState({ user });
     this.setState({ loginerrormsg: "" });
  //handle change events)
    }*/

   ResetPassword = (event) => {

      event.preventDefault();
      if (this.state.user["password"] != this.state.confirmPassword) {
         const errorMessage = "Please enter confirm_password same as new_password !"
         this.setState({ errorMessage });

      }
      else {
         const requestOptions = {
            method: 'POST',
            body: JSON.stringify(this.state.user),
            headers: {
               'Content-Type': 'application/json; charset=utf-8',
               'Accept': "application/json",
               'Authorization': "JWT-" + localStorage.getItem("ros_token")

            }
         };

         fetch(process.env.REACT_APP_ROS_BASE_URL + '/token/resetforgotpwd', requestOptions)
            .then(res => res.json())
            .then(res => this.afterSave(res))
            .catch(err => console.error(err));

      }
   }
   afterSave = (res) => {

      if (res["success"]) {
         sessionStorage.clear();
         return (
            confirmAlert({
               title: "Success", message: "Password reset Successfully",
               buttons: [{ label: "ok", className: "confirm_btn_popup", onClick: () => this.closeModal() }]
            })
         );


      }
      else if (res["message"] === "TOKEN_ALREADYUSED") {
         const errorMessage = "Link Already Used!"
         this.setState({ errorMessage });
      }
   }
   closeModal() {
      this.props.history.push("portal/login")
   }

   render() {

      return (

         <React.Fragment>
            <PageTitle title={this.state.pageTitle} />
            {this.state.errorMessage && <h5 className="alert-danger mt-0 ml-2 mr-4"> {this.state.errorMessage} <span className="float-right pointer-cursor" onClick={() => { this.setState({ errorMessage: "" }) }}>&#x2716;</span> </h5>}
            {this.state.successMessage && <h5 className="alert-success mt-0 ml-2 mr-4"> {this.state.successMessage} <span className="float-right pointer-cursor" onClick={() => { this.setState({ successMessage: "" }) }}>&#x2716;</span> </h5>}

            <div className="container h-100">

               <div className="d-flex justify-content-center h-100">
                  <div className="user_card">
                     <p className="Login_head">Enter OTP</p>
                     <div className="d-flex justify-content-center form_container mt-2">
                        <form>

                           <div className="input-group mt-4 mb-3">

                              <input type="text" name="" className="form-control input_user"
                                 placeholder="Submit Request ..." value={this.state.request}
                                 onChange={(event) => this.submitChange(event)}
                              />
                           </div>
                           <div className="d-flex justify-content-center mt-3  login_container">
                              <button className="btn login_btn " onClick={(event) => this.SubmitRequest(event)}>Submit</button>
                           </div>

                           <br /><br /><br />
                           <span className=" d-flex justify-content-center pt-2 mt-5 home_welcome ">Powered By Linuxmantra!</span>







                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </React.Fragment>

      );
   }



   /////////////////////////////////////////////////////////


   state = {
      user: { username: "", password: "" },
      loginerrormsg: "",
      sendusername: "",
      modal: true,
      handleClose: false,
      modalIsOpen: false,
      setIsOpen: false,
      errorMessage: "",
      modalopen: false,
      request: "",


   }

   handleUserChangeEvents = (event) => {
      let user = { ...this.state.user }
      user.username = event.target.value
      this.setState({ user });
      this.setState({ loginerrormsg: "" });
      //handle change events
   }
   SubmitRequest(event) {


      const requestOption = {
         method: "GET",
         body: JSON.stringify(),
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json"
         }
      }
      let submit = ""
      if (this.state.request === "") {
         submit = "undefined"
      } else {
         submit = "submit="
      }
      const queryString = submit + this.state.request
      fetch(process.env.REACT_APP_ROS_BASE_URL + "/submitRequest?" + queryString, requestOption)
         .then(response => {
            // const filename =  response.headers.get('Content-Disposition').split('filename=')[1];

            response.blob().then(blob => {
               console.log(queryString)
               if (this.state.request === "") {
                  this.setState({ errorMessage: "Failed to submit", successMessage: "" });
               } else { this.setState({ errorMessage: "", successMessage: "Otp Successfully Submited" }); }
            });

         }).catch(err => console.error(err), this.setState({ errorMessage: "Failed to submit", successMessage: "" }), this.formData = {});
      ;


      event.preventDefault();
      // handle submit events
   }
   handleSubmitevents(event) {

      const requestOption = {
         method: "POST",
         body: JSON.stringify(this.state.user),
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json"
         }
      }
      fetch(process.env.REACT_APP_ROS_BASE_URL + "/token/generate-token", requestOption)
         .then(response => response.json())
         .then(res => this.setLoginInfo(res))
         .catch(err => {
            const loginerrormsg = "Server error!";
            this.setState({ loginerrormsg });
            console.log(err);
         });
      // handle submit events
   }

   openModal() {
      this.state.setIsOpen = !this.state.setIsOpen;
   }



   closeModal = (event) => {

      this.setState({ sendusername: "" });
      this.setState({ modalIsOpen: false })
      this.setState({ modalopen: false })
      this.setState({ errorMessage: "" })

   }
   setLoginInfo(res) {
      if (res["success"]) {
         const result = res["result"];
         localStorage.setItem("user", JSON.stringify({ username: result.username, name: result.name }));
         localStorage.setItem("ros_token", result.token);
         this.props.history.push("/portal/home")
      } else {
         const loginerrormsg = "Invalid user or password";
         this.setState({ loginerrormsg });
      }

   }

   submitChange = (event) => {

      this.state.request = event.target.value
      this.setState({});
   }
   handlePasswordChange = (event) => {
      let user = { ...this.state.user }
      user.password = event.target.value
      this.setState({ user });
      this.setState({ loginerrormsg: "" });
   }
   handleChangeUsername = (event) => {
      event.preventDefault();
      let sendusername = event.target.value
      this.setState({ sendusername: sendusername });


   }


}

export default EnterOTP;