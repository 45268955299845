export const useStore = (() => {
   let storeInstance;
   let subs = {};
   const createStoreInstance = () => {
      let store = {};

      const getStore = (key) => {
         return store[key];
      }

      const setStore = ({ key, val }) => {
         store[key] = val;
         if (subs[key]) { subs[key]() };
         return store[key];
      }

      const subscribe = ({ key, fx }) => {
         subs[key]=fx;
      }

      const unsubscribe = (key) => {
         delete subs[key];
      }
      return [getStore, setStore,subscribe,unsubscribe];
   }

   return () => {
      if (!storeInstance) {
         storeInstance = createStoreInstance();
      }
      return storeInstance;
   }
})();