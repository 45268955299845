import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect } from 'react-router-dom'
import { checkAuth } from '../Auth/AuthRoute'
import BackDrop from './BackDrop/BackDrop';
import Toolbar from './Toolbar/toolbar'
import SideDrawer from './SideDrawer/SideDrawer'
import ReconciliationComponent from './reconciliation/reconciliation.component'
import DetailsComponent from './reconciliation/details.component'
import QueueViewComponent from './reconciliation/queueview.component'
import DataSourceComponent from './configuration/datasource.component';
import DataRulesComponent from './configuration/datarules.component';
import TemplateComponent from './configuration/templates.component';
import { useStore } from '../store/store'
import RoleComponent from './role/role.component';
import UserComponent from './user/user.component';
import { confirmAlert } from 'react-confirm-alert'; // Import
import ChangePass from './Logout/ChangePasss.component';
import ForgotPassswordComponent from './forgotPassword/forgotpasssword.component';
import { isMobile } from '../common/util';
import EnterOTP from './enterotp/enter-otp.component'
import ClientDetail from './clientdetail/clientdetail.component'
class MainComponent extends Component {
   state = {
      sideDrawerOpen: false,
      privileges: [],
      menus: []
   }

   componentDidMount() {
      this.loadPrivileges();
   }

   backDropClickHandler = () => {
      this.setState({ sideDrawerOpen: false })
   }
   drawerToggleClickHandler = () => {
      const sideDrawerOpen = !this.state.sideDrawerOpen
      this.setState({ sideDrawerOpen: sideDrawerOpen })
   }
   render() {
      let backDrop;
      if (this.state.sideDrawerOpen) {
         backDrop = <BackDrop click={this.backDropClickHandler} />
      }
      let mobileScreen = isMobile();
      //console.log(mobileScreen);
      if (mobileScreen) {
         return (
            <div className="window-main-screen">
               <Toolbar drawerClickHandler={this.drawerToggleClickHandler} logoutHandler={this.logoutHandler} PassChange={this.PassChange}
               />
               <SideDrawer show={this.state.sideDrawerOpen} history={this.props.history} menus={this.state.menus} drawerClickHandler={this.drawerToggleClickHandler} logoutHandler={this.logoutHandler} PassChange={this.PassChange} />
               {backDrop}
               <main className="pl-0 ml-0 pt-0 mt-0 pb-0 mb-0">
                  <div className="pl-0 ml-0 pt-0 mt-0 pb-0 mb-0" style={{ maxHeight: '100vh', overflowY: 'hidden' }}>
                     {this.openPage()}
                  </div>
               </main>
            </div>);
      }
      else {
         return (
            <div className="window-main-screen">
               <SideDrawer show={this.state.sideDrawerOpen} history={this.props.history} menus={this.state.menus} drawerClickHandler={this.drawerToggleClickHandler} logoutHandler={this.logoutHandler} PassChange={this.PassChange} />
               {backDrop}
               <main >
                  <div style={{ maxHeight: '95vh', overflowX: 'hidden' }}>
                     {this.openPage()}
                  </div>
               </main>
            </div>);
      }


   }


   redirectLogin = () => {
      this.props.history.push("/portal/login");
   }
   PassChange = (event) => {
      this.props.history.push({ pathname: "/portal/ChangePass", redirectLoginFn: this.redirectLogin });
   }

   logoutHandler = (event) => {
      confirmAlert({
         title: "Confirm", message: "Are you sure  you want to Logout ?",
         buttons: [{ label: "Yes", className: "confirm_btn_popup", onClick: (event) => this.handleLogoutevents(event) }, {
            label: "No", className: "confirm_btn_popup", "onClick": () => { return false }
         }]
      })


   }
   handleLogoutevents = (event) => {
      sessionStorage.clear();
      localStorage.removeItem("User");
      sessionStorage.removeItem("restid");
      localStorage.removeItem("ros_token");
      this.props.history.push("/portal/login");
   }


   openPage = () => {
      let htmlele = ""
      let value = "ticketvsairline"
      console.log(this.props.location.pathname);
      if (this.props.location.pathname === "/portal/enterotp") {

         htmlele = <EnterOTP />

      }
      if (this.props.location.pathname === "/portal/clientdetail") {

         htmlele = <ClientDetail/>

      }
      if (this.props.location.pathname === "/portal/forgotpassword") {
         htmlele = <ForgotPassswordComponent />


      } else if (checkAuth()) {
         switch (this.props.location.pathname) {
            case "/portal/ticketvsairline":
               htmlele = <ReconciliationComponent value="ticketvsairline" />
               break;
            case "/portal/mailprocessed":
               htmlele = <ReconciliationComponent value="mailprocessed" />
               break;
            case "/portal/airlinevsgstr":
               htmlele = <ReconciliationComponent value="airlinevsgstr" />
               break;
            case "/portal/ticketsvsagent":
               htmlele = <ReconciliationComponent value="ticketsvsagent" />
               break;
            case "/portal/agentvsairline":
               htmlele = <ReconciliationComponent value="agentvsairline" />
               break;
            case "/portal/agentvsgstr":
               htmlele = <ReconciliationComponent value="agentvsgstr" />
               break;
            case "/portal/tracking":
               htmlele = <QueueViewComponent />
               break;
            case "/portal/details":
               htmlele = <DetailsComponent />
               break;
            case "/portal/datasource":
               htmlele = <DataSourceComponent />
               break;
            case "/portal/templates":
               htmlele = <TemplateComponent />
               break;
            case "/portal/datarules":
               htmlele = <DataRulesComponent />
               break;
            case "/portal/role":
               htmlele = <RoleComponent />
               break;
            case "/portal/user":
               htmlele = <UserComponent />
               break;
            case "/portal/ChangePass":
               htmlele = <ChangePass />
               break;
            default:
               htmlele = <Redirect to="/portal/home" />

         }
      } else {
         htmlele = <Router>
            <Redirect to="/portal/login" />
         </Router>
      }
      return htmlele
   }

   loadPrivileges = () => {
      const requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json",
            'Authorization': "JWT-" + localStorage.getItem("ros_token")
         }
      };

      fetch(process.env.REACT_APP_ROS_BASE_URL + '/userprivileges', requestOptions)
         .then(response => response.json())
         .then(res => this.handlUserPrivResponse(res))
         .catch(err => console.error(err));
   }

   handlUserPrivResponse = res => {
      if (res["success"]) {
         const [setStore] = useStore();
         setStore("privileges", res["result"]);

         this.setState({ privileges: res["result"] })
         this.loadMenus();
         let privileges = res["result"]
         let n = privileges.includes("discount");
         let role = "";
         if (n) {
            role = "Manager";
         }
         else {
            role = "Server";
         }

         sessionStorage.setItem('role', JSON.stringify(role));
         // console.log(n);
      } else {
         console.error(res["message"])
      }
   }

   loadMenus = () => {
      const requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json",
            'Authorization': "JWT-" + localStorage.getItem("ros_token")
         }
      };

      fetch(process.env.REACT_APP_ROS_BASE_URL + '/menus', requestOptions)
         .then(response => response.json())
         .then(res => this.handleMenuResponse(res))
         .catch(err => console.error(err));
   }

   handleMenuResponse = res => {
      if (res["success"]) {
         this.setState({ menus: res["result"] })
      }
   }

}

export default MainComponent;

