import React, { Component } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import makeAnimated from 'react-select/animated';
import Select from 'react-select'
import { Modal, Button, Row, Col } from 'react-bootstrap';
import "./recon.css";
import { withRouter } from 'react-router'
import moment from "moment";
import DatePicker from "react-datepicker";

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
/*import {tofix_2} from '../../common/utility.jsx'
import { confirmAlert } from 'react-confirm-alert'; // Import
import { Multiselect } from 'multiselect-react-dropdown';
import Checkbox from '@material-ui/core/Checkbox';
import { each } from 'jquery';
import timezone from "moment-timezone";
import Toggle from 'react-toggle'
import { currency } from '../../common/util';
import { MdCloudUpload } from "react-icons/md";
import PageTitle from '../page.title'
import ReactTooltip from "react-tooltip";
*/
const animatedComponents = makeAnimated();

class CSVDownloadComponent extends Component {
   blankClient = { clientId: undefined, clientName: undefined };
   blankItem = {
      itemId: undefined, itemName: undefined, mrpPrice: undefined, itemImage: null, itemImageName: undefined, price: undefined, max: undefined, type: undefined,
      description: undefined, itemMetadata: undefined, category: { itemCategoryId: undefined }, subItems: [],
      mandatory: false, restaurantId: undefined, customized: undefined, options: undefined, TypeValue: undefined, availibilty: "A"
   }
   blankCate = {
      itemCategoryId: undefined

   }
   blakSubItem = {
      subItemName: undefined, price: 0, description: undefined
   }
   constructor() {
      super();
      this.multiselectRef = React.createRef();
      console.log(this.state.PDFs)
   }
   resetValues() {
      // By calling the belowe method will reset the selected values programatically
      this.multiselectRef.current.resetSelectedValues();
   }
   state = {
      startTime: new Date(),
      stopTime: new Date(),
      startDate: new Date(),
      stopDate: new Date(),
      filerecord: {},
      pageTitle: "",
      successMessage: "",
      processStatus: false,
      enablenotification: "",
      sendnotificationvalue: "",
      source: "",
      PDFs: "",
      downloadType: "",
      pdfType: "",
      pdfcsvfile: "",
      filename: "",
      fileType: "DSR",
      options: "",
      customization: false,
      selection: false,
      errorMessage: "",
      popUpModalShow: false,
      initRecord: this.blankItem,
      record: this.blankItem,
      _data: [],
      recordItemCategory: this.blankCate,
      recordSubItem: this.blakSubItem,
      recordSubItems: [],
      selectAllOption: { clientId: "0", clientName: "Select-All" },
      client: [this.blankClient],
      //clients:this.blankClient,
      selectedClients: []
   }



   popUpModalClose = () => {

      this.setState({ popUpModalShow: false });
      this.props.onHide();
   }

   openPopUpModal = (event) => {
      event.preventDefault();
      const pageTitle = "Downoad Report"
      this.setState({ popUpModalShow: true });

      // console.log(this.state.addModalShow);
   }

   componentDidMount() {
      this.getClientList();
      this.getAirLineList();
      console.log(this.getClientList())
      //  console.log(this.props.data)
   }


   render() {
      console.log(this.props.fileList);
      return (
         <Modal {...this.props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <span className="clo-lg-12">{this.state.errorMessage && <h5 className="alert-danger mt-0 ml-2 mr-4"> {this.state.errorMessage} <span className="float-right pointer-cursor" onClick={() => { this.setState({ errorMessage: "" }) }}>&#x2716;</span> </h5>} </span>
            <Modal.Header >
               <span className="row border_bottom col-lg-12 pt-1 pb-0 ml-0 pl-0 pr-0 mt-1 mb-0 mr-0 d-flex justify-content-center">
                  <h5 className="text-dark text-bold"  >{this.props.headerTitle}  </h5>
               </span>
            </Modal.Header>
            <Modal.Body className=" pt-1 pb-2 ml-0 pl-0 pr-0 mr-0 " >
               <div className="row pt-1 pb-2 ml-0 pl-0 pr-0 mr-0 ">
                  <div className="row col-lg-12 pt-1 pb-0 ml-0 pl-0 pr-0 mt-2 mb-0 mr-0 ">
                     <span className="col-lg-12 download_button pt-2 mt-3 mb-0 pr-0 mr-0 pl-1 ml-0 d-flex justify-content-center">
                        <label className="col-lg-3 text-bold" >File Type</label>
                        <select className=" h-30px form-control align-right col-lg-3 col-md-12 col-sm-12 col-12 small mb-0  mt-0 pt-1  text-sm required" required
                           value={this.state.downloadType} onChange={(event) => this.handleonChangeDownloadType(event)}>
                           <option>-</option>
                           <option value="PDF">Invoice Data</option>
                           <option value="Excel">CreditNote Data</option>
                        </select>
                     </span>
                     <span className="col-lg-12 download_button pt-2 mt-3 mb-0 pr-0 mr-0 pl-1 ml-0 d-flex justify-content-center">
                        {this.loadContentWithType()}

                     </span>

                  </div>
               </div><br /><br />

            </Modal.Body>
            <Modal.Footer className="row modal-footer pt-0 mt-0 mb-2 pb-2 mb-0 no-bg d-flex justify-content-center">
               {/*<a className="anchor_disable" href={this.downloadCsv()} target="_blank" download > </a>*/}
               <button className="btn btn-sm  confirm_btn_upload" onClick={() => this.downloadFile()}>Download</button>
               <button className="btn btn-sm  confirm_btn_upload" onClick={() => this.props.onHide()}>Exit</button>
            </Modal.Footer>
         </Modal>
      );
   }
   loadContentWithType = () => {
      let htmlele = [];
      if (this.state.downloadType === "PDF") {
         htmlele.push(
            <div>
               <span className="col-lg-12 download_button pt-2 pb-3 mt-3 mb-0 pr-0 mr-0 pl-1 ml-0 d-flex justify-content-center">
                  <label className="col-lg-6 text-bold" >Filter&nbsp;Type</label>
                  <select className=" h-30px form-control align-right col-lg-6   small mb-0  mt-0 pt-1  text-sm required" required
                     value={this.state.pdfType} onChange={(event) => this.handleonChangepdfType(event)}>
                     <option>-</option>
                     <option value="SystemInvoice">SystemID</option>
                     <option value="ClientName">Family Name</option>
                     <option value="AirlineInvoice">InvoiceNo</option>
                     <option value="EmailInvoiceDownload">AirLine</option>
                  </select>
               </span>
               <span className="col-lg-12  pt-2 pb-3 mt-3 mb-0 pr-0 mr-0 pl-1 ml-0 d-flex justify-content-center">

                  {this.loadContentWithpdfType()}
               </span>
            </div>
         )
      }
      if (this.state.downloadType === "Excel") {
         htmlele.push(
            <div >
               <div className='row mb-2 d-flex justify-content-end'>

                  <span className=" col-lg-4 pt-0 mt-0 mb-0 pb-0 pr-0 mr-0 pl-1 ml-0">
                     <label className="col-lg-3 small text-bold">From</label>
                     <DatePicker className="form-control col-lg-9" dateFormat="dd-MM-yyyy" selected={this.state.startDate} datePickerType="single" popperPlacement="left-start" value={this.state.startDate} name="starDate" onChange={this.handlechangestDate} scrollableYearDropdown showYearDropdown />
                  </span>

                  <span className=" col-lg-4 pt-0 mt-0 mb-0 pb-0 pr-0 mr-0 pl-0 ml-0">
                     <label className="col-lg-3 small text-bold ">To</label>
                     <DatePicker className="form-control col-lg-9" dateFormat="dd-MM-yyyy" selected={this.state.stopDate} datePickerType="single" popperPlacement="left-start" value={this.state.stopDate} name="stopDate" onChange={this.handlechangeEnDate} scrollableYearDropdown showYearDropdown />
                  </span>
               </div>
               <div class="row">
                  <label className="col-lg-3 text-bold " >Family&nbsp;Name</label>

                  <Select className="col-lg-9 d-flex justify-content-end"
                     closeMenuOnSelect={false}
                     components={animatedComponents}
                     isMulti
                     options={this.options}
                     onChange={(e) => this.changeClientValue(e)}



                  />

               </div>
               <div class="row pt-3">
                  <label className="col-lg-3 text-bold " >AirLine</label>

                  <Select className="col-lg-9 d-flex justify-content-end"
                     closeMenuOnSelect={false}
                     components={animatedComponents}
                     isMulti
                     options={this.optionss}
                     maxMenuHeight={150}
                     maxMenuWidth={150}

                     onChange={(e) => this.changeAirlineValue(e)}



                  />            </div></div>

         )
         {/*<div>
               <label className="col-lg-3 text-bold" >File&nbsp;Name</label>
               <select className="h-30px col-lg-6" required
                  value={this.state.source} onChange={(event) => this.handleonChangeSource(event)}>
                  <option>-</option>
                  {this.props.fileList.map(optn => (<option value={optn}>{optn}</option>))}
               </select>

            </div>*/}
      }
      return htmlele;
   }
   loadContentWithpdfType = () => {

      let htmlele = [];
      if (this.state.pdfType === "SystemInvoice") {
         htmlele.push(
            <div class="row">
               <label className="col-lg-3 text-bold  " >System&nbsp;ID</label>
               <textarea className="col-lg-9 " rows={1} cols={30} value={this.state.PDFs} onChange={(event) => this.handleonChangePDFs(event)} placeholder='Please enter SystemID (single/multiple)'> </textarea>
            </div>)
      }
      if (this.state.pdfType === "ClientName") {
         htmlele.push(
            <div >
               <div className='row mb-2 d-flex justify-content-end'>

                  <span className=" col-lg-4 pt-0 mt-0 mb-0 pb-0 pr-0 mr-0 pl-1 ml-0">
                     <label className="col-lg-3 small text-bold">From</label>
                     <DatePicker className="form-control col-lg-9" dateFormat="dd-MM-yyyy" selected={this.state.startTime} datePickerType="single" popperPlacement="left-start" value={this.state.startTime} name="startTime" onChange={this.handlechangeDate} scrollableYearDropdown showYearDropdown />
                  </span>

                  <span className=" col-lg-4 pt-0 mt-0 mb-0 pb-0 pr-0 mr-0 pl-0 ml-0">
                     <label className="col-lg-3 small text-bold ">To</label>
                     <DatePicker className="form-control col-lg-9" dateFormat="dd-MM-yyyy" selected={this.state.stopTime} datePickerType="single" popperPlacement="left-start" value={this.state.stopTime} name="stopTime" onChange={this.handlechangeEndDate} scrollableYearDropdown showYearDropdown />
                  </span>
               </div>
               <div class="row">
                  <label className="col-lg-3 text-bold " >Family&nbsp;Name</label>

                  <Select className="col-lg-9 d-flex justify-content-end"
                     closeMenuOnSelect={false}
                     components={animatedComponents}
                     isMulti
                     options={this.options}
                     onChange={(e) => this.changeClientValue(e)}



                  />

               </div>
               <div class="row pt-3">
                  <label className="col-lg-3 text-bold " >AirLine</label>

                  <Select className="col-lg-9 d-flex justify-content-end"
                     closeMenuOnSelect={false}
                     components={animatedComponents}
                     isMulti
                     options={this.optionss}
                     maxMenuHeight={150}
                     maxMenuWidth={150}

                     onChange={(e) => this.changeAirlineValue(e)}



                  />            </div></div>


         )
      }
      if (this.state.pdfType === "AirlineInvoice") {
         htmlele.push(
            <div class="row">
               <label className="col-lg-3 text-bold " >Invoice&nbsp;No</label>
               <textarea className="col-lg-9" rows={1} cols={30} value={this.state.PDFs} onChange={(event) => this.handleonChangePDFs(event)} placeholder='Please enter InvoiceNo (single/multiple)'> </textarea>
            </div>)
      }
      if (this.state.pdfType === "EmailInvoiceDownload") {
         htmlele.push(
            <div class="row">
               <label className="col-lg-3 text-bold " >AirLine</label>

               <Select className="col-lg-9 d-flex justify-content-end"
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti
                  options={this.optionss}
                  onChange={(e) => this.changeAirlineValue(e)}



               />            </div>)
      }

      return htmlele
   }
   changeClientValue = (e) => {
      console.log(e)
      let clientValues = []
      for (let i = 0; i < e.length; i++) {
         let client = e[i].label;
         clientValues.push(client)
      }
      let clientString = clientValues.join(',')

      console.log(clientString)
      this.setState({ clientString });
   }
   getClientList = () => {
      const requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json",
            'Authorization': "JWT-" + localStorage.getItem("ros_token")
         }
      };

      fetch(process.env.REACT_APP_ROS_BASE_URL + '/getclientlist', requestOptions)
         .then(response => response.json())
         .then(res => this.refreshDataClientList(res))
         .catch(err => console.error(err));
   }
   refreshDataClientList = (res) => {
      //  console.log(res);
      let client = [];

      if (res["success"]) {

         client = res["results"];
         this.options = res["results"].map(c => ({ label: c.clientName, value: c.clientId }))


         //console.log( this.options);
         // this.setPage(res["result"]);
         this.setState({ client });
      }
      //console.log(this.state.client)
   }
   changeAirlineValue = (e) => {
      console.log(e)
      let AirlineValue = []
      for (let i = 0; i < e.length; i++) {
         let client = e[i].label;
         AirlineValue.push(client)
      }
      let AirlineString = AirlineValue.join(',')

      console.log(AirlineString)
      this.setState({ AirlineString });
   }
   getAirLineList = () => {
      const requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json",
            'Authorization': "JWT-" + localStorage.getItem("ros_token")
         }
      };

      fetch(process.env.REACT_APP_ROS_BASE_URL + '/getairlinelist', requestOptions)
         .then(response => response.json())
         .then(res => this.refreshDataAirlineList(res))
         .catch(err => console.error(err));
   }

   refreshDataAirlineList = (res) => {
      console.log(res);
      let airLine = [];

      if (res["success"]) {
         // console.log(res["results"])
         this.optionss = res["results"].map(c => ({ label: c.clientName, value: c.clientId }))

         airLine = res["results"];
         // console.log(clients);
         // this.setPage(res["result"]);
         this.setState({ airLine });
      }
   }
   getFOrmattedExcel = (dateValue) => {
      let val = Date.parse(dateValue);
      if (val) {
         // const datev = new Date(dateValue);
         // const strDate = this.getMonthname(datev.getMonth()+1)+"-"+datev.getDate()  + "-" + datev.getFullYear()+"-"+datev.getHours()+":"+datev.getMinutes()+":"+datev.getSeconds();
         const strDate = moment.tz(val, "US/Pacific").format('MM-YYYY-DD--hh:mm A');
         return strDate;
      } else {
         val = "";
         console.log("");
      }
      return dateValue;
   }
   downloadCsv = () => {
      let csvNameRaw = this.state.source;
      let csvName = csvNameRaw.replace(" ", "_")
      let link = process.env.REACT_APP_ABOT_BASE_URL + "/assets/PInvoice/" + csvName + ".csv";
      console.log(link);

      return link;
   }
   downloadFile = () => {
      let downloadType = this.state.downloadType;
      if (downloadType === "PDF") {
         this.downloadPDFFiles();
      }
      if (downloadType === "Excel") {
         this.downloadPDFFiles();
         //this.downloadExcelFile()
      }
   }
   handlechangeDate = (date) => {
      const startTime = date;
      console.log(startTime)
      this.setState({ startTime });
      // this.getDataByDate(startTime,this.state.stopTime,this.state.selectedClients);       
   }
   handlechangeEndDate = (date) => {
      const stopTime = date;
      console.log(stopTime)
      this.setState({ stopTime });
      // this.getDataByDate(this.state.startTime,stopTime,this.state.selectedClients);

   }
   handlechangestDate = (date) => {
      const startDate = date;
      this.setState({ startDate });
      // this.getDataByDate(startTime,this.state.stopTime,this.state.selectedClients);       
   }
   handlechangeEnDate = (date) => {
      const stopDate = date;
      this.setState({ stopDate });
      // this.getDataByDate(this.state.startTime,stopTime,this.state.selectedClients);

   }
   downloadExcelFile = () => {
      let filename = this.state.source;
      const requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json",
            'Authorization': "JWT-" + localStorage.getItem("ros_token")
         }
      };

      // const queryString = "page=" + (page.number - 1) + "&size=" + page.size + "&searchText=" + this.state.searchText +"&sortDirection="+this.state.sortDirection +"&sortFieldName="+this.state.sortFieldName;
      const queryString = "filename=" + filename
      fetch(process.env.REACT_APP_ROS_BASE_URL + '/getfile?' + queryString, requestOptions)
         .then(response => {
            // const filename =  response.headers.get('Content-Disposition').split('filename=')[1];
            response.blob().then(blob => {
               let url = window.URL.createObjectURL(blob);
               let a = document.createElement('a');
               a.href = url;
               a.download = filename + ".xlsx";
               a.click();
            });
         });
   }

   handleonChangeSource = (event) => {
      let source = { ...this.state.source }
      source = event.target.value;
      this.setState({ source })
   }
   getFormatStartDate = (val) => {
      let date
      if (val) {
         date = moment.tz(val, "Asia/Kolkata").format('DD-MMM-yyyy');

         //date = moment.tz(val, "Asia/Kolkata").format('DD-MMM-yyyy 00:00');
         //date= moment.tz(val,"Asia/Kolkata").format('yyyy-MM-DD');
      }
      else {
         date = "";
      }
      //var date = moment.tz(val,"US/Pacific").format("hh:mm:ss");

      return date;
   }
   getFormatEndDate = (val) => {
      let date
      if (val) {
         date = moment.tz(val, "Asia/Kolkata").format('DD-MMM-yyyy');
         //  date = moment.tz(val, "Asia/Kolkata").format('DD-MMM-yyyy 23:59');
         //date= moment.tz(val,"Asia/Kolkata").format('yyyy-MM-DD');
      }
      else {
         date = "";
      }
      //var date = moment.tz(val,"US/Pacific").format("hh:mm:ss");

      return date;
   }
   downloadPDFFiles = () => {
      let pdfnames
      let apiname
      if (this.state.pdfType === "ClientName") {
         let startTimeN = this.getFormatStartDate(this.state.startTime);
         let stopTimeN = this.getFormatEndDate(this.state.stopTime);
         pdfnames = "pdfnames=" + this.state.clientString + "&airlinenames=" + this.state.AirlineString + "&startTime=" + startTimeN + "&endTime=" + stopTimeN
         apiname = "/getcustomerzippdfs?"
      }
      if (this.state.pdfType === "SystemInvoice") {
         pdfnames = "pdfnames=" + (this.state.PDFs).replace(/\n/g, ",");
         apiname = "/getsystemzippdfs?"
      }
      if (this.state.pdfType === "AirlineInvoice") {
         pdfnames = "pdfnames=" + (this.state.PDFs).replace(/\n/g, ",");
         apiname = "/getairlinezippdfs?"
      }
      if (this.state.pdfType === "EmailInvoiceDownload") {
         pdfnames = "pdfnames=" + this.state.AirlineString
         apiname = "/getEmailzippdfs?"
      }
      if (this.state.downloadType === "Excel") {
         let startTimeN = this.getFormatStartDate(this.state.startDate);
         let stopTimeN = this.getFormatEndDate(this.state.stopDate);

         pdfnames = "pdfnames=" + this.state.clientString + "&airlinenames=" + this.state.AirlineString + "&startTime=" + startTimeN + "&endTime=" + stopTimeN
         apiname = "/getcreditnotezippdfs?"
      }



      const requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json",
            'Authorization': "JWT-" + localStorage.getItem("ros_token")
         }
      };
      console.log(apiname)

      // const queryString = "page=" + (page.number - 1) + "&size=" + page.size + "&searchText=" + this.state.searchText +"&sortDirection="+this.state.sortDirection +"&sortFieldName="+this.state.sortFieldName;

      const queryString = pdfnames
      fetch(process.env.REACT_APP_ROS_BASE_URL + apiname + queryString, requestOptions)
         .then(response => {
            // const filename =  response.headers.get('Content-Disposition').split('filename=')[1];
            response.blob().then(blob => {
               let url = window.URL.createObjectURL(blob);
               let a = document.createElement('a');
               const current = new Date();
               const date = `${current.getDate()}-${current.getMonth() + 1}-${current.getFullYear()}`;
               const time = `${current.getHours()}${current.getMinutes()}${current.getSeconds()}`;
               const filename = `${date}_${time}_Zip`;

               // let filename = date + "_Zip";
               a.href = url;
               a.download = filename + ".zip";
               a.click();
            })

         })
   }

   refreshByPDFFiles = (res) => {
      console.log(res);

      if (res["success"]) {

         const data = res["result"];
         console.log(data);
         // this.setPage(res["result"]);
         this.setState({ data });

      }
   }

   handleonChangePDFs = (event) => {
      let PDFs = { ...this.state.PDFs }
      PDFs = event.target.value;
      console.log(PDFs)
      this.setState({ PDFs })
   }

   handleonChangeDownloadType = (event) => {
      let downloadType = { ...this.state.downloadType }
      downloadType = event.target.value;
      this.setState({ downloadType })
   }
   handleonChangepdfType = (event) => {
      let pdfType = { ...this.state.pdfType }
      pdfType = event.target.value;
      this.setState({ pdfType })
   }


}


export default withRouter(CSVDownloadComponent);

