import React, { Component } from 'react';
import './table.css';
import { BsFillCaretRightFill,BsFillCaretLeftFill } from "react-icons/bs";

const MAX_PAGE_BUTTONS = 5;
class PaginationComponent extends Component {
   state = { 
      
   }
  
   render() { 
      const firstButtonClasses = "page-link firstButtonClasses pagination_button" + (this.disableMoveFirstPageButton() ? " disable" : "");
      const lastButtonClasses = "page-link pagination_button" + (this.disableMoveLastPageButton() ? " disable" : "");

      return ( 
         <div className="row ml-0 mb-1 mt-1 mr-0 pl-0 ml-0">
            <div className="col-lg-11 col-md-12 col-sm-12 col-12 mr-0 pr-0 mb-0 pl-0 ml-0">
               <div className="row form-inline col-lg-6 col-md-6 col-sm-6 col-6 mb-0 ml-0 pl-0 inline-block">
                  <div className="col-lg-2 col-md-2 col-sm-2 col-2 form inline-block ml-0 pl-0 mt-1 pt-1" >
                     <span className="pl-0 ml-0" ><label className="Max-Rows col-lg-2 mr-0 pr-0 pl-0 ml-0">Max Rows</label>
                     <input type="number" className="Max_Row_Text text-dark  text-left ml-2" min="1" max="100" value={this.props.page.size} onChange={(event) => this.handleSizeOnChange(event) }/>
                     </span>
                  </div>
                  
                  <div className="col-lg-9 col-md-9 col-sm-9 col-9 inline-block ml-0 pl-0 mr-0 pr-0">
                  <ul className="pagination float-right mt-0 mb-0 mr-0 pr-0 ">
                  <li className="page-item small mr-1 pagination_button mt-1" ><span className={firstButtonClasses} onClick={() => this.handlePageClick(1)}>First</span></li>
                  <li className="page-item small mt-1" ><span className="page-link pagination_button" onClick={() => this.handlePageClick(this.props.page.number-1)}><BsFillCaretLeftFill /></span></li>
                  {this.createPageButtons()}
                  <li className="page-item small mt-1"><span className="page-link pagination_button" onClick={() => this.handlePageClick(this.props.page.number + 1)} > <BsFillCaretRightFill /></span></li>
                  <li className="page-item small mt-1 ml-1 pagination_button" ><span className={lastButtonClasses} onClick={() => this.handlePageClick(this.props.page.totalPages)}>Last</span></li>
                   </ul>
                   </div>
               </div>
                            
            </div>
         </div>
       );
   }

   componentDidMount() {
      //this.adjustPageFirstButton();
   }

   createPageButtons = (selectedPage) => {
      let buttonEle = [];
      const pagebuttonarr = [...this.props.pagebuttons];

      for (let i = 0; i < pagebuttonarr.length && pagebuttonarr[i] <= this.props.page.totalPages; i++) {
         let className = "page-item small mt-1"
         if (pagebuttonarr[i] === this.props.page.number) className += " active"
         const ele = <li key={i} className={className} onClick = {() => {this.handlePageClick(pagebuttonarr[i])}} ><span className="page-link">{pagebuttonarr[i]}</span></li>
         buttonEle.push(ele)
      }
      return buttonEle;
   }

   handlePageClick = pageNumber => {

      if (pageNumber > 0 && pageNumber <= this.props.page.totalPages && (!this.props.disable)) {
         if (pageNumber === 1) {
            this.adjustPageFirstButton();
         } else if ( pageNumber === this.props.page.totalPages) {
            this.adjustPageLastButton();
         }else {
            this.adjustButtons(pageNumber)
         }
         let page = {...this.props.page}
         page.number = pageNumber;
         if (this.props.loadData) {
            
            this.props.loadData(page);
         }
         this.props._this.setState({ page })
      }
   }

   adjustButtons = (pageNumber) => {
      if (this.props.pagebuttons) {
         let pagebuttons = [...this.props.pagebuttons]
         if (pageNumber === pagebuttons[pagebuttons.length - 1] && pageNumber < this.props.page.totalPages) {
            for (let i = 0; i < pagebuttons.length-1; i++) {
               pagebuttons[i] = pagebuttons[i+1]
            }            
            pagebuttons[pagebuttons.length - 1] = pagebuttons[pagebuttons.length - 2] + 1;
            this.props._this.setState({pagebuttons})
         } else if (pageNumber === pagebuttons[0] && pagebuttons[0] > 1) {
            for (let i = pagebuttons.length-1; i > 0 ; i--) {
               pagebuttons[i] = pagebuttons[i-1]
            }              
            pagebuttons[0] = pagebuttons[1] - 1;
            this.props._this.setState({pagebuttons})
         }
      }
   }

   handleSizeOnChange = (event) => {
      let page = { ...this.props.page }
      page.size = event.target.value;
      this.props._this.setState({page})
     // this.props._this.getData();
   }

   adjustPageFirstButton() {      
      let pagebuttons = [];
      for (let i = 1; i <= MAX_PAGE_BUTTONS && i <= this.props.page.totalPages; i++) {
         pagebuttons.push(i);
      }
      this.props._this.setState({ pagebuttons })
   }

   adjustPageLastButton() {
      let pagebuttons = [];
      const minVal = this.props.page.totalPages - MAX_PAGE_BUTTONS > 0 ? (this.props.page.totalPages - MAX_PAGE_BUTTONS + 1) : 1
      for (let i = minVal; i <= this.props.page.totalPages; i++) {
         pagebuttons.push(i);
      }
      this.props._this.setState({pagebuttons})      
   }

   disableMoveFirstPageButton() {    
      return this.props.page.number === 1;
   }

   disableMoveLastPageButton() {
      return this.props.page.number === this.props.page.totalPages
   }
}
 
const adjustInitPageButton=(_this) => {      
   let pagebuttons = [];
   for (let i = 1; i <= MAX_PAGE_BUTTONS && i <= _this.state.page.totalPages; i++) {
      pagebuttons.push(i);
   }
   _this.setState({ pagebuttons })
}
export { PaginationComponent, adjustInitPageButton };
