import React, { Component } from 'react';
//import { confirmAlert } from 'react-confirm-alert'; // Import
import PageTitle from '../page.title';
import './recon.css';
import {tofix_2} from '../../common/utility.jsx'
import { PaginationComponent, adjustInitPageButton } from '../GridTable/pagination.component';
import GridTableComponent from '../GridTable/grid.table.component';


class AgentvsAirlineComponent extends Component {
   blankCate = {
      TicketInvoiceDate: undefined, TicketPNR: undefined, TicketPayment: undefined, TicketStatus: undefined,AirlineInvoiceDate:undefined,
      AirlinePNR: undefined,AirlineInvoiceId:undefined, AirlineInvoiceAmount: undefined }
   
   //blankSubItem = { SubItem: undefined, subItemName: undefined, price: undefined, description: undefined };
   boxClass = { totalAgentInvoice: 'recon_less_btn ', AgInWithnoAiIn: 'recon_less_btn ', partiallyMatched: 'recon_less_btn ', AiInWithNoAgIn: 'recon_less_btn ', fullyMatched: 'recon_btn '  };
   
   state = { 
      addModalShow :false ,
      sortFieldName:"itemName",
      sortDirection:"A",
      detail: false,
      reconClass:"recon_less_btn ",
      data: [],
      record: { ...this.blankCate },
      boxRecord: { ...this.boxClass },
      pageTitle : "",
      page : {offset :  1, /*Start record from*/
         totalPages : 1, /*number of pages*/
         totalElements : 0, /*number of records*/
         last : true, /*is last page*/
         first: true, /*is first page*/
         size: 20, /*page size*/
         number : 1, /* current page*/
         numberOfElements: 0 /*Total number of record*/
      },
      pagebuttons: [],
      headerTitle:"Item Details",
      type:[],
      searchText: "",
      errorMessage: "",
      successMessage : "",
      disableTable: false,
      categories: [],
      mode : "none",
      selectedTeam:"",
   }

   currencies = {
    "USD": "$",
    "INR": "₹"
 }
 
 currency = "INR";

 getCurrent() {
    return this.currencies[this.currency]
 }
   
   componentDidMount() {
     // this.getData(this.state.page);
      this.getData();
      //this.loadCategories();
    

   }
   render() { 
      return ( 
         <React.Fragment>            
           
            {this.state.errorMessage && <h5 className="alert-danger mt-0 ml-2 mr-4"> {this.state.errorMessage} <span className="float-right pointer-cursor" onClick={() => { this.setState({ errorMessage: "" }) }}>&#x2716;</span> </h5>}
            {this.state.successMessage && <h5 className="alert-success mt-0 ml-2 mr-4"> {this.state.successMessage} <span className="float-right pointer-cursor" onClick={() => { this.setState({ successMessage: "" }) }}>&#x2716;</span> </h5> }
            {this.loadPageElement()}
            {this.showHideDetails()}
         </React.Fragment>
       );
   }
   showHideDetails=() => {
    //  console.log(this.state.record)
      if(this.state.addModalShow) {
         //console.log("addModalShow")
         return <div ></div>
      }
   }

   addModalClose =() => {
      this.setState({addModalShow:false});
   }

   openModalwithDetails=(event)=>{
      const headerTitle = "Item Details"
      event.preventDefault();
      this.setState({addModalShow:true});
      this.setState({ mode : "update"})
      this.setState({headerTitle})
     // console.log(this.state.addModalShow);
      }

   itemView = () => {
      window.location.reload(); 
   }

   loadpageTitle = ()=>
     {
        let htmlele=[];
            htmlele.push(
               <div key={"div"+this.state.record.tableID} className="col-12 mr-1 pl-0 ml-0 pr-1 pb-0 pr-0">
               <span className="col-12 pl-0 ml-0 mr-0 pr-0 page-title"> {this.state.pageTitle}</span> 
               </div>
              )    
           return htmlele;
        }

   loadPageElement = () => {
      if (this.state.detail) {
         
         return (
            <div className="row card pt-1 pb-2 ml-0 mr-2 pr-2">
               <span className="col-12 pl-2 ml-3">
               <PageTitle  title={this.loadpageTitle()} />
               </span> 
            <p className="mb-2 col-12 mr-0 pr-0 mb-2 mt-0 pt-0 ml-2 pl-3 page-title-text">Edit, update items/subitems for your restaurant</p>
         </div>
         )
      } else {
         return (
         <React.Fragment>
            {this.state.errorMessage && <h5 className="alert-danger mt-0 ml-2 mr-4"> {this.state.errorMessage} <span className="float-right pointer-cursor" onClick={() => { this.setState({ errorMessage: "" }) }}>&#x2716;</span> </h5>}
             {this.state.successMessage && <h5 className="alert-success mt-0 ml-2 mr-4"> {this.state.successMessage} <span className="float-right pointer-cursor" onClick={() => { this.setState({ successMessage: "" }) }}>&#x2716;</span> </h5> }
             
         <div className="row col-lg-12 pt-1 pb-2 ml-0 pl-0 mt-2 mb-1 pr-0 mr-0">
         <PageTitle title={this.loadpageTitle()} />
         
        <div className="form form-inline row pl-0 ml-0 col-lg-12 col-md-12 col-sm-12 col-12 pt-2 mr-0 pr-0 " > 
        <span className={this.state.boxRecord.totalAgentInvoice+"btn ml-2 pl-2 row pt-1 pb-1"}   onClick={() => this.onBoxClick("totalAgentInvoice")}>
        <label className="pl-0 ml-0 col-md-12 col-lg-12 d-flex  justify-content-center font_14 mr-0 pr-0 mb-2">Total <br/>Agent Invoices</label> 
        <label className="col-md-12 col-lg-12 mb-1 mt-2 font_24"><b>0</b>  </label> 
        <label className="col-md-12 col-lg-12 mb-1 mt-2">{this.getCurrent()}</label>
        </span>
        <span className={this.state.boxRecord.AgInWithnoAiIn+"btn ml-2 pl-2 row pt-1 pb-1"}   onClick={() => this.onBoxClick("AgInWithnoAiIn")}>
        <label className="pl-0 ml-0 col-md-12 col-lg-12 d-flex  justify-content-center font_14 mr-0 pr-0 mb-2">Agent Invoices With <br/>No Airline Invoices</label> 
        <label className="col-md-12 col-lg-12 mb-1 mt-2 font_24"><b>0</b>  </label> 
        <label className="col-md-12 col-lg-12 mb-1 mt-2">{this.getCurrent()}</label>
        </span>
        <span className={this.state.boxRecord.partiallyMatched+"btn ml-2 pl-2 row pt-1 pb-1"}   onClick={() => this.onBoxClick("partiallyMatched")}>
        <label className="pl-0 ml-0 col-md-12 col-lg-12 d-flex  justify-content-center font_14 mr-0 pr-0 mb-2">Partially<br/> Matched</label> 
        <label className="col-md-12 col-lg-12 mb-1 mt-2 font_24"><b>0</b>  </label> 
        <label className="col-md-12 col-lg-12 mb-1 mt-2">{this.getCurrent()}</label>
        </span>
      
        <span className={this.state.boxRecord.AiInWithNoAgIn+"btn ml-2 pl-2 row pt-1 pb-1"}   onClick={() => this.onBoxClick("AiInWithNoAgIn")}>
        <label className="pl-0 ml-0 col-md-12 col-lg-12 d-flex  justify-content-center font_14 mr-0 pr-0 mb-2">Airline Invoice With <br/>No Agent Invoices</label> 
        <label className="col-md-12 col-lg-12 mb-1 mt-2 font_24"><b>0</b>  </label> 
        <label className="col-md-12 col-lg-12 mb-1 mt-2">{this.getCurrent()}</label>
        </span>
        <span className={this.state.boxRecord.fullyMatched+"btn ml-2 pl-2 row pt-1 pb-1"}   onClick={() => this.onBoxClick("fullyMatched")}>
        <label className="pl-0 ml-0 col-md-12 col-lg-12 d-flex  justify-content-center font_14 mr-0 pr-0 mb-2">Fully<br/> Matched</label> 
        <label className="col-md-12 col-lg-12 mb-1 mt-2 font_24"><b>0</b>  </label> 
        <label className="col-md-12 col-lg-12 mb-1 mt-2">{this.getCurrent()}</label>
        </span>
      </div> 
      <div className="col-lg-12 form-group form-inline mt-1 mb-1 pr-0 mr-0 pl-0 ml-2 ">
      <PaginationComponent page={this.state.page} pagebuttons={this.state.pagebuttons} loadData={this.getData} disable={this.state.disableTable} _this={this} />
      </div>
      </div>
      <div className="col-lg-12 row mt-1 pt-1 pb-2 ml-1 pl-1 mr-1 pr-1">
      <span className="col-lg-6 bg_color pb-0 mb-0 pt-2 pl-2 ml-0 font_14 "> <b>Agent Invoices</b> </span><span className="col-lg-6 font_14 bg_color pt-2 pl-3"> <b>Airline Invoices </b></span>
      <span className="col-lg-6 mt-0 pt-0 pb-2 ml-0 pl-0 mr-0 pr-0">
      <GridTableComponent cols={this.getColsTickets()} data={this.state.data} onRowClick={this.onSelectRecord} disable={this.state.disableTable} _this={this} />
      </span>
      <div className="col-lg-6 mt-0 pt-0 pb-2 ml-0 pl-0 mr-0 pr-0">
      <GridTableComponent cols={this.getColsAirline()} data={this.state.data} onRowClick={this.onSelectRecord} disable={this.state.disableTable} _this={this} />
      </div>
      </div>
         </React.Fragment>);

      }
   }

   getActionLabel=(itemId)=>{
      return(
         <div classNme="row d-flex justify-content-center"> 
 
         <span className="col-lg-3 btn btn-small btn-primary-outline ml-0 pl-0 pr-0 pt-0 pb-0 mt-0 mr-0"
            onClick={(event)=>this.openModalwithDetails(event)}>
            <img alt="Remove" src={process.env.PUBLIC_URL + "/assets/images/edit-row.png"}>
            </img>
         </span> 

<span className="col-lg-3 btn btn-small btn-primary-outline ml-0 pl-0 pr-0 pt-0 pb-0 mt-0  mr-0"
onClick={(event)=>this.handleDeleteItem(event,itemId)}>
<img alt="Remove" src={process.env.PUBLIC_URL + "/assets/images/big-trash.png"}>
</img>
</span>  

</div>
  )
}
            handleSortDirection=(event)=>{
               const sortDirection = event.target.value;
               this.setState({ sortDirection: sortDirection });
            }
   handleSearchTextOnChange = (event) => {
      const searchText = event.target.value;
      this.setState({ searchText: searchText });
   }

   handleSearchClick() {
      
      let page = { ...this.state.page }
      page.number = 1;
      this.setState({page})
      this.getData(page);
      
   }
   onBoxClick = (boxName) => {
      let boxRecord={};
      if (boxName==="totalAgentInvoice"){
         boxRecord={...this.boxClass};
         boxRecord.totalAgentInvoice='recon_less_btn_click ';
         
      } else  if (boxName==="AgInWithnoAiIn"){
         boxRecord={...this.boxClass};
         boxRecord.AgInWithnoAiIn='recon_less_btn_click ';
         
      } else  if (boxName==="partiallyMatched"){
         boxRecord={...this.boxClass};
         boxRecord.partiallyMatched='recon_less_btn_click ';

      } else  if (boxName==="AiInWithNoAgIn"){
         boxRecord={...this.boxClass};
         boxRecord.AiInWithNoAgIn='recon_less_btn_click ';
         
      }else  if (boxName==="fullyMatched"){
         boxRecord={...this.boxClass};
         boxRecord.fullyMatched='recon_less_btn_click ';
      }
      console.log(boxRecord);
     this.setState({addModalShow:true});
     this.setState({ boxRecord, errorMessage: "", successMessage: "",mode : "add" })
  }
  
  getColsTickets = () => {
   return [{ header: "Invoice Date", key: "itemName", rowclasses : "text-center", headerclasses: "w-20 text-center" },
      { header: "PNR/Ticket No", key: "category.categoryName", headerclasses: "w-20" },
      { header: "Sector", key: "description", headerclasses: "w-20",rowclasses : "mr-2" },
      { header: "Amount" , key: "price", headerclasses : "w-15",rowclasses : "pr-2 ",fnValue:tofix_2},
      { header :"Status",key:"itemId",rowclasses : "text-center", headerclasses: "w-20 text-center" }];
}

getColsAirline = () => {
   return [{ header: "Invoice Date", key: "itemName", rowclasses : "text-center", headerclasses: "w-20 text-center" },
      { header: "PNR/Ticket No", key: "category.categoryName", headerclasses: "w-20" },
      { header: "Invoice No.", key: "description", headerclasses: "w-20",rowclasses : "mr-2" },
      { header: "Amount" , key: "price", headerclasses : "w-15",rowclasses : "pr-2 ",fnValue:tofix_2}];
}

   getData=page => {
      const requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json",
            'Authorization' : "JWT-" + localStorage.getItem("ros_token")
         }
      };

     // const queryString = "page=" + (page.number - 1) + "&size=" + page.size + "&searchText=" + this.state.searchText +"&sortDirection="+this.state.sortDirection +"&sortFieldName="+this.state.sortFieldName;

      fetch(process.env.REACT_APP_ROS_BASE_URL + '/getallinvoices', requestOptions)
         .then(response => response.json())
         .then(res => this.refreshData(res))
         .catch(err => console.error(err));
   }

   refreshData = (res) => {
      //console.log(res);
      if (res["success"]) {
         
         const data = res["results"];
         console.log(data);
        // this.setPage(res["result"]);
         this.setState({ data });
         let recordraw = this.blankCate;
         let eachdata =[];
         //console.log(record);
        if (data && data.length > 0) {
           for (let i = 0; i<data.length;i++ ) 
            eachdata = data[i];
            recordraw.AirlineInvoiceDate=eachdata[0];
            recordraw.AirlineInvoiceAmount=eachdata[3];
            recordraw.AirlinePNR=eachdata[1];
            recordraw.AirlineInvoicestatus=eachdata[4];
            //recordraw.TicketInvoiceDate=eachdata[4];
            //recordraw.TicketPNR=eachdata[5];
            //recordraw.TicketPayment=eachdata[6];
            //recordraw.TicketStatus=eachdata[7];
            //recordraw.AirlineInvoiceAmount=eachdata[8];
            console.log(recordraw);
          //  this.setState({record})
        }
      } else {
         const data = [];
         this.setState({ data,errorMessage : "Failed to get item category list!",successMessage :"" });
         console.log("Error");
      }
   }

   setPage = (result) => {
      let page = { ...this.page }
      page.number = result["number"] + 1;
      page.first = result["first"];
      page.last = result["last"];
      page.numberOfElements = result["numberOfElements"];
      page.size = result["size"];
      page.totalElements = result["totalElements"];
      page.totalPages = result["totalPages"];
      this.setState({ page })
      if (page.number === 1) {
         adjustInitPageButton(this);
      }   
   }
}
 
export default AgentvsAirlineComponent;