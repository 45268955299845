import React, { Component } from 'react';
//import { Router, Route, Link  } from 'react-router-dom';
import moment from 'moment';
import 'moment-timezone';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';

import { Link, withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { VscFilter } from "react-icons/vsc";
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { MdCloudUpload, MdCloudDownload } from "react-icons/md";
import './recon.css';
import * as XLSX from "xlsx";
import { Multiselect } from 'multiselect-react-dropdown';
import { tofix_2 } from '../../common/utility.jsx'
import { PaginationComponent, adjustInitPageButton } from '../GridTable/pagination.component';
import GridTableComponent from '../GridTable/grid.table.component';
import BulkUploadComponent from './bulkupload.component';
import CSVDownloadComponent from './csvdownload.component ';
import PDFViewComponent from './pdfview.component';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Route } from 'react-router-dom/cjs/react-router-dom.min';
import MailProcessedComponent from './mailprocessed.component';
const animatedComponents = makeAnimated();
class TicketvsAirlineComponent extends Component {
   blankClient = { clientId: undefined, clientName: undefined };
   click(event) {
      return <MailProcessedComponent />
   }
   state = {
      addModalShowUpload: false,
      addModalShowDownload: false,
      addModalShowPDF: false,
      sortFieldName: "itemName",
      sortDirection: "A",
      detail: false,
      filename: '',
      pdflink: "",
      startTime: new Date(),
      stopTime: new Date(),
      recordDataFiles: [],
      data: [],
      dataAll: [],
      dataTicket: [],
      dataAirline: [],
      pdffile: "",
      pdf: "",
      source: "-",
      recordData: { ...this.blankData },
      recordTicket: { ...this.blankTicket },
      recordAirline: { ...this.blankAirline },
      boxRecord: { ...this.boxClass },
      pageTitle: "",
      page: {
         offset: 1, /*Start record from*/
         totalPages: 1, /*number of pages*/
         totalElements: 0, /*number of records*/
         last: true, /*is last page*/
         first: true, /*is first page*/
         size: 20, /*page size*/
         number: 1, /* current page*/
         numberOfElements: 0 /*Total number of record*/
      },
      pagebuttons: [],
      headerTitle: "",
      type: [],
      searchText: "",
      searchClientText: "",
      errorMessage: "",
      successMessage: "",
      disableTable: false,
      categories: [],
      mode: "none",
      selectedTeam: "",
      fileList: [],
      selectAllOption: { clientId: "0", clientName: "Select-All" },
      client: [this.blankClient],
      //clients:this.blankClient,
      selectedClients: []
   }

   constructor() {
      super();
      this.multiselectRef = React.createRef();
      // this.optionsSelect=
   }
   currencies = {
      "USD": "$",
      "INR": "₹"
   }
   resetValues() {
      // By calling the belowe method will reset the selected values programatically
      this.multiselectRef.current.resetSelectedValues();
   }

   currency = "INR";
   getCurrent() {
      return this.currencies[this.currency]
   }
   componentDidMount() {
      this.getData(this.state.page);
      //this.getFilesList();
      this.getClientList();
      //this.getFileProcessData();  
   }
   render() {
      return (
         <React.Fragment>

            {this.state.errorMessage && <h5 className="alert-danger mt-0 ml-2 mr-4"> {this.state.errorMessage} <span className="float-right pointer-cursor" onClick={() => { this.setState({ errorMessage: "" }) }}>&#x2716;</span> </h5>}
            {this.state.successMessage && <h5 className="alert-success mt-0 ml-2 mr-4"> {this.state.successMessage} <span className="float-right pointer-cursor" onClick={() => { this.setState({ successMessage: "" }) }}>&#x2716;</span> </h5>}
            {this.loadPageElement()}
         </React.Fragment>
      );
   }

   onFileChange = (event) => {
      // Update the state 
      let files = event.target.files[0];
      //console.log( files );
      let reader = new FileReader();
      reader.readAsDataURL(files);
      // this.setState({ selectedFile:  }); 

      reader.onload = (e) => {
         let pdf = this.state.pdf;

         let itemImageraw = e.target.result
         //console.log(itemImageraw);
         var itemImagerawarray = itemImageraw.split(";");
         let itemImagearray = itemImagerawarray[1].split(",");
         pdf = itemImagearray[1];
         // console.log(pdf);
         this.setState({ pdf, pdffile: files });
      }
   }
   loadPageElement = () => {
      return (

         <React.Fragment>
            <div className="row col-lg-12 pt-0  ml-1 pl-0 mt-0 mb-1 pr-0 mr-0 d-flex justify-content-center">
               {this.state.errorMessage && <h5 className="alert-danger pt-0 mt-0 ml-2 mr-4"> {this.state.errorMessage} <span className="float-right pointer-cursor" onClick={() => { this.setState({ errorMessage: "" }) }}>&#x2716;</span> </h5>}
               {this.state.successMessage && <h5 className="alert-success pt-0 mt-0 ml-2 mr-4"> {this.state.successMessage} <span className="float-right pointer-cursor" onClick={() => { this.setState({ successMessage: "" }) }}>&#x2716;</span> </h5>}
               <div className="row col-lg-12 pt-0  ml-1 pl-0 mt-0 mb-1 pr-0 mr-0 d-flex justify-content-center">
                  <div className=" card bg_color col-lg-12 pt-1 pb-2 ml-0 pl-0 mr-0 pr-0 mt-2 mb-1 mr-0 justify-content-center">
                     <h1 className="col-lg-12 mt-2 pl-0 ml-2 pt-0 text-center" > Reconciliation </h1>
                  </div>
               </div>
               <div className="col-lg-12 row mt-0 pt-0 mb-0 pr-0 mr-0 pl-0 ml-0  ">
                  <div className="col-lg-2 mt-0  pr-0 mr-0 pl-0 ml-0 ">
                     <label className="col-lg-12 small text-bold" >Search (PNR/Invoice)</label>
                     <input name="searchParam" type="textbox" className="form-control ml-0 mr-0 pr-0 pl-1 mt-0 col-lg-11 col-md-12 col-sm-12 col-12" value={this.state.searchParam} onChange={this.handleSearchByPNRResellerInvoice}></input>

                  </div>

                  <div className="col-lg-8  mt-0  pr-0 mr-0 pl-0 ml-0 d-flex justify-content-center">
                     <span className="col-lg-6  pt-1 mt-0 mb-0  pr-0 mr-0 pl-0 ml-0 ">
                        <label className="col-lg-12 row small text-bold" >Family</label>
                        <span className=" col-lg-12 small h-30px pt-0 mt-0 pb-0 mb-0 pr-0 mr-0 pl-0 ml-0 ">
                           <Select
                              components={animatedComponents}

                              options={this.options} // Options to display in the dropdown
                              //maxSelectedLabels={3}
                              //options={[this.state.selectAllOption, ...this.state.client]} // Options to display in the dropdown
                              isMulti
                              value={this.optionsSelect}
                              //options={this.state.client}
                              closeMenuOnSelect={false}
                              onSearch={this.searchClient}
                              // selectedValues={this.state.selectedClients} // Preselected value to persist in dropdown
                              // onSelect={this.onSelectClient} // Function will trigger on select event
                              // onRemove={this.onRemoveClient} // Function will trigger on remove event
                              // displayValue= "clientName" // Property name to display in the dropdown options
                              //showCheckbox={true}
                              //closeOnSelect={false}
                              //keepSearchTerm={true}
                              //showArrow={true}
                              onChange={(e) => this.changeClientValue(e)}
                              //caseSensitiveSearch={true}
                              //resetSelectedValues={this.resetValues}
                              //ref={this.multiselectRef} 

                              className="  pt-0 pb-0 "
                           />
                        </span>
                     </span>
                     <span className="col-lg-2 pt-0 mt-0 mb-0 pb-0 pr-0 mr-0 pl-1 ml-0">
                        <label className="col-lg-12 small text-bold">From</label>
                        <DatePicker className="form-control col-lg-11" dateFormat="dd-MM-yyyy" selected={this.state.startTime} datePickerType="single" popperPlacement="left-start" value={this.state.startTime} name="startTime" onChange={this.handlechangeDate} scrollableYearDropdown showYearDropdown />
                     </span>

                     <span className="col-lg-2 pt-0 mt-0 mb-0 pb-0 pr-0 mr-0 pl-0 ml-0">
                        <label className="col-lg-12 small text-bold ">To</label>
                        <DatePicker className="form-control col-lg-11" dateFormat="dd-MM-yyyy" selected={this.state.stopTime} datePickerType="single" popperPlacement="left-start" value={this.state.stopTime} name="stopTime" onChange={this.handlechangeEndDate} scrollableYearDropdown showYearDropdown />
                     </span>
                     <span className="col-lg-2 pt-1 mt-3 mb-0 pb-0 pr-0 mr-0 pl-0 ml-0">
                        <button className="col-lg-10 pl-0 ml-0 mr-0 pr-0 pt-0 mt-2 pb-0 mb-0 btn confirm_btn_popup" onClick={(event) => this.getDataByDate(this.state.startTime, this.state.stopTime, this.state.selectedClients)}>Fetch</button>
                     </span>
                  </div>

                  <div className="col-lg-2 row pt-0 mt-0   pr-1 mr-0 pl-0 ml-0 ">
                     <span className=" h-30px col-lg-12 pt-2 mt-1 mb-0 pr-0 mr-0 pl-0 ml-0 d-flex justify-content-center" data-for="registerTip">
                        <button data-tip data-for='bulkUploadTip' className="col-lg-3 pl-0 ml-0 mr-0 pr-0 pt-0 pb-0 mt-3 mb-0 btn confirm_btn_popup" onClick={(event) => this.openModalwithUpload(event)}><MdCloudUpload className="cloud_icon" /></button>
                        <ReactTooltip id="bulkUploadTip" place="top" effect="solid">
                           Upload
                        </ReactTooltip>
                        <button data-tip data-for='DownloadTip' className="col-lg-3 pl-0 ml-1 mr-0 pr-0 pt-0 pb-0 mt-3 mb-0 btn confirm_btn_popup" onClick={(event) => this.openModalwithDownload(event)}><MdCloudDownload className="cloud_icon" /></button>
                        <ReactTooltip id="DownloadTip" place="top" effect="solid">
                           Download
                        </ReactTooltip>
                        <button className="col-lg-5 pl-0 ml-1 mr-0 pr-0 pt-0 mt-3 pb-0 mb-0 btn confirm_btn_popup" onClick={(event) => this.downloadExcel(this.state.data)}>Export</button>
                     </span>
                  </div>
                  {/*<div className="col-lg-12">
           <Link to="/portal/mailprocessed" >
           <button className="btn mail_btn_click col-lg-12">Mail&nbsp;Processed</button>
           </Link>
         </div>*/}


               </div>


            </div>
            <div className="col-lg-12 mt-0 pt-0 pb-2 ml-0 pl-0 mr-0 pr-0">
               <PaginationComponent page={this.state.page} pagebuttons={this.state.pagebuttons} loadData={this.getData} disable={this.state.disableTable} _this={this} />

               <span className="col-lg-12 mt-0 pt-0 pb-2 ml-0 pl-0 mr-0 pr-0">
                  <GridTableComponent cols={this.getColsAll()} data={this.state.data} disable={this.state.disableTable} _this={this} />
               </span>
            </div>
            {this.showHideUpload()}
            {this.showHidePDF()}
            {this.showHideDownload()}
         </React.Fragment>);
   }

   changeClientValue = (e) => {
      console.log(e)
      let clientValues = []
      for (let i = 0; i < e.length; i++) {
         let client = e[i].label;
         clientValues.push(client)
      }
      let clientString = clientValues.join(',')

      console.log(clientString)
      this.setState({ clientString });
   }
   getSystemHyperlink = (val) => {
      let htmlele = [];
      let flag = "invoice";
      let link = process.env.REACT_APP_ABOT_BASE_URL + "/assets/PInvoice/A-" + val + ".pdf";
      //console.log(link);
      if (val) {
         htmlele.push(
            <span className=" cursorpointer text-dark font_12" onClick={(event) => this.openModalwithPDF(flag, event, val, link, 'systemId')}><u>{val}</u></span>
            //  http://localhost:3000/assets/PInvoice/T-42345.pdf
         )
      }
      else {
         htmlele.push(
            <span   >
               <input type="file" accept=".pdf" hidden id="singleAirlineUploadTip" name="PDFStyle" onChange={(event) => this.onFileChange(event)} />
               <label data-tip data-for="singleAirlineUploadTip" className="h-20px pt-0 mt-0 mb-0 pb-0" htmlFor="singleAirlineUploadTip"> <MdCloudUpload htmlFor="singleAirlineUploadTip" className="cursorpointer cloud_icon_small" /></label>
               <ReactTooltip data-background-color="transparent" id="singleAirlineUploadTip" place="top" effect="solid">
                  Upload
               </ReactTooltip>
            </span>

         )
      }
      return htmlele;
   }

   getPNRHyperlink = (val) => {
      let htmlele = [];
      let flag = "invoice";
      let link = process.env.REACT_APP_ABOT_BASE_URL + "/assets/PInvoice/A-" + val + ".pdf";
      //console.log(link);
      if (val) {
         htmlele.push(
            <span className=" cursorpointer text-dark font_12" onClick={(event) => this.openModalwithPDF(flag, event, val, link, 'pnrId')}><u>{val}</u></span>
            //  http://localhost:3000/assets/PInvoice/T-42345.pdf
         )
      }
      else {
         htmlele.push(
            <span   >
               <input type="file" accept=".pdf" hidden id="singleAirlineUploadTip" name="PDFStyle" onChange={(event) => this.onFileChange(event)} />
               <label data-tip data-for="singleAirlineUploadTip" className="h-20px pt-0 mt-0 mb-0 pb-0" htmlFor="singleAirlineUploadTip"> <MdCloudUpload htmlFor="singleAirlineUploadTip" className="cursorpointer cloud_icon_small" /></label>
               <ReactTooltip data-background-color="transparent" id="singleAirlineUploadTip" place="top" effect="solid">
                  Upload
               </ReactTooltip>
            </span>

         )
      }
      return htmlele;
   }


   getColsAll = () => {
      return [{ header: "DocID", key: "docID", headerclasses: "w-10 text-center ", rowclasses: "w-9  text-center", fnValue: this.getSystemHyperlink },
      { header: "InvoiceDate", key: "airlinesinvoicedate", headerclasses: "w-8 text-center", rowclasses: "w-8 text-center", },
      { header: "InvoiceNo.", key: "airlineInvoiceNo", headerclasses: "w-8 text-center ", rowclasses: "w-8 mr-0 text-center" },
      { header: "PNR", key: "pnr", headerclasses: "w-8 text-center", rowclasses: "w-8 text-center", fnValue: this.getPNRHyperlink },
      { header: "CreditNoteNo", key: "airlineCreditDebitNoteNo", headerclasses: "w-7 text-center", rowclasses: "w-5  text-center" },
      { header: "PaxName", key: "paxName", headerclasses: "w-10 text-center ", rowclasses: "w-10 text-center " },
      { header: "Amount", key: "totalfare", headerclasses: "w-8 text-center ", rowclasses: "w-10 text-center " },
      { header: "Non-Taxable", key: "nonTaxable", headerclasses: "w-10 text-center ", rowclasses: "w-10 text-center " },
      // { header: "ROT", key: "rateofTax", headerclasses: "w-5 text-center ", rowclasses: "w-5 text-center " },
      { header: "Airline", key: "airlineName", headerclasses: "w-10 text-center ", rowclasses: "w-10  text-center" },
      { header: "Bill To Name", key: "billToName", headerclasses: "w-10 text-center ", rowclasses: "w-10 text-center" },
      { header: "Doc Date", key: "docDate", headerclasses: "w-10 text-center ", rowclasses: "w-10 text-center" },

         //{ header: "Status", key: "cartAmendmentBookingStatus", headerclasses: "w-5 text-center", rowclasses: "w-8 text-center" },
         //{ header: "Source", key: "source", headerclasses: "w-8 text-center", rowclasses: "w-10 text-center" }

      ];
   }

   handleSearchByPNRResellerInvoice = (event) => {
      let searchParam = this.state.searchParam
      searchParam = event.target.value;
      console.log(searchParam);
      this.setState({ searchParam });
      if (searchParam.length > 0)
         this.getDataByPNRResellerInvoice(searchParam);
      else {
         this.getData(this.state.page);
      }
   }

   getDataByPNRResellerInvoice = (pnr) => {
      const requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json",
            'Authorization': "JWT-" + localStorage.getItem("ros_token")
         }
      };

      const queryString = "searchParam=" + pnr
      fetch(process.env.REACT_APP_ROS_BASE_URL + '/getswandatabyypnrinvoicereseller?' + queryString, requestOptions)
         .then(response => response.json())
         .then(res => this.refreshPNRResellerInvoice(res))
         .catch(err => console.error(err));
   }

   refreshPNRResellerInvoice = (res) => {
      if (res["success"]) {

         const data = res["result"];
         console.log(data);
         // this.setPage(res["result"]);
         this.setState({ data });

      }
   }
   getFilesList = () => {
      const requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json",
            'Authorization': "JWT-" + localStorage.getItem("ros_token")
         }
      };

      fetch(process.env.REACT_APP_ROS_BASE_URL + '/getfilelist', requestOptions)
         .then(response => response.json())
         .then(res => this.refreshDataGetFile(res))
         .catch(err => console.error(err));
   }

   refreshDataGetFile = (res) => {
      console.log(res);
      let fileList = [];
      if (res["success"]) {

         fileList = res["results"];
         console.log(fileList);
         // this.setPage(res["result"]);
         this.setState({ fileList });
      }
   }

   getClientList = () => {
      const requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json",
            'Authorization': "JWT-" + localStorage.getItem("ros_token")
         }
      };

      fetch(process.env.REACT_APP_ROS_BASE_URL + '/getclientlist', requestOptions)
         .then(response => response.json())
         .then(res => this.refreshDataClientList(res))
         .catch(err => console.error(err));
   }

   refreshDataClientList = (res) => {
      console.log(res);
      let client = [];

      if (res["success"]) {

         client = res["results"];
         this.options = res["results"].map(c => ({ label: c.clientName, value: c.clientId }))

         // console.log(clients);
         // this.setPage(res["result"]);
         this.setState({ client });
      }
   }

   getMonthname = (month) => {
      switch (month) {
         case 1:
            return "Jan";
         case 2:
            return "Feb";
         case 3:
            return "Mar";
         case 4:
            return "Apr";
         case 5:
            return "May";
         case 6:
            return "Jun";
         case 7:
            return "Jul";
         case 8:
            return "Aug";
         case 9:
            return "Sep";
         case 10:
            return "Oct";
         case 11:
            return "Nov";
         case 12:
            return "Dec";
         default:
            return month;
      }
   }

   getData = page => {
      const requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json",
            'Authorization': "JWT-" + localStorage.getItem("ros_token")
         }
      };
      const queryString = "page=" + (page.number - 1) + "&size=" + page.size;

      fetch(process.env.REACT_APP_ROS_BASE_URL + '/getallswan?' + queryString, requestOptions)
         .then(response => response.json())
         .then(res => this.refreshData(res))
         .catch(err => console.error(err));
   }

   refreshData = (res) => {

      if (res["success"]) {
         const data = res["result"].content;
         //console.log(data);
         this.setPage(res["result"]);
         this.setState({ data });

      }
      else {
         const data = [];
         this.setState({ data, errorMessage: "Failed to get data!", successMessage: "" });
         console.log("Error");
      }
   }

   loadpageTitle = () => {
      let htmlele = [];
      htmlele.push(
         <div key={"div"} className="col-12 mr-1 pl-0 ml-0 pr-1 pb-0 pr-0">
            <span className="col-6 pl-0 ml-0 mr-0 pr-0 float-right">  <button className="float-right h-30px col-lg-12 pl-0 ml-0 mr-0 pr-0 pt-0 pb-0 mt-3 mb-0 btn filter_btn"><VscFilter></VscFilter> </button> </span>
         </div>
      )
      return htmlele;
   }

   showHideUpload = () => {
      //  console.log(this.state.record)
      if (this.state.addModalShowUpload) {
         return <div ><BulkUploadComponent show={true} onHide={this.addModalCloseUpload} categories={this.state.categories} onBack={this.onDetailBack} baseRecord={this.state.record} type={this.state.type} mode={this.state.mode} headerTitle={this.state.headerTitle} /></div>
      }
   }
   addModalCloseUpload = () => {
      this.setState({ addModalShowUpload: false });
   }

   openModalwithUpload = (event) => {
      const headerTitle = "Upload File"
      event.preventDefault();
      this.setState({ addModalShowUpload: true });
      this.setState({ headerTitle })
      // console.log(this.state.addModalShowUpload);
   }

   addModalClosePDFView = () => {
      this.setState({ addModalShowPDF: false });
   }

   openModalwithUpload = (event) => {
      const headerTitle = "Upload File"
      event.preventDefault();
      this.setState({ addModalShowUpload: true });
      this.setState({ headerTitle })
      // console.log(this.state.addModalShowUpload);
   }

   addModalClosePDFView = () => {
      this.setState({ addModalShowPDF: false });
   }

   openModalwithPDF = (flag, event, val, pdflink, type) => {
      let apiname
      console.log(this.getSystemHyperlink());
      if (type === "systemId") {

         apiname = "/getpdffile?"
      }
      if (type === "pnrId") {
         apiname = "/getpdfTicketpnrfile?"
      }
      let filename = val;
      const requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json",
            'Authorization': "JWT-" + localStorage.getItem("ros_token")
         }
      };

      const queryString = "filename=" + filename
      fetch(process.env.REACT_APP_ROS_BASE_URL + apiname + queryString, requestOptions)
         .then(response => {
            // const filename =  response.headers.get('Content-Disposition').split('filename=')[1];
            response.blob().then(blob => {
               const pdfFile = new Blob([blob], { type: 'application/pdf' });
               const fileURL = URL.createObjectURL(pdfFile);
               window.open(fileURL, "_blank");
               //let url = window.URL.createObjectURL(blob);
               // window.open(url);
               //let a = document.createElement('a');
               //a.click();
               // a.download = val+".pdf";
               // console.log(a)
            });

         });
      let headerTitle = "";

      headerTitle = val + ".pdf";

      event.preventDefault();
      //this.setState({addModalShowPDF:true});
      // this.setState({headerTitle,pdflink})
      //sconsole.log(this.state.addModalShowPDF);
   }



   showHidePDF = () => {
      //console.log("cusotmize function called")
      if (this.state.addModalShowPDF) {
         return <div ><PDFViewComponent show={true} onHide={this.addModalClosePDFView} pdffilelink={this.state.pdflink} headerTitle={this.state.headerTitle} /></div>
      }
   }


   showHideDownload = () => {
      //  console.log(this.state.record)
      if (this.state.addModalShowDownload) {
         return <div ><CSVDownloadComponent show={true} onHide={this.addModalCloseDownload} data={this.state.data} dataTicket={this.state.dataTicket} dataAirline={this.state.dataAirline} baseRecord={this.state.record} type={this.state.type} mode={this.state.mode} fileList={this.state.fileList} headerTitle={this.state.headerTitle} /></div>
      }
   }
   addModalCloseDownload = () => {
      this.setState({ addModalShowDownload: false });
   }

   openModalwithDownload = (event) => {
      const headerTitle = "Download Report"
      event.preventDefault();
      this.setState({ addModalShowDownload: true });
      this.setState({ headerTitle })
      //console.log(this.state.addModalShowDownload);
   }

   handleSearchTextOnChange = (event) => {
      const searchText = event.target.value;
      this.setState({ searchText: searchText });
   }

   handleSearchClick() {
      let page = { ...this.state.page }
      page.number = 1;
      this.setState({ page })
      //this.getData(page);
   }
   handlechangeDate = (date) => {
      const startTime = date;
      console.log(startTime)
      this.setState({ startTime });
      // this.getDataByDate(startTime,this.state.stopTime,this.state.selectedClients);       
   }
   handlechangeEndDate = (date) => {
      const stopTime = date;
      console.log(stopTime)
      this.setState({ stopTime });
      // this.getDataByDate(this.state.startTime,stopTime,this.state.selectedClients);

   }

   getFormattedColumnDate = (val) => {
      let date
      if (val) {
         date = moment.tz(val, "Asia/Kolkata").format('DD-MM-YYYY');
      }
      else {
         date = "";
      }
      //var date = moment.tz(val,"US/Pacific").format("hh:mm:ss");

      return date;
   }

   getFormatStartDate = (val) => {
      let date
      if (val) {
         date = moment.tz(val, "Asia/Kolkata").format('DD-MMM-yyyy 00:00');
         //date= moment.tz(val,"Asia/Kolkata").format('yyyy-MM-DD');
      }
      else {
         date = "";
      }
      //var date = moment.tz(val,"US/Pacific").format("hh:mm:ss");

      return date;
   }
   getFormatEndDate = (val) => {
      let date
      if (val) {
         date = moment.tz(val, "Asia/Kolkata").format('DD-MMM-yyyy 23:59');
         //date= moment.tz(val,"Asia/Kolkata").format('yyyy-MM-DD');
      }
      else {
         date = "";
      }
      //var date = moment.tz(val,"US/Pacific").format("hh:mm:ss");

      return date;
   }
   formatDate = (val) => {
      let date
      if (val) {
         date = moment.tz(val, "Asia/Kolkata").format('DD-MMM-yyyy_hh:mm:ss');
         //date= moment.tz(val,"Asia/Kolkata").format('yyyy-MM-DD');
      }
      else {
         date = "";
      }
      //var date = moment.tz(val,"US/Pacific").format("hh:mm:ss");

      return date;
   }

   getDataByDate = (startTime, stopTime, clients) => {

      let startTimeN = this.getFormatStartDate(startTime);
      let stopTimeN = this.getFormatEndDate(stopTime);
      let clientsArr = [];

      /* for (let i=0;i<clients.length;i++){
          clientsArr.push(clients[i].clientName)
       }
       let clientsN = clientsArr.toString();*/
      console.log(startTimeN);
      console.log(stopTimeN);
      console.log(this.state.clientString)
      const requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json",
            'Authorization': "JWT-" + localStorage.getItem("ros_token")
         }
      };
      console.log(startTimeN)
      // const queryString = "page=" + (page.number - 1) + "&size=" + page.size + "&searchText=" + this.state.searchText +"&sortDirection="+this.state.sortDirection +"&sortFieldName="+this.state.sortFieldName;
      const queryString = "startTime=" + startTimeN + "&endTime=" + stopTimeN + "&clients=" + this.state.clientString
      fetch(process.env.REACT_APP_ROS_BASE_URL + '/getinvoiceswithdateclients?' + queryString, requestOptions)
         .then(response => response.json())
         .then(res => this.refreshByDateData(res))
         .catch(err => console.error(err));
   }

   refreshByDateData = (res) => {
      console.log(res);
      if (res["success"]) {
         const data = res["result"];
         console.log(data);
         this.setPage(data);
         this.setState({ data });
      }
      else {
         const data = res["result"];
         this.setState({ data });
         this.setPage(data);
      }
      this.getClientList();
   }

   setPage = (result) => {
      let page = { ...this.page }
      page.number = result["number"] + 1;
      page.first = result["first"];
      page.last = result["last"];
      page.numberOfElements = result["numberOfElements"];
      page.size = result["size"];
      page.totalElements = result["totalElements"];
      page.totalPages = result["totalPages"];
      this.setState({ page })
      if (page.number === 1) {
         adjustInitPageButton(this);
      }
   }

   handleMsg = (msg, type = "") => {
      if (type === "e") {
         this.setState({ errorMessage: msg })
      } else if (type === "s") {
         this.setState({ successMessage: msg })
      } else {
         this.setState({ errorMessage: "", successMessage: "" })
      }
   }
   downloadExcel = (data) => {
      let nameHash = {}

      const finaldata = data.map(ele => {
         const noOccurence = data.filter(obj => obj.ticketPNR === ele.ticketPNR).length;
         if (noOccurence > 1) {
            // if there are multiple occurences get the current index. If undefined take 1 as first copy index.
            let currentIndex = nameHash[ele.ticketPNR] || 1;
            //ele.k3Amt = ele.k3Amt / noOccurence;
            //ele.grossFare = ele.grossFare / noOccurence;
            //ele.nonTaxable = ele.nonTaxable / noOccurence;
            //ele.taxable = ele.taxable / noOccurence;
            // ele.grossFare1 = ele.grossFare1 / noOccurence;
            // ele.cgstAmount = ele.cgstAmount / noOccurence;
            // ele.sgstAmount = ele.sgstAmount / noOccurence;
            // ele.igstAmount = ele.igstAmount / noOccurence;
            // ele.total_GST = ele.total_GST / noOccurence;
            const newObj = ele
            // nameHash[ele.name] = currentIndex+ 1;
            return newObj;
         }
         return ele;
      })

      console.log(finaldata)
      let curdate = new Date();
      let curdatestring = this.formatDate(curdate);
      const worksheet = XLSX.utils.json_to_sheet(finaldata);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, "Recon-Export_" + curdatestring + ".xlsx");
   };

   /* downloadExcel = (data) => {
       let finaldata = [];
       for(let i=0;i<data.length();i++){
        const filtered = data.filter(itm => itm.ticketPNR === data[i].ticketPNR); 
        if(filtered.length>1){
          console.log(filtered)
        }
       }
       let curdate = new Date();
       let curdatestring = this.formatDate(curdate);
       const worksheet = XLSX.utils.json_to_sheet(data);
       const workbook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
       //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
       //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
       XLSX.writeFile(workbook, "Recon-Export_"+curdatestring+".xlsx");
     };*/

   onSelectClient = (selectedList, selectedItem) => {
      if (selectedItem.clientName === "Select-All") {
         selectedList = this.state.client;
         this.state.selectAllOption.clientName = "DeSelect-All"
         selectedList.push(this.state.selectAllOption)
      }

      this.setClient(selectedList);
   }


   onRemoveClient = (selectedList, removeItem) => {
      if (removeItem.clientName === "DeSelect-All") {
         this.state.selectAllOption.clientName = "Select-All"
         selectedList.push(this.state.selectAllOption);
         selectedList = [];

      }

      this.setClient(selectedList);
      this.getClientList();
   }

   onSearchClient = (searchclientText) => {
      let searchClientText = this.state.searchClientText;
      searchClientText = searchClientText
      let allclients = this.state.clients;
      let clients = allclients.filter(item => item == searchclientText)
      this.setState({ clients });
   }

   setClient = selectedList => {
      let selectedClients = []
      for (let i = 0; i < selectedList.length; i++) {
         selectedClients.push(selectedList[i])
         console.log(selectedClients);
      }
      this.setState({ selectedClients });
   }


}

export default TicketvsAirlineComponent;