import React, { Component } from 'react';
import PageTitle from '../page.title';
import queryString from 'query-string';
import { confirmAlert } from 'react-confirm-alert'; // Import



class ForgotPasswordComponent extends Component {
   state = {
    pageTitle : "Forgot Password",
        open: false,
        loginerrormsg:"",
        
        
       user:{username:"",password:"",token:""},
      confirmPassword:"",
      successMessage:"",
      errorMessage:""
      
      }
      componentDidMount(){
        const pathname = this.props.location.pathname
        if (pathname.startsWith("/portal/forgotpassword")) {
        //  const usern = this.props.match.params.username;
       //   console.log(usern);
          let url = this.props.location.search;
          let params = queryString.parse(url);
          //console.log(params["username"]);
          let user = { ...this.state.user }
          user.username=params["username"]
          user.token=params["token"]
         
          this.setState({user});
        }
       
       
    /* if(userName && token){
      let user = { ...this.state.user }
       user.username=userName["username"]
       user.token=token
       console.log(user.token);
       this.setState({user});
     }
    
      */
       }
       handleOnChangePassword=(event)=>{
        let user = { ...this.state.user }
        user.password=event.target.value;
        this.setState({user});
       
       }
       handleOnChangeconfirmPassword=(event)=>{
        const confirmPassword = event.target.value;
        this.setState({ confirmPassword:confirmPassword })
       
       }
      /* handleOnChangeoldPassword=(event)=>{
        let user = { ...this.state.user }
        user.oldpassword = event.target.value
        this.setState({ user });
        this.setState({ loginerrormsg: "" });
     //handle change events)
       }*/
  
       ResetPassword=(event)=>{
     
        event.preventDefault();
        if(this.state.user["password"]!=this.state.confirmPassword){
          const errorMessage = "Please enter confirm_password same as new_password !"
          this.setState({ errorMessage }); 
        
        }
        else{
        const requestOptions = {
          method: 'POST', 
          body : JSON.stringify(this.state.user),
          headers: {
             'Content-Type': 'application/json; charset=utf-8',
             'Accept': "application/json",
             'Authorization' : "JWT-" + localStorage.getItem("ros_token")
            
          }
       };
      
       fetch(process.env.REACT_APP_ROS_BASE_URL + '/token/resetforgotpwd', requestOptions)
       .then(res => res.json())
       .then(res => this.afterSave(res))
        .catch(err => console.error(err));
       
      }}
    afterSave=(res) => {
  
      if (res["success"]) { 
        sessionStorage.clear();
        return(
          confirmAlert({
             title:"Success",message: "Password reset Successfully",
             buttons: [{ label: "ok", className:"confirm_btn_popup", onClick : ()  => this.closeModal() }]
          })
          );
      
      
      }
      else if(res["message"]==="TOKEN_ALREADYUSED"){
        const errorMessage = "Link Already Used!"
        this.setState({ errorMessage }); 
      }
    }
    closeModal(){
         this.props.history.push("portal/login")
    }
   
    render() {
     
        return (

          <React.Fragment> 
           <PageTitle title={this.state.pageTitle} />   
          
         {this.state.errorMessage && <h5 className="alert-danger mt-0 ml-2 mr-4"> {this.state.errorMessage} <span className="float-right pointer-cursor" onClick={() => { this.setState({ errorMessage: "" }) }}>&#x2716;</span> </h5>}
            {this.state.successMessage && <h5 className="alert-success mt-0 ml-2 mr-4"> {this.state.successMessage} <span className="float-right pointer-cursor" onClick={() => { this.setState({ successMessage: "" }) }}>&#x2716;</span> </h5> }
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
   <form className="mt-2">

<div className="mt-0 row">

  <div className="col-md-6">
 
    <div className="form-group required form-inline  ml-2 mt-1 mb-0">
      <label name="newpassword" className="col-md-5 c-mw-px-200 control-label">New Password</label>
      <input  type="password" className="form-control col-md-7 mb-1" 
         value={this.state.password}
         onChange={(event) => this.handleOnChangePassword(event)}
         minLength="3" maxLength="50"
        pattern="(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])(?=.*[@#$%!]).{8,30}" data-toggle="tooltip"
       >
  </input>  </div>
    <div className="form-group required form-inline  ml-2 mt-1 mb-0">
      <label name="confirmPassword" className="col-md-5 c-mw-px-200 control-label">Confirm_Password</label>
      <input type="password" className="form-control col-md-7 mb-1" 
         data-toggle="tooltip"value={this.state.confirmPassword}
         onChange={(event) => this.handleOnChangeconfirmPassword(event)}
        >
    </input></div>
  </div>
  <div className="col-md-1"></div>
  <div className="col-md-4 alert-danger small">
    <p className="mt-4">
      Password length from 8 to 30 characters.
    </p>
    <p className="mt-0">
      Combination of alphabet, digit and special character(@#$%!)
    </p>
    <p className="mt-0">
      Atleast 1 capital letter, 1 small letter, 1 digit and 1 special character .
    </p>
    <p className="mt-0">
      Start with alphabet.
    </p>
  </div>
</div>
<div className="row mt-4">
  <div className="col-md-8 mt-0 mb-2 d-flex justify-content-center span2">
    <button className="btn btn-outline-primary shadow" onClick={(event)=> this.ResetPassword(event)}>Reset Password</button>
  </div>
</div>

</form>
                       
</React.Fragment>      
       
          );
     }

}
export default ForgotPasswordComponent;