import React from 'react'
import { Route,Redirect } from 'react-router-dom'
import decode from 'jwt-decode'

export const checkAuth = () => {
   const token = localStorage.getItem("ros_token")
   if (!token) {
      return false;
   }
   try {
      const { exp } = decode(token)
      if (exp < (Date.now() / 1000)) {
         return false;
      }
   } catch (err) {
      return false;
   }
   return true;
}
export const AuthRoute = ({ component: Component, ...rest }) => (
   <Route {...rest}  render={props => (
      checkAuth() ? ( <Component {...props} />) : <Redirect to={{pathname : "/portal/login"}} />
   )} />
)

