import React, { Component } from 'react';
import PageTitle from '../page.title';
import UserDetailComponent from './user.details.component';
import { PaginationComponent,adjustInitPageButton } from '../GridTable/pagination.component';
import GridTableComponent from '../GridTable/grid.table.component';
import { confirmAlert } from 'react-confirm-alert'; // Import

class UserComponent extends Component {
  
   state = { 
      pageTitle: "Users",
      errorMessage: "",
      successMessage: "",
      data: [],
    
      page : {offset :  1, /*Start record from*/
         totalPages : 1, /*number of pages*/
         totalElements : 0, /*number of records*/
         last : true, /*is last page*/
         first: true, /*is first page*/
         size: 20, /*page size*/
         number : 1, /* current page*/
         numberOfElements: 0 /*Total number of record*/
      },
      pagebuttons: [],
      disableTable: false,
      detail: false,
      searchText: "",
      roles : [],
      mode : "none"
   }
   
   componentDidMount() {
      this.getData(this.state.page);
      
   }
   
   render() { 
      return (  
         <React.Fragment>            
            <PageTitle title={this.state.pageTitle} classes="mt-1" />
            {this.state.errorMessage && <h5 className="alert-danger mt-0 ml-2 mr-4"> {this.state.errorMessage} <span className="float-right pointer-cursor" onClick={() => { this.setState({ errorMessage: "" }) }}>&#x2716;</span> </h5>}
            {this.state.successMessage && <h5 className="alert-success mt-0 ml-2 mr-4"> {this.state.successMessage} <span className="float-right pointer-cursor" onClick={() => { this.setState({ successMessage: "" }) }}>&#x2716;</span> </h5> }
            {this.loadPageElement()}
         </React.Fragment>
      );
   }

   loadPageElement = () => {
     // console.log(this.state.roles);
      if (this.state.detail) {
         return (<UserDetailComponent  onBack={this.onDetailBack} baseRecord={this.state.record} mode={this.state.mode}  handleMsg={this.handleMsg}/>)
      } else {
         return (
         <React.Fragment>
         <div className="row card pt-1 pb-2 ml-2 mr-2 pr-2">
            <div className="form form-inline col-lg-12 col-md-12 col-sm-12 col-12" >
               <label className="control-label col-lg-2 col-md-2 col-sm-2 col-2 small" >Search Text</label>
               <input type="text" className="col-lg-6 form-control col-md-7 col-sm-7 col-7 small pl-1 pr-1" value={this.state.searchText} onChange={(event) => this.handleSearchTextOnChange(event)} />
               <button className="btn btn-sm confirm_btn ml-2 mr-2" disabled={this.state.disableTable} onClick={() => this.handleSearchClick()}>Refresh</button>
               <div style={{ flex: 1 }}></div>
               <button className="btn btn-sm confirm_btn ml-2 mr-2" disabled={this.state.disableTable} onClick={() => this.addNew()}>Add New User</button>
               </div>
         </div>
         <PaginationComponent page={this.state.page} pagebuttons={this.state.pagebuttons} loadData={this.getData} disable={this.state.disableTable} _this={this} />
         <GridTableComponent cols={this.getCols()} data={this.state.data} onRowDblClick={this.onSelectRecord} disable={this.state.disableTable} _this={this} />
         </React.Fragment>);

      }
   }

   getCols = () => {
      return [{ header: "Name", key: "name", headerclasses: "w-20 mw-20", rowclasses : "w-20"   },
         { header: "User Name", key: "username", headerclasses: "w-20 mw-20",rowclasses : "w-20" },
         { header: "Email", key: "userEmail", headerclasses: "w-25 mw-25", rowclasses: "w-25 mw-25" },
         { header: "Role", key: "roleName", headerclasses: "w-25 mw-25", rowclasses: "w-25 mw-25" },
         { header :"Delete",key:"userId", headerclasses: "10",fnValue:this.getLabelDelete }
      ] 

   }
   getLabelDelete=(userId)=>{
    
      return(
           <button className="btn btn-small btn-primary-outline ml-1 pl-0 pr-0 h-20px pt-0 pb-0 mt-0 float-right mr-1"
           onClick={(event)=>this.handleUser(event,userId)}>
           <img alt="Remove" src={process.env.PUBLIC_URL + "/assets/images//deletecross_16.png"}>
           </img>
        </button>  
           )
      }
      handleUser(event,userId){
         console.log("UserId==="+userId)
         confirmAlert({
            title: "Confirm", message: "Are you sure to delete Item?",
            buttons: [{ label: "Yes", className:"confirm_btn", onClick : ()  => this.handleDeleteUser(userId) }, {
               label : "No",className:"confirm_btn","onClick" : () => {return false}
            }]
         })
      }
      handleDeleteUser=(userId)=> {
         const requestOptions = {
            method: 'DELETE',
            headers: {
               'Content-Type': 'application/json; charset=utf-8',
               'Accept': "application/json",
               'Authorization' : "JWT-" + localStorage.getItem("ros_token")
            }
         };
   
     
    const querystring="userId="+userId
         fetch(process.env.REACT_APP_ROS_BASE_URL + '/user?' + querystring, requestOptions)
            .then(response => response.json())
            .then(res => this.refreshData1(res))
            .catch(err => console.error(err));

      }
      refreshData1(res){
         if(res["success"]){
            this.setState({ successMessage : "User Deleted Successfully!!" });
            this.getData(this.state.page);

         }else {
          
            this.setState({ errorMessage : "Failed to get user list!",successMessage :"" });
            console.log("Error");
         }

      }
   concateRoles = (value) => {
      let result = undefined
      if (value) {
         result = value.join(",");
         result = result.length > 100 ? result.substring(1, 97) + "..." : result;
      }
      return result
   }

      getData=page => {
      const requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json",
            'Authorization' : "JWT-" + localStorage.getItem("ros_token")
         }
      };

      const queryString = "page=" + (page.number - 1) + "&size=" + page.size + "&searchText=" + this.state.searchText ;

      fetch(process.env.REACT_APP_ROS_BASE_URL + '/users?' + queryString, requestOptions)
         .then(response => response.json())
         .then(res => this.refreshData(res))
         .catch(err => console.error(err));
   }

   refreshData = (res) => {
      if (res["success"]) {
         const data = res["result"].content;
         this.setPage(res["result"]);
         this.setState({ data });
         if (data && data.length > 0) {
            const record = { ...data[0] }
            this.setState({record})
          
         }
      } else {
         const data = [];
         this.setState({ data,errorMessage : "Failed to get user list!",successMessage :"" });
         console.log("Error");
      }
   }

   setPage = (result) => {
      let page = { ...this.page }
      page.number = result["number"] + 1;
      page.first = result["first"];
      page.last = result["last"];
      page.numberOfElements = result["numberOfElements"];
      page.size = result["size"];
      page.totalElements = result["totalElements"];
      page.totalPages = result["totalPages"];
      this.setState({ page })
      if (page.number === 1) {
         adjustInitPageButton(this);
      }      
   }

  addNew = () => {
      const pageTitle = "Add New User"
      this.setState({ pageTitle, errorMessage: "", successMessage: "",mode : "add" })
      this.setState({detail : true})
   }
   onSelectRecord = record => {
      const pageTitle = "User Detail"
      this.setState({ pageTitle, errorMessage: "", successMessage: "" ,mode : "update"})
      this.setState({record : {...record} })
      this.setState({detail : true})
   }
   
   onDetailBack = (isModified) => {
      this.setState({ detail: false });
      if (isModified) {
         this.getData(this.state.page)
      }
   }

   handleSearchTextOnChange = (event) => {
      const searchText = event.target.value;
      this.setState({ searchText: searchText });
   }
   handleSearchClick() {
      let page = { ...this.state.page }
      page.number = 1;
      this.setState({page})
      this.getData(page);
   }

   handleMsg = ( msg,type="") => {
      if (type === "e") {
         this.setState({ errorMessage: msg })
      } else if (type === "s") {
         this.setState({ successMessage: msg })
      } else {
         this.setState({errorMessage : "",successMessage : ""})
      }
   }

   
}
 
export default UserComponent;