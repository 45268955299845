import React, { Component } from 'react';
import './recon.css';
/*import {BrowserRouter as Router,Redirect } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'; // Import
import PageTitle from '../page.title';
import {tofix_2} from '../../common/utility.jsx'
import { PaginationComponent, adjustInitPageButton } from '../GridTable/pagination.component';
import GridTableComponent from '../GridTable/grid.table.component';*/
import AgentvsAirlineComponent  from './agentvsairline.component';
import AgentvsGSTRComponent  from './agentvsGSTR.component';
import AirlinevsGSTRComponent  from './airlinesvsGSTR.component';
import TicketvsAirlineComponent  from './ticketsvsairline.component';
import MailProcessedComponent  from './mailprocessed.component';

import TicketvsAgentComponent  from './ticketvsagent.component';
import QueueViewComponent  from './queueview.component';
import DetailsComponent  from './details.component';


class ReconciliationComponent extends Component {
   state = { 
   }
   currencies = {
    "USD": "$",
    "INR": "₹"
 }
 
 currency = "INR";

 getCurrent() {
    return this.currencies[this.currency]
 }
   
   componentDidMount() {
    

   }
   render() { 
      return ( 
         <React.Fragment>            
           
         {this.loadPageElement()}
         </React.Fragment>
       );
   }
   
   loadPageElement = () => {
      // console.log(this.props)
         return (
            
         <React.Fragment>
            {this.openPage()}
         </React.Fragment>);

      }
  
      openPage =() => {
        let htmlele=""
           switch (this.props.value) {
              case "ticketvsairline":
                 htmlele=<TicketvsAirlineComponent />
                 break;
                 case "mailprocessed":
                 htmlele=<MailProcessedComponent />
                 break;
                 
              case "airlinevsgstr":
                  htmlele=<AirlinevsGSTRComponent/>
                  break;
              case "ticketsvsagent":
                  htmlele=<TicketvsAgentComponent/>
                   break;
              case "agentvsairline":
                    htmlele=<AgentvsAirlineComponent/>
                     break;
              case "agentvsgstr":
                     htmlele=<AgentvsGSTRComponent />
                     break;
              case "tracking":
                        htmlele=<QueueViewComponent />
                        break;
               case "details":
                        htmlele=<DetailsComponent />
                           break;
              
              default: 
                    htmlele=<TicketvsAirlineComponent />
                          
           }
       
        return htmlele
     }
 
}
 
export default ReconciliationComponent;