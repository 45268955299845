
import React, { Component } from "react";
import {Modal, Button,Row,Col} from 'react-bootstrap';
import { BsX } from "react-icons/bs";
import { BsChevronDown,BsChevronRight } from "react-icons/bs";
import { confirmAlert } from 'react-confirm-alert';
import {tofix_2} from '../../common/utility.jsx'
import { withRouter } from 'react-router'
import PageTitle from '../page.title';
import { PaginationComponent, adjustInitPageButton } from '../GridTable/pagination.component';
import GridTableComponent from '../GridTable/grid.table.component';
import DataSourceChangeComponent from './datasource.change.component';
//import MenuItemComponent from './menu.item.component';


class DataSourceComponent extends Component {
    blankSource = {
      sftp: undefined,email: undefined,url: undefined,username: undefined,password: undefined,state:"A"}
    state = {
        addModalShow :false ,
        templateFields:[],
        mode:"",
        field : "-",
        data: [],
        name:"",
        source : "-",
        sourceID:"",
        record : {},
        pageTitle : "Data Source",
        headerTitle: "",
        action:"",
    }
    componentDidMount=()=>{
      this.getAllDataSource();
    }
    render() { 
        return(
            <React.Fragment>   
                <div className="row pl-1 ml-1 pr-1 mr-1 justify-content-center">  
               <div className="row border bg_color col-lg-12 pt-1 pb-2 ml-0 pl-0 mr-0 pr-0 mt-2 mb-1 mr-0 justify-content-center">
                <h2 className="col-lg-12 mt-0 pl-0 ml-2 pt-0 text-center" > Data Source </h2>
                <span className="col-lg-12 mt-0 pt-0 ml-0 pl-1 text-primary text-center"><h3 className="text-primary text-center"> Add new Data Source </h3>  </span>    
                </div>
               <div className="row card bg_color col-lg-12 pt-1 pb-2 ml-0 pl-0 mr-0 pr-0 mt-2 mb-1 mr-0 justify-content-center">
               <span className="col-lg-8 mt-0 pt-0 ml-0 pl-1 text-center"> Add  and View new document data source for processing </span>
                <button onClick={(event) => this.openSourceModalwithUpload(event)} className="col-lg-4 pl-0 ml-0 mr-0 pr-0 pt-0 pb-0 mt-0 mb-0 btn pt-1 pb-1 confirm_btn">Add New Source</button>    
                </div>
                <div className="row col-lg-12 card pt-0 mt-0 pb-2">
                <div className="row col-lg-12 pt-1 pb-0 ml-1 pl-1 mt-2 mb-0 mr-0 justify-content-center">
               
                <span className="control-label text-bold col-lg-12 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0"> 
                <GridTableComponent cols={this.getCols()} data={this.state.data}  onRowClick={this.onSelectRecord} disable={this.state.disableTable} _this={this} />
               </span>
                   {this.showHideDataSource()}
               
                </div>
                </div> 
                </div>
               
         </React.Fragment> 
        )
    }
    getCols = () => {
      return [{ header: "Name", key: "name", rowclasses : "text-center", headerclasses: "w-20 text-center", },
         { header: "Type", key: "sourceType",rowclasses : "text-center", headerclasses: "w-20 text-center"},
         { header:"Value", key: "value", headerclasses: "w-20",rowclasses : "mr-0",fnValue:this.getAirlinePNRHyperlink },
         { header: "UserName" , key: "userName", headerclasses : "w-20",rowclasses : "pr-2 ",fnValue:this.getFormattedDate},
         { header: "Document Type", key: "documentType",rowclasses : "w-10 text-center", headerclasses: "w-20 text-center"},
         { header :"Action",key:"_id",rowclasses : "text-center", headerclasses: "w-20 text-center",fnValue:this.getActionLabel }];
   }
   getActionLabel=(sourceId)=>{
    return(
       <div classNme="row d-flex justify-content-center"> 

       <span className="col-lg-3 btn btn-small btn-primary-outline ml-0 pl-0 pr-0 pt-0 pb-0 mt-0 mr-0"
          onClick={(event)=>this.openModalwithDetails(event,sourceId)}>
          <img alt="Remove" src={process.env.PUBLIC_URL + "/assets/images/edit-row.png"}>
          </img>
       </span> 

       <span className="col-lg-3 btn btn-small btn-primary-outline ml-0 pl-0 pr-0 pt-0 pb-0 mt-0  mr-0"
         onClick={(event)=>this.handleDeleteSource(event,sourceId)}>
         <img alt="Remove" src={process.env.PUBLIC_URL + "/assets/images/big-trash.png"}>
         </img>
         </span>  
       </div>
         )
    }

    handleDeleteSource(event,sourceId){
      //console.log("item id == " + itemId);
      confirmAlert({
          title: "Confirm", message: "Are you sure to delete Source?",
          buttons: [{ label: "Yes", className:"confirm_btn_popup", onClick : ()  => this.handleSource(sourceId) }, {
             label : "No",className:"confirm_btn_popup","onClick" : () => {return false}
          }]
       })
      }

      handleSource=(sourceId)=>{     
         //let idx = this.state.itemId.findIndex(s => s.id === record.itemId);
            //   console.log(idx);
           /* const data = [...this.state.data];
            if(data) {
               const idx = data.findIndex(obj => obj.id===sourceId);
               if(idx > -1) {
                  let record = data[idx]; 
                  this.setState({record})
                  console.log(record);
                  */
                  const requestOptions = {
                     method: 'GET',
                    // body : JSON.stringify(record),
                     headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Accept': "application/json",
                        'Authorization' : "JWT-" + localStorage.getItem("ros_token")
                     }
                  }
                  const queryString = "id=" + sourceId
                  fetch(process.env.REACT_APP_ROS_BASE_URL + '/deletesource?'+queryString, requestOptions)
                  .then(response => response.json())
                  .then(res => this.handleDeleteSourcerefresh(res))
                  .catch(err => console.error(err));
            }

            
            handleDeleteSourcerefresh=(res) => {

         if (res["success"]) {
            
            this.getAllDataSource();
   
         } else {
           return false;
            }
            this.handleMsg("", "e");
      }
    openModalwithDetails=(event,sourceID)=>{
      const headerTitle = "Edit Data Source"
      const action = "Update"
      event.preventDefault();
      this.setState({addModalShow:true});
      this.setState({ mode : "update"})
      this.setState({headerTitle,sourceID,action})
     // console.log(this.state.addModalShow);
      }

   getAllDataSource=()=> {
    const requestOptions = {
       method: 'GET',
       headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Accept': "application/json",
          'Authorization' : "JWT-" + localStorage.getItem("ros_token")
       }
    };
 
   // const queryString = "page=" + (page.number - 1) + "&size=" + page.size + "&searchText=" + this.state.searchText +"&sortDirection="+this.state.sortDirection +"&sortFieldName="+this.state.sortFieldName;
 
    fetch(process.env.REACT_APP_ROS_BASE_URL + '/getalldatasource', requestOptions)
       .then(response => response.json())
       .then(res => this.refreshAllDataSource(res))
       .catch(err => console.error(err));
 }
 
 refreshAllDataSource = (res) => {
    //console.log(res);
    if (res["success"]) {
       
       const data = res["results"];
     //  console.log(data);
      // this.setPage(res["result"]);
      this.setState({ data });
    }
 }
 
 openSourceModalwithUpload=(event)=>{
  const headerTitle = "Add Data Source"
  const action = "Add"
  event.preventDefault();
  this.setState({addModalShow:true});
  this.setState({headerTitle,action})
 // console.log(this.state.addModalShowUpload);
  }
    showHideDataSource=() => {
      //  console.log(this.state.record)
        if(this.state.addModalShow) {
         return <div ><DataSourceChangeComponent show={true} onHide={this.addSourceModalCloseUpload} sourceID={this.state.sourceID}  refreshData={this.getAllDataSource} mode={this.state.mode} headerTitle={this.state.headerTitle} action={this.state.action}/></div>
        }
     }
     addSourceModalCloseUpload =() => {
        let sourceID ="";
      this.setState({addModalShow:false,sourceID});

      }
      handleOnChangeName = (event) => {
    let name = { ...this.state.name }
    name=event.target.value;
    this.setState({ name }) ;
}
handleonChangeSource = (event) => {
    let source = { ...this.state.source }
    source=event.target.value;
    this.setState({ source }) 
    let record = this.state.record;
    if (record.url!="" ||record.username!="" ||record.password!="" ){
        record = this.blankSource;
        this.setState({ record })
    }

}
  handleOnChangeURL = (event) => {
    let record = { ...this.state.record }
    record.url = event.target.value;
    this.setState({ record });
 }
 handleOnChangeEmail = (event) => {
  let record = { ...this.state.record }
  record.email = event.target.value;
  this.setState({ record });
}
handleOnChangeSFTP = (event) => {
  let record = { ...this.state.record }
  record.sftp = event.target.value;
  this.setState({ record });
}
 handleOnChangePassword = (event) => {
    let record = { ...this.state.record }
    record.password = event.target.value;
    this.setState({ record });
 }
 handleOnChangeUserName = (event) => {
    let record = { ...this.state.record }
    record.username = event.target.value;
    this.setState({ record });
 }

}

export default withRouter(DataSourceComponent);