import React, { Component } from 'react';
//import { confirmAlert } from 'react-confirm-alert'; // Import
//import PageTitle from '../page.title';
import './recon.css';
import moment from 'moment';
import { withRouter } from 'react-router'
//import {tofix_2} from '../../common/utility.jsx'
//import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PaginationComponent, adjustInitPageButton } from '../GridTable/pagination.component';
import GridTableComponent from '../GridTable/grid.table.component';

class DetailsComponent extends Component {

   state = {
      
      filerecord: {},
      recordData: [],
      data: [],
      pageTitle: "",
      successMessage: "",
      source: "",
      pdfcsvfile: "",
      filename: "",
      searchpnr: "",
      status:{
         total:0,
         processed:0,
         pending:0,
         failed:0
      },
      fileType: "DSR",
      customization: false,
      selection: false,
      errorMessage: "",
      page: {
         offset: 1, /*Start record from*/
         totalPages: 1, /*number of pages*/
         totalElements: 0, /*number of records*/
         last: true, /*is last page*/
         first: true, /*is first page*/
         size: 20, /*page size*/
         number: 1, /* current page*/
         numberOfElements: 0 /*Total number of record*/
      },
      pagebuttons: []

   }

   componentDidMount() {
      console.log(this.props);
      this.getFileProcessData();
      if (this.props.location.fileselected) {
         let filename = this.props.location.filename;
         console.log(filename);
         this.setState({ filename });
         this.getPNRStatusByFiles();
         this.getPNRDataByFiles();
        

      } else {
         
         this.getFileProcessData();
      }
      console.log(this.state.filename);
   }

   render() {
      // console.log(this.props);
      return (
         <React.Fragment>
            <div className="row pl-1 ml-1 pr-1 mr-1 d-flex justify-content-center">
               <div className="row border bg_color col-lg-12 pt-1 pb-2 ml-0 pl-0 mr-0 pr-0 mt-2 mb-1 mr-0 justify-content-center">
                  <h2 className="col-lg-12 mt-0 pl-0 ml-2 pt-0 text-center" > Details </h2>
                  <span className="col-lg-12 mt-0 pt-0 ml-0 pl-1 text-primary text-center"><h3 className="text-primary text-center"> View file processing Data </h3>  </span>
               </div>
               <div className="row col-lg-12 pt-0 mt-0 pb-2">
                  <div className="col-lg-12 pt-0 pb-0 ml-0 pl-0 mt-0 mb-0 mr-0 pr-0">
                     <div className="row col-lg-12 pt-0 pb-0 ml-0 pl-0 mt-0 mb-0 mr-0 pr-0 d-flex justify-content-center">
                        <span className="control-label text-bold col-lg-3 col-md-3 col-sm-3 pt-1 mt-1 col-12 ml-0 mr-0 pl-0 pr-0">
                           <label className="control-label text-bold col-lg-12 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0">Data Source </label>
                           <select className="h-30px form-control col-lg-11 col-md-12 col-sm-12 col-12 small ml-0 mr-0 mb-2 pb-0 pt-0 required" required value={this.state.filename} onChange={(event) => this.handleonChangeDatSource(event)}>
                              <option>-</option>
                              {this.state.recordData.map((recorddata) => <option key={recorddata.id} value={recorddata.fileName}>{recorddata.fileName}</option>)}
                           </select>
                        </span>
                        <span className="control-label col-lg-7 col-md-3 col-sm-3 pt-1 mt-1 col-12 ml-0 mr-0 pl-0 pr-0">
                        <label className="ml-0 text-bold mr-0 pr-0 pl-0 mt-0 col-lg-12 col-md-12 col-sm-12 col-12">Status</label>

                        <label className="ml-0 mr-0 font_14 pr-0 pl-0 mt-0 col-lg-1 col-md-2 col-sm-2 col-2">Total:</label>
                        <label className="ml-0 mr-0 font_14 pr-0 pl-0 mt-0 col-lg-1 col-md-2 col-sm-2 col-2">{this.state.status.total}</label>
                         

                         <label className="ml-0 mr-0 font_14 pr-0 pl-0 mt-0 col-lg-2 col-md-2 col-sm-2 col-2">Processed:</label>
                         <label className="ml-0 mr-0 font_14 pr-0 pl-0 mt-0 col-lg-2 col-md-2 col-sm-2 col-2">{this.state.status.processed}</label>
                       
                        <label className="ml-0 mr-0 font_14 pr-0 pl-0 mt-0 col-lg-2 col-md-2 col-sm-2 col-2">Pending:</label>
                        <label className="ml-0 mr-0 font_14 pr-0 pl-0 mt-0 col-lg-2 col-md-2 col-sm-2 col-2">{this.state.status.pending}</label>

                        <label className="ml-0 mr-0 font_14 pr-0 pl-0 mt-0 col-lg-1 col-md-2 col-sm-2 col-2">Failed:</label>
                        <label className="ml-0 mr-0 font_14 pr-0 pl-0 mt-0 col-lg-1 col-md-2 col-sm-2 col-2">{this.state.status.failed}</label>
                         
                        
                        </span>
                        <span className="control-label col-lg-2 col-md-3 col-sm-3 pt-1 mt-1 col-12 ml-0 mr-0 pl-0 pr-0">

                           <label className="ml-0 text-bold mr-0 pr-0 pl-0 mt-0 col-lg-12 col-md-12 col-sm-12 col-12">Search</label>
                           <input type="textbox" className="h-30px form-control ml-0 mr-0 pr-0 pl-1 mt-0 col-lg-11 col-md-12 col-sm-12 col-12 " value={this.state.searchpnr} onChange={(event) => this.handleSearch(event)}></input>

                        </span>

                     </div>
                  </div>
               </div>
            </div>
            <PaginationComponent page={this.state.page} pagebuttons={this.state.pagebuttons} loadData={this.getPNRDataByFiles} disable={this.state.disableTable} _this={this} />
            <span className="control-label text-bold col-lg-12 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0">
               <GridTableComponent cols={this.getCols()} data={this.state.data} onRowClick={this.onSelectRecord} disable={this.state.disableTable} _this={this} />
            </span>

         </React.Fragment>

      )

   }

   handleSearch = (event) => {
      let searchpnr = this.state.searchpnr
      searchpnr = event.target.value;
      console.log(searchpnr);
      this.setState({ searchpnr });
      if (searchpnr.length > 0)
         this.getPNRDataByPNR(searchpnr);

      else {
         this.getAllPNRData();
      }
   }

   getCols = () => {
      return [{ header: "PNR", key: "pnr", rowclasses: "text-center", headerclasses: "w-20 text-center", },
      { header: "Airline", key: "airlineName", rowclasses: "text-center", headerclasses: "w-20 text-center" },
      { header: "Name", key: "paxName", headerclasses: "w-20", rowclasses: "mr-0", fnValue: this.getAirlinePNRHyperlink },
      { header: "Last Updated", key: "genTS", headerclasses: "w-20", rowclasses: "pr-2 " },
      { header: "Status", key: "status", headerclasses: "w-30", rowclasses: "pr-2", fnValue: this.getStatus },
      { header: "Message", key: "msg", rowclasses: "w-10 text-center", headerclasses: "w-20 text-center" }];
   }


   handleonChangeDatSource = (event) => {
      let filename = this.state.filename
      filename = event.target.value;
      this.state.filename = event.target.value;
      //console.log(filename);
      this.setState({ filename });
      if (filename === "-") {
         this.getAllPNRData();
      }
      else {
         this.getPNRStatusByFiles();
         this.getPNRDataByFiles();
      }
   }

   handlechangeDate = date => {
      const startTime = this.formatDateUs(date)
      this.setState({ startTime: startTime })
      console.log(startTime)

   }

   handlechangeEndDate = date => {
      const stopTime = this.formatDateUs(date)
      this.setState({ stopTime: stopTime })
      console.log(stopTime)

   }

   formatDateUs = (dateValue) => {
      let val = Date.parse(dateValue);
      if (val) {
         const datev = new Date(dateValue);
         //const strDate =  this.getMonthname(datev.getMonth()+1)+"-"+datev.getDate()  + "-" + datev.getFullYear()
         // return strDate;
         return datev;
      } else {
         return dateValue;
      }
   }
   getMonthname = (month) => {
      switch (month) {
         case 1:
            return "Jan";
         case 2:
            return "Feb";
         case 3:
            return "Mar";
         case 4:
            return "Apr";
         case 5:
            return "May";
         case 6:
            return "Jun";
         case 7:
            return "Jul";
         case 8:
            return "Aug";
         case 9:
            return "Sep";
         case 10:
            return "Oct";
         case 11:
            return "Nov";
         case 12:
            return "Dec";
         default:
            return month;
      }
   }

   getAllPNRData = () => {
      const requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json",
            'Authorization': "JWT-" + localStorage.getItem("ros_token")
         }
      };

      // const queryString = "page=" + (page.number - 1) + "&size=" + page.size + "&searchText=" + this.state.searchText +"&sortDirection="+this.state.sortDirection +"&sortFieldName="+this.state.sortFieldName;

      fetch(process.env.REACT_APP_ROS_BASE_URL + '/getallpnr', requestOptions)
         .then(response => response.json())
         .then(res => this.refreshAllPNRData(res))
         .catch(err => console.error(err));
   }

   refreshAllPNRData = (res) => {
      //console.log(res);
      if (res["success"]) {

         const data = res["result"];
         //  console.log(data);
         // this.setPage(res["result"]);
         this.setState({ data });
      }
   }


   getPNRDataByPNR = (searchpnr) => {
      const requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json",
            'Authorization': "JWT-" + localStorage.getItem("ros_token")
         }
      };
      // const queryString = "page=" + (page.number - 1) + "&size=" + page.size + "&searchText=" + this.state.searchText +"&sortDirection="+this.state.sortDirection +"&sortFieldName="+this.state.sortFieldName;
      const queryString = "pnr=" + searchpnr;

      fetch(process.env.REACT_APP_ROS_BASE_URL + '/getpnrdetailsbypnr?' + queryString, requestOptions)
         .then(response => response.json())
         .then(res => this.refreshPNRDataByPNR(res))
         .catch(err => console.error(err));
   }

   refreshPNRDataByPNR = (res) => {
      // console.log(res);
      if (res["success"]) {

         const data = res["result"];
         console.log(data);
         // this.setPage(res["result"]);
         this.setState({ data });
      }
   }

   getPNRDataByFiles = () => {
      console.log(this.state.page)
      // let page = this.state.page
      const requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json",
            'Authorization': "JWT-" + localStorage.getItem("ros_token")
         }
      };
      // const queryString = "page=" + (page.number - 1) + "&size=" + page.size + "&searchText=" + this.state.searchText +"&sortDirection="+this.state.sortDirection +"&sortFieldName="+this.state.sortFieldName;
         const queryString = "page=" + this.state.page?.number + "&size=" + this.state.page.size + "&filename=" + this.state.filename;
         fetch(process.env.REACT_APP_ROS_BASE_URL + '/getpnrdetailsbyfile?' + queryString, requestOptions)
         .then(response => response.json())
         .then(res => this.refreshPNRDataByFiles(res))
         .catch(err => console.error(err));
   }

   refreshPNRDataByFiles = (res) => {
      console.log(res);

      if (res["success"]) {

         const data = res["result"].content;
         console.log(data);
         this.setPage(res["result"]);
         this.setState({ data });
      }
      else {
         const data = [];
         this.setState({ data, errorMessage: "Failed to get data!", successMessage: "" });
         console.log("Error");
      }
   }

   getPNRStatusByFiles = () => {
      console.log(this.state.page)
      // let page = this.state.page
      const requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json",
            'Authorization': "JWT-" + localStorage.getItem("ros_token")
         }
      };
      // const queryString = "page=" + (page.number - 1) + "&size=" + page.size + "&searchText=" + this.state.searchText +"&sortDirection="+this.state.sortDirection +"&sortFieldName="+this.state.sortFieldName;
         const queryString = "filename=" + this.state.filename;
         fetch(process.env.REACT_APP_ROS_BASE_URL + '/getpnrstatusbyfile?' + queryString, requestOptions)
         .then(response => response.json())
         .then(res => this.refreshPNRStatusByFiles(res))
         .catch(err => console.error(err));
   }

   refreshPNRStatusByFiles = (res) => {
      console.log(res);

      if (res["success"]) {

         const data = res["result"];
         console.log(data);
         this.statusCount(data);
      }
      else {
         const data = [];
         this.setState({ data, errorMessage: "Failed to get data!", successMessage: "" });
         console.log("Error");
      }
   }
   statusCount = (result)=>{
      console.log(result)
      let status = {...this.state.status}
      status.total = result.total;
      status.processed = result.processed;
      status.failed = result.failed;
      status.pending = result.pending;

      this.setState({status})
   }


   getFileProcessData = () => {
      //console.log(tableCode);
      //POST request using fetch inside useEffect React hook
      const requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json",
            'Authorization': "JWT-" + localStorage.getItem("ros_token")
         }
      };
      //const queryString = "rid=" + rid + "&tablenumber=" + tableCode;
      fetch(process.env.REACT_APP_ROS_BASE_URL + '/getfilesstatus', requestOptions)
         .then(response => response.json())
         .then(res => this.refreshProcessFiles(res))

         .catch(err => alert(err));

      // empty dependency array means this effect will only run once (like componentDidMount in classes)

   }
   refreshProcessFiles = (res) => {
      //console.log(res)
      var record = res;
      //onsole.log(record)
      if (record.success) {
         let recordData = record.results;
         this.setState({ recordData });
        
         //this.getConfigData();

      }
   }

  


   getStatus = (status) => {
      if (status === "P") {
         return "Pending";
      } else if (status === "F") {
         return "Failed";
      } else if (status === "A") {
         return "Success";
      } else if (status === "NP") {
         return "Not Processed";
      } else {
         return status;
      }
   }

   getFormattedDate = (val) => {
      let datevalue = new Date(val);
      //console.log(datevalue);
      //console.log(moment(datevalue).format('DD-MM-YYYY '+'hh:MM:ss'))
      let date = "";
      if (val) {
         date = moment.tz(datevalue, "Asia/Kolkata").format('DD-MM-YYYY ' + 'hh:MM:ss');
      }
      else {
         date = "";
      }
      return date;
   }

   setPage = (result) => {
      let page = { ...this.page }
      page.number = result["number"] + 1;
      page.first = result["first"];
      page.last = result["last"];
      page.numberOfElements = result["numberOfElements"];
      page.size = result["size"];
      page.totalElements = result["totalElements"];
      page.totalPages = result["totalPages"];
      this.setState({ page })
      if (page.number === 1) {
         adjustInitPageButton(this);
      }
   }



}
export default withRouter(DetailsComponent);