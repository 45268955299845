import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch,Route,Redirect } from "react-router-dom"
import 'typeface-roboto';
import PortalComponent from "./components/portal.component"


class App extends Component {
 
  render() {
    return (
      <Router>
        <Switch>
          <Redirect from="/" exact to="/portal"/>
          
          <Route path="/portal" component={PortalComponent}></Route>
        </Switch>
      </Router>
    );
  }

}

export default App;