import React, { Component } from 'react';
//import { currency } from '../../common/util';
//import { MdCloudUpload } from "react-icons/md";
import { Modal } from 'react-bootstrap';
//import PageTitle from '../page.title'
//import ReactTooltip from "react-tooltip";
import "./recon.css";
//import Toggle from 'react-toggle'
//import {tofix_2} from '../../common/utility.jsx'
//import { confirmAlert } from 'react-confirm-alert'; // Import
//import { Multiselect } from 'multiselect-react-dropdown';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Checkbox from '@material-ui/core/Checkbox';

class BulkUploadComponent extends Component {
   blankItem = {
      itemId: undefined, itemName: undefined, mrpPrice: undefined, itemImage: null, itemImageName: undefined, price: undefined, max: undefined, type: undefined,
      description: undefined, itemMetadata: undefined, category: { itemCategoryId: undefined }, subItems: [],
      mandatory: false, restaurantId: undefined, customized: undefined, options: undefined, TypeValue: undefined, availibilty: "A"
   }
   blankCate = {
      itemCategoryId: undefined

   }
   blakSubItem = {
      subItemName: undefined, price: 0, description: undefined
   }

   state = {
      filerecord: {},
      fileData: "",
      formData: {},
      pageTitle: "",
      successMessage: "",
      processStatus: false,
      enablenotification: "",
      sendnotificationvalue: "",
      pdfcsv: "",
      pdfcsvfile: "",
      filename: "",
      fileType: "",
      airLine: "",
      customization: false,
      selection: false,
      errorMessage: "",
      popUpModalShow: false,
      initRecord: this.blankItem,
      record: this.blankItem,
      recordItemCategory: this.blankCate,
      recordSubItem: this.blakSubItem,
      recordSubItems: [],

   }



   popUpModalClose = () => {

      this.setState({ popUpModalShow: false });
      this.props.onHide();
   }

   openPopUpModal = (event) => {
      event.preventDefault();
      const pageTitle = "Upload File"
      this.setState({ popUpModalShow: true });

      // console.log(this.state.addModalShow);
   }

   constructor() {
      super();
      this.multiselectRef = React.createRef();

   }

   componentDidMount() {
      // console.log(this.props.baseRecord);
      /*      if (this.props.mode === "update") {
               if (this.props.baseRecord && this.props.baseRecord.itemId) {
                  this.fetchData(this.props.baseRecord.itemId)
               }
            } else if(this.props.mode==="add") {
               this.setState({ record: { ...this.blankItem },recordItemCategory : {...this.blankItem}, recordSubItems : [], recordSubItem : {...this.blakSubItem}})
            }*/
   }

   render() {
      // console.log(this.props);
      return (
         <Modal {...this.props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            {/*<span className="clo-lg-12">{this.state.errorMessage && <h5 className="alert-danger mt-0 ml-2 mr-4"> {this.state.errorMessage} <span className="float-right pointer-cursor" onClick={() => { this.setState({ errorMessage: "" }) }}>&#x2716;</span> </h5>} </span>*/}
            <Modal.Header >
               <span className="row border_bottom col-lg-12 pt-1 pb-0 ml-0 pl-0 pr-0 mt-1 mb-0 mr-0 d-flex justify-content-center">
                  <h5 className="text-dark text-bold"  >{this.props.headerTitle}  </h5>
               </span>
            </Modal.Header>
            <Modal.Body className=" pt-1 pb-2 ml-0 pl-0 pr-0 mr-0 " >
               <div className="row pt-1 pb-2 ml-0 pl-0 pr-0 mr-0 ">
                  <div className="row col-lg-12 pt-1 pb-0 ml-0 pl-0 pr-0 mt-2 mb-0 mr-0 d-flex justify-content-start">



                     <span className="ml-5 row control-label text-left text-bold col-lg-4  ml-0 mr-0 pl-0 pr-0">
                        <span className="ml-4 control-label text-bold col-lg-12 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0">File Type</span>
                        <select className=" ml-4 h-30px form-control col-lg-12 col-md-12 col-sm-12 col-12 small mb-0 pb-0 mt-0 pt-0 ml-0 mr-0 mb-0 required" required
                           value={this.state.fileType} onChange={(event) => this.handleonChangeFields(event)}>

                           <option value="Ticket Copy">Ticket Copy</option>
                           <option value="Bulk Ticket Copy">Bulk Ticket Copy</option>
                           <option value="DSR">DSR</option>
                           <option value="GST Invoice">GST Invoice</option>
                           <option value="Bulk GST Invoices">Bulk GST Invoices</option>
                        </select>


                     </span>
                     {this.ShowOnBulkUpload()}




                     {this.onfileChange()}
                  </div>
               </div><br /><br /><br /><br />
               <span className="clo-lg-12">{this.state.errorMessage && <h5 className="alert-danger mt-0 ml-2 mr-4"> {this.state.errorMessage} <span className="float-right pointer-cursor" onClick={() => { this.setState({ errorMessage: "" }) }}>&#x2716;</span> </h5>} </span>

               {this.state.successMessage && <h5 className="alert-success pt-0 mt-0 ml-2 mr-4"> {this.state.successMessage} <span className="float-right pointer-cursor" onClick={() => { this.setState({ successMessage: "" }) }}>&#x2716;</span> </h5>}
               <br />
            </Modal.Body>
            <Modal.Footer className="row modal-footer pt-0 mt-0 mb-2 pb-2 mb-0 no-bg d-flex justify-content-center">
               {this.uploadFileButton()}
               {this.processFileButton()}
               <button className="btn btn-sm  confirm_btn_upload" onClick={() => this.props.onHide()}>Exit</button>
            </Modal.Footer>

            {this.showHidePopUp()}
         </Modal>

      );
   }

   processFileButton = () => {
      let processStatus = this.state.processStatus;
      let htmlele = [];
      if (processStatus) {
         htmlele.push(
            <button className="btn btn-sm  confirm_btn_upload" onClick={(event) => this.openPopUpModal(event)}>Add to processing<br />queue</button>
         )
      }
      return htmlele;
   }

   uploadFileButton = () => {

      let processStatus = this.state.processStatus;
      let htmlele = [];
      if (!processStatus) {
         htmlele.push(
            <button className="btn btn-sm  confirm_btn_upload" onClick={(event) => this.openPopUpModal(event)}>Upload</button>
         )
      }
      return htmlele;
   }

   onAirLinechnge = () => {
      let htmlele = [];
      if (this.state.airLine) {
         htmlele.push(

            <div className='ml-2 row col-md-12'>
               <span className=" ml-5 row control-label text-left pt-4 text-bold  col-md-5 ml-0 mr-0 pl-0 pr-2">
                  <span className="control-label text-center text-bold pt-1  ml-0 mr-0 pl-0 pr-0"> Select File</span>
                  <input className=" h-30px form-group float-left  pt-0 mt-0 mb-0 pb-0 pr-0 mr-0 pl-0 ml-0 d-flex justify-content-start" type="file" accept=".zip" id="file-input" name="CSVStyle" onChange={(event) => this.onFExcelFileChange(event)} />
               </span> </div>)
      } else {

         htmlele.push(

         )
      }
      return htmlele;
   }
   onfileChange = () => {

      let htmlele = [];
      if (this.state.fileType === "Bulk GST Invoices") {
         htmlele.push(
            <>
               {this.onAirLinechnge()}
            </>)
      }

      else {
         htmlele.push(
            <>

               <span className=" ml-3 row control-label text-left pt-4 text-bold col-lg-2 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-2">
                  <span className="control-label text-center text-bold pt-1 col-lg-12 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0"> Select File</span>
               </span>
               <span className="row control-label text-left pt-4 text-bold col-lg-4 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0">
                  <input className=" h-30px form-group float-left col-lg-12 pt-0 mt-0 mb-0 pb-0 pr-0 mr-0 pl-0 ml-0" type="file" accept=".xlsx, .xls, .csv" id="file-input" name="CSVStyle" onChange={(event) => this.onFExcelFileChange(event)} />
               </span> </>)
      }


      return htmlele;
   }
   ShowOnBulkUpload = () => {
      let htmlele = [];
      console.log(this.state.airLine)
      if (this.state.fileType === "Bulk GST Invoices") {
         htmlele.push(
            <span className="row ml-5 control-label text-left text-bold col-lg-4 ">
               <span className="  control-label text-bold col-lg-12 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0">AirLine</span>
               <select className="h-30px form-control  col-lg-12 col-md-12 col-sm-12 col-12 small mb-0 pb-0 mt-0 pt-0 ml-0 mr-0 mb-0 required" required
                  value={this.state.airLine} onChange={(event) => this.handleonChangeAirLine(event)}>
                  <option value="IndiGo">IndiGo</option>
                  <option value="Go Air">Go Air</option>
                  <option value="SpiceJet">SpiceJet</option>
                  <option value="airAsia">Air Asia</option>
                  <option value="vistara">Vistara</option>
                  <option value="United">United</option>
                  <option value="Emirates">Emirates</option>
                  <option value="Akasaair">Akasaair</option>
                  <option value="GulfAir">GulfAir</option>
                  <option value="Lufthansa">Lufthansa</option>
                  <option value="Etihad">Etihad</option>
               </select>
            </span>)
      }
      return htmlele;
   }
   onFExcelFileChange = (e) => {
      e.preventDefault();

      let fileData = e.target.files[0];
      let filename = fileData?.name
      console.log(fileData);
      this.setState({ fileData, filename })
   };

   onFileChange = (event) => {
      // Update the state 
      let files = event.target.files[0];
      let filename = files.name
      console.log(files.name);
      let reader = new FileReader();
      reader.readAsDataURL(files);
      // this.setState({ selectedFile:  }); 

      reader.onload = (e) => {
         let pdfcsv = this.state.pdfcsv;
         let itemImageraw = e.target.result;
         console.log(e.target.result);
         var itemImagerawarray = itemImageraw.split(";");
         let itemImagearray = itemImagerawarray[1].split(",");
         pdfcsv = itemImagearray[1];
         console.log(pdfcsv);
         this.setState({ pdfcsv, pdcsvffile: files, filename });
      }
   }

   handleonChangeFields = (event) => {
      let fileType = { ...this.state.fileType }
      fileType = event.target.value;

      this.setState({ fileType })
   }
   handleonChangeAirLine = (event) => {
      let airLine = { ...this.state.airLine }
      airLine = event.target.value;

      this.setState({ airLine })
   }


   showHidePopUp = () => {
      //console.log("cusotmize function called")
      if (this.state.popUpModalShow && !this.state.processStatus) {
         //console.log("addModalShow")
         return (
            <Modal {...this.props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered >
               <Modal.Header closeButton>
                  <h5 className="pb-0 mb-0 modalTitle text-dark text-bold"> Confirmation  </h5>
               </Modal.Header>
               <Modal.Body className=" mt-0 pt-0 pb-0 mb-0 ml-0 pl-0 pr-0 mr-0 " >
                  <p>Press the "SAVE" button if you want to upload the file</p>
               </Modal.Body>
               <Modal.Footer className="pt-0 mt-0">
                  <button className="btn btn-sm  w-100px confirm_btn_popup" onClick={(event) => this.popUpModalClose()}>Cancel</button>
                  <button className="btn btn-sm  w-100px confirm_btn_popup" onClick={(event) => this.uploadExcelFile()}>Save</button>

               </Modal.Footer>
            </Modal>
         );
      }
      else if (this.state.popUpModalShow && this.state.processStatus) {
         //console.log("addModalShow")
         return (
            <Modal {...this.props} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
               <Modal.Header closeButton>
                  <h5 className="pb-0 mb-0 modalTitle text-dark text-bold"> Notification  </h5>
               </Modal.Header>
               <Modal.Body className=" mt-0 pt-0 pb-0 mb-0 ml-0 pl-0 pr-0 mr-0 " >
                  <div className="col-lg-12 form form-group form-inline mt-0 mb-0 pl-0 ml-0 pt-0 mr-0 pr-0 mt-0 mb-1 pb-2">
                     <Checkbox className="col-lg-2 col-md-2 col-sm-2 col-2 ml-0 pl-0" checked={this.state.enablenotification} onClick={(event) => this.handleSelectNotifiation(event)} />
                     <span className="col-lg-8 col-md-8 col-sm-8 col-8 ml-0 pl-0 pr-0 mr-0 text-dark text-left">Post Processing Notification</span>

                  </div>
                  {this.loadSendNotification()}
               </Modal.Body>
               <Modal.Footer className="pt-0 mt-0">
                  <div className="col-lg-12 form form-group form-inline mt-0 mb-0 pl-2 ml-0 pt-0 mt-0 mb-0 pb-0 d-flex justify-content-center">
                     <span className="col-lg-6 form form-group form-inline mt-0 mb-0 pl-2 ml-0 pt-0 mt-0 mb-0 pb-0 d-flex justify-content-center"> <button className="btn btn-sm col-lg-8 w-100px confirm_btn_popup" onClick={(event) => this.popUpModalClose()}>Exit</button> </span>
                     <span className="col-lg-6 form form-group form-inline mt-0 mb-0 pl-2 ml-0 pt-0 mt-0 mb-0 pb-0 d-flex justify-content-center"> <button className="btn btn-sm  col-lg-8  w-100px confirm_btn_popup" onClick={(event) => this.processFile()}>Submit</button></span>
                  </div>
               </Modal.Footer>
            </Modal>
         );
      }
   }

   handleSelectNotifiation = (event) => {

      let enablenotification = this.state.enablenotification
      console.log(enablenotification);
      enablenotification = !enablenotification;
      this.setState({ enablenotification });
   }
   handleSendNotification = (event) => {
      let sendnotificationvalue = this.state.sendnotificationvalue
      sendnotificationvalue = event.target.value;
      this.setState({ sendnotificationvalue });
   }

   loadSendNotification = () => {
      let htmlele = [];
      let enablenotification = this.state.enablenotification
      if (enablenotification) {
         htmlele.push(
            <div className="row col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0 pb-2 pt-2 pl-0 pr-0 mr-0 ml-0" >
               <span className="text-bold  text-center col-lg-3 col-md-3 col-sm-3 col-3 pl-0 pr-0">E-mail Id</span>
               <span className="text-bold  text-center col-lg-9 col-md-9 col-sm-9 col-9 pl-0 pr-1 mr-0 ml-0 ">
                  <input type="textbox" className="h-30px form-control ml-0 mr-0 pr-0 mt-0 col-lg-12 col-md-12 col-sm-12 col-12 small  mb-2 text-sm "
                     value={this.state.sendnotificationvalue} onChange={(event) => this.handleSendNotification(event)}></input>
               </span>

            </div>
         )
      }
      return htmlele;
   }

   uploadExcelFile = () => {

      //let filename = this.state.filename;
      //let file = this.state.pdfcsv;
      //let data = {filename : filename, file : file }
      ;
      var formData = new FormData();
      formData.append("file", this.state.fileData);
      formData.append('filename', this.state.filename);
      formData?.append('type', this.state.fileType);

      if (this.state?.fileType === "Bulk GST Invoices") {

         formData?.append('AirLine', this.state.airLine);
      }
      else { formData.append('AirLine', '') }


      let data = formData
      const requestOptions = {
         method: 'POST',
         body: data,
         headers: {
            //'Content-Type': 'application/json' 
            //'Content-Type': 'application/x-www-form-urlencoded'
            'Authorization': "JWT-" + localStorage.getItem("ros_token")

         }
      };
      //let querystring = "mobile="+mobile+"&& restID="+rid;
      fetch(process.env.REACT_APP_ROS_BASE_URL + '/exceldsrdata', requestOptions)
         .then(response => response.json())
         .then(res => this.refreshExcelUploadFile(res))
         .catch(err => console.error(err), this.formData = {});
   }
   refreshExcelUploadFile(res) {
      const record = res;
      console.log(record);
      if (record.success) {
         this.formData = {};
         let filerecord = record.result;
         console.log(filerecord);
         this.setState({ filerecord, popUpModalShow: false, processStatus: true, successMessage: "The file has been uploaded successfully" });
         this.setState({ errorMessage: "" })
         this.formData = {};
      }
      if (record.success === false && record.message === "exists" && record.result === "File name already exists!") {


         this.setState({ popUpModalShow: false, processStatus: false, errorMessage: "File name already exists please upload the different file !" });
         this.formData = {};
      }
   }


   uploadFile = () => {

      let filename = this.state.filename;
      let file = this.state.pdfcsv;
      //let data = {filename : filename, file : file }
      let data = "filename=" + filename + "&file=" + file + "&type=" + this.state.fileType;
      const requestOptions = {
         method: 'POST',
         body: data,
         headers: {
            //'Content-Type': 'application/json' 
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': "JWT-" + localStorage.getItem("ros_token")
         }
      };
      //let querystring = "mobile="+mobile+"&& restID="+rid;
      fetch(process.env.REACT_APP_ROS_BASE_URL + '/dsrdata', requestOptions)
         .then(response => response.json())
         .then(res => this.refreshUploadFile(res))
         .catch(err => console.error(err));
   }

   refreshUploadFile(res) {
      const record = res;
      console.log(record);
      if (record.success) {
         let filerecord = record.result;
         console.log(filerecord);
         this.setState({ filerecord, popUpModalShow: false, processStatus: true, successMessage: "The file has been uploaded successfully" });
      }

   }

   processFile = () => {
      console.log(this.state.filerecord);
      console.log(this.state.sendnotificationvalue)
      let fileid = this.state.filerecord._id;
      let filename = this.state.filerecord.fielName;
      let sendnotificationvalue = "no email";
      if (this.state.sendnotificationvalue) {
         sendnotificationvalue = this.state.sendnotificationvalue;
      }
      //let data = {filename : filename, file : file }
      let data = "id=" + fileid + "&filename=" + filename + "&notificationEmail=" + sendnotificationvalue;
      const requestOptions = {
         method: 'POST',
         body: data,
         headers: {
            //'Content-Type': 'application/json' 
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': "JWT-" + localStorage.getItem("ros_token")
         }
      };
      //let querystring = "mobile="+mobile+"&& restID="+rid;
      fetch(process.env.REACT_APP_ROS_BASE_URL + '/dsrdatanotification', requestOptions)
         .then(response => response.json())
         .then(res => this.refreshSendMsg(res))
         .catch(err => console.error(err));
   }

   refreshSendMsg(res) {
      const record = res;
      console.log(record);
      if (record.success) {
         let filerecord = record.result;
         console.log(filerecord);
         this.setState({ popUpModalShow: false, processStatus: true, successMessage: "The file processing has been initiated" });
      }

   }
   popUpModalClose = () => {

      this.setState({ popUpModalShow: false });
      this.props.onHide();
   }

   openPopUpModal = (event) => {
      event.preventDefault();
      const pageTitle = "Upload File"
      this.setState({ popUpModalShow: true });

      // console.log(this.state.addModalShow);
   }

}


export default BulkUploadComponent;

