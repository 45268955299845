import React, { Component } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import { confirmAlert } from 'react-confirm-alert';

class ItemDetailComponent extends Component {
   blankRole = { roleId : undefined, roleName: undefined, description: undefined, privileges: undefined};
   
   state = { 
      initRecord : this.blankRole,
      record: this.blankRole,
      privileges : []
   }

   constructor() {
      super();
      this.multiselectRef = React.createRef();
   }
   
   componentDidMount() {
      if (this.props.mode === "update") {
         if (this.props.baseRecord && this.props.baseRecord._id) {
            this.fetchData(this.props.baseRecord._id)
         }
      } else if(this.props.mode==="add") {
         this.setState({ record: { ...this.blankRole }, privileges: [] });
      }
   }
   render() { 
      return ( 
         <div className="card pt-1 pb-2 ml-2" >
            <form>
               <div className="row ml-2 mr-2 mt-4 d-flex justify-content-center">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-2 mb-0">
                     <div className="form-group form-inline required">
                        <label className="control-label col-lg-2 col-md-2 col-sm-10 col-10 ">Role Name</label>
                        <input type="text" className="form-control col-10 small text-sm required" required
                           value={this.state.record.roleName || ""} onChange={(event) => this.handleOnChangeRoleName(event)}></input>
                     </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-2 mb-0">
                     <div className="form form-group form-inline required">
                        <label className="control-label col-lg-2 col-md-2 col-sm-10 col-10 ">Privileges</label>
                        <div className="col-10 small pl-0 pr-0">
                           <Multiselect
                              options={this.props.privileges} // Options to display in the dropdown
                              selectedValues={this.state.privileges} // Preselected value to persist in dropdown
                              onSelect={this.onSelectPrivs} // Function will trigger on select event
                              onRemove={this.onRemovePrivs} // Function will trigger on remove event
                              displayValue="privilege" // Property name to display in the dropdown options
                              showCheckbox={true}
                              closeOnSelect={false}
                              ref={this.multiselectRef}
                              
                           />
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-2 mb-0">
                     <div className="form form-group form-inline">
                        <label className="control-label col-lg-2 col-md-2 col-sm-10 col-10 ">Description</label>
                        <textarea className="form-control col-10 small text-sm" required
                           value={this.state.record.description || ""} onChange={(event) => this.handleOnChangeDescription(event)}></textarea>
                     </div>
                  </div>
               </div>
            </form>
            
            <div className="col-lg-8 col-md-8 col-sm-10 col-10 ml-2 mr-2 pt-1 mt-4 mb-5 row">
               <div className="col-lg-4 col-md-4 col-sm-4 col-4 div-sep-btn"></div>
               <div className="col-lg-8 col-md-8 col-sm-8 col-8 d-flex justify-content-center ">
               <button className="btn btn-sm confirm_btn w-100px" disabled = {this.disableSave()} onClick= {() => this.handleSaveClick()}>Save</button>
               <button className="btn btn-sm confirm_btn w-100px ml-2" onClick={()=> this.handleCancelClick()}>Cancel</button>
               </div>
            </div>
         </div>
      );
   }

   handleOnChangeRoleName(event) {
      this.handleMsg("", "");
      let record = { ...this.state.record }
      record.roleName = event.target.value;
      this.setState({ record });
      this.handleMsg("", "");
   }

   handleOnChangeDescription = (event) => {
      this.handleMsg("", "");
      let record = { ...this.state.record }
      record.description = event.target.value;
      this.setState({ record })
      this.handleMsg("", "");
   }

   disableSave = () => {
      if (this.isModified()) {
         return false;
      } else {
         return true;
      }
   }

   isModified = () => {
      const initRecord = this.state.initRecord;
      const record = this.state.record;
      if (initRecord.roleName !== record.roleName) {
         return true;
      } else if (initRecord.description !== record.description) {
         return true;
      } else if(this.isModifiedPrivs()) {
         return true;
      }
      return false;
   }

   isModifiedPrivs() {
      const oldPrivs = this.state.initRecord.privileges;
      const newPrivs = this.state.privileges;
      if ((!oldPrivs) && (!newPrivs)) {
         return false;
      } else if ((!oldPrivs) && newPrivs) {
         return true;
      } else if (oldPrivs && (!newPrivs)) {
         return true;
      } else if (oldPrivs.length !== newPrivs.length) {
         return true;
      } else {
         let found = true

         for (let i = 0; i < oldPrivs.length && found; i++) {
            const filteredpriv = newPrivs.filter(itm => itm.privilege === oldPrivs[i]);
            if (!(filteredpriv && filteredpriv.length > 0)) {
               found = false;
            }
         }
         if (!found) {
            return true;
         }
      }
   }

   handleMsg =(msg,type="") => {
      if (this.props.handleMsg) {
         this.props.handleMsg( msg,type);
      }
   }
   handleSaveClick = () => {
      this.handleMsg("", "");
      if (!(this.state.record.roleName && this.state.record.roleName !== "")) {
         this.handleMsg("Please enter roleName!","e")
      } else {
         const privileges = this.multiselectRef.current.getSelectedItems();
         if (!(privileges && privileges.length > 0)) {
            this.handleMsg("Please select privilege!","e")
         } else {
            confirmAlert({
               title: "Confirm", message: "Are you sure to save Item?",
               buttons: [{ label: "Yes", className:"confirm_btn_popup", onClick : ()  => this.saveRecord() }, {
                  label : "No",className:"confirm_btn_popup","onClick" : () => {return false}
               }]
            })
         }
      }
   }
   saveRecord = () => {
      const roleprivlist = this.multiselectRef.current.getSelectedItems();
      let privileges = []
      for (let i = 0; i < roleprivlist.length; i++) {
         privileges.push(roleprivlist[i].privilege)
      }
      let record = { ...this.state.record }
      record.privileges = privileges.join(",");
      
      const requestOptions = {
         method: 'POST',
         body : JSON.stringify(record),
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json",
            'Authorization' : "JWT-" + localStorage.getItem("ros_token")
         }
      };

      fetch(process.env.REACT_APP_ROS_BASE_URL + '/role', requestOptions)
         .then(response => response.json())
         .then(res => this.afterSave(res))
         .catch(err => console.error(err));
   }
   afterSave = (res) => {
      if (res["success"]) {
         let record = { ...this.state.record };
         record.roleId = res["result"];
         this.setState({ record });
         this.handleMsg("Item saved successfully!", "s");
         if (this.props.onBack) {
            this.props.onBack(true);
         }
      } else {
         this.handleMsg("Error to save role!", "e");
      }
   }

   fetchData(itemId) {
      const requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json",
            'Authorization' : "JWT-" + localStorage.getItem("ros_token")
         }
      };

      fetch(process.env.REACT_APP_ROS_BASE_URL + '/role?roleId=' + itemId, requestOptions)
         .then(response => response.json())
         .then(res => this.refreshData(res))
         .catch(err => console.error(err));
   }

   refreshData(res) {
      if (res["success"]) {
         const record = res["result"];
         let roleprivileges = record.privileges;
         console.log(roleprivileges);
         var rawprivileges =  "";
         let privileges=[];
         if (roleprivileges) {
            rawprivileges = roleprivileges.split(",")
            Object.entries(rawprivileges).map(([key, value]) => {
               privileges.push({ privilege: value });
               return true;
         })
      }
         console.log(rawprivileges);
         const initRecord = {record,privileges : [record.privileges] }
         this.setState({record,initRecord,privileges})
      } else {
         this.handleMsg("Error to get role detail !","e")
      }

      /*   if (roleprivileges) {
            Object.entries(roleprivileges).map(([key, value]) => {
               privileges.push({ privilege: value });
               return true;
            })
         }
     
         const initRecord = {...record,privileges : [...record.privileges] }
         this.setState({record,initRecord, privileges})
      } else {
         this.handleMsg("Error to get role detail !","e")
      }*/
   }

   handleCancelClick() {
      if (this.props.onBack) {
         if (this.isModified()) {
            confirmAlert({
               title: "Alert", message: "Record has been modified, are you sure to exit without saving it?",
               buttons: [{ label: "Yes", className:"confirm_btn", onClick: () => this.props.onBack(false) }, {
                  label: "No", className:"confirm_btn", "onClick": () => { return false }
               }]
            })
         } else {
            this.props.onBack(false);
         }
      }
   }

   onSelectPrivs = (selectedList, selectedItem) => {
      this.setPrivs(selectedList);
   }

   onRemovePrivs = (selectedList, removeItem) => {
      this.setPrivs(selectedList);
   }

   setPrivs = selectedList => {
      let privileges = [];
      for (let i = 0; i < selectedList.length; i++) {
         privileges.push(selectedList[i])
      }
      this.setState(privileges)
   }
}
 
export default ItemDetailComponent;