import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import Login from '../Auth/login';
import { checkAuth } from '../Auth/AuthRoute'
import MainComponent from './main.component';
import ForgotPasswordComponent from './forgotPassword/forgotpasssword.component';
import EnterOTP from './enterotp/enter-otp.component';
import ClientDetail from './clientdetail/clientdetail.component';
class PortalComponent extends Component {
   render() {
      return (
         <Router>
            <Switch>
               <Route path="/portal/login" exact render={(props) => (checkAuth() ? <Redirect to={{ pathname: "/portal/home" }} /> : <Login {...props} />)} />
               <Route path="/portal/forgotpassword" render={(props) => <ForgotPasswordComponent {...props} />} />
               <Route path="/portal/enterotp" render={(props) => <EnterOTP {...props} />} />
               <Route path="/portal/clientdetail" render={(props) => <ClientDetail {...props} />} />
               <Route path="/portal" render={(props) => (checkAuth() ? <MainComponent {...props} /> : <Redirect to={{ pathname: "/portal/login" }} />)} />

            </Switch>
         </Router>
      )
   }

}

export default PortalComponent