//import React, { Component ,useState, useEffect } from 'react';
class CurrencyComponent {
   currencies = {
      "USD": "$", 
      "INR": "₹"
   };

   currency = "USD";
   
   getCurrency = () => {
      return this.currencies[this.currency]
   }
}

export const currency =(() => {
   let currencyInstance = undefined;

   const getCurrency = () => {
      if (!currencyInstance) {
         currencyInstance = new CurrencyComponent();
      }
      return currencyInstance.getCurrency();
   }
   return getCurrency;
})()

export const isMobile = () => {
   if (window.innerWidth > 500) {
      return false;
   } else {
      return true;
   }
}
