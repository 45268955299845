import React, { Component } from 'react';
import './logout.css';
import PageTitle from '../page.title';

class ChangePass extends Component {
   state = {
    pageTitle : "Change Password",
        open: false,
        loginerrormsg:"",
        
       user:{username:"",password:"", oldpassword:""},
      password2:"",
      successMessage:"",
      errorMessage:""
      
      }
      componentDidMount(){
        const userName=JSON.parse(localStorage.getItem("user"));
      
     if(userName){
      let user = { ...this.state.user }
       user.username=userName["username"]
       this.setState({user});
     };
      
       }
       handleOnChangePassword=(event)=>{
        let user = { ...this.state.user }
        user.password=event.target.value;
        this.setState({user});
       
       }
       handleOnChangePassword2=(event)=>{
        const password2 = event.target.value;
        this.setState({ password2:password2 })
       
       }
       handleOnChangeoldPassword=(event)=>{
        let user = { ...this.state.user }
        user.oldpassword = event.target.value
        this.setState({ user });
        this.setState({ loginerrormsg: "" });
     //handle change events)
       }
  
       ChangePassPassword=(event)=>{
     
        event.preventDefault();
        if(this.state.user["password"]!=this.state.password2){
          const errorMessage = "Please enter confirm_password same as new_password !"
          this.setState({ errorMessage }); 
          //console.log(this.state.user["password"]);
          //console.log(this.state.password2);
        }
        else{
        const requestOptions = {
          method: 'POST', 
          body : JSON.stringify(this.state.user),
          headers: {
             'Content-Type': 'application/json; charset=utf-8',
             'Accept': "application/json",
             'Authorization' : "JWT-" + localStorage.getItem("ros_token")
          }
       };
      
       fetch(process.env.REACT_APP_ROS_BASE_URL + '/changepassword', requestOptions)
       .then(res => res.json())
       .then(res => this.afterSave(res))
        .catch(err => console.error(err));
       
      }}
    afterSave=(res) => {
    
      if (res["success"]) { 
       
      
    
      sessionStorage.clear();
     // localStorage.clear();
      localStorage.removeItem("user");
      localStorage.removeItem("ros_token");
       // history.push("/portal/login");
       const successMessage = "Password Changed Sucessfully !"
       this.setState({ successMessage }); 
      
      }
      else{
        const errorMessage = "Please Check your Old Password!"
        this.setState({ errorMessage }); 
      }
    }
   
    render() { 
        return (

          <React.Fragment> 
          <PageTitle title={this.state.pageTitle} classes="mt-1" />
          {this.state.errorMessage && <h5 className="alert-danger mt-0 ml-2 mr-4"> {this.state.errorMessage} <span className="float-right pointer-cursor" onClick={() => { this.setState({ errorMessage: "" }) }}>&#x2716;</span> </h5>}
          {this.state.successMessage && <h5 className="alert-success mt-0 ml-2 mr-4"> {this.state.successMessage} <span className="float-right pointer-cursor" onClick={() => { this.setState({ successMessage: "" }) }}>&#x2716;</span> </h5> }
          
              
   <form className="mt-2">

<div className="mt-0 row">

  <div className="col-md-6">
    <div className="form-group required form-inline  ml-2 mt-4 mb-0">
      <label name="oldPassword" className="col-md-5 c-mw-px-200 control-label">Old Password</label>
      <input type="password" className="form-control col-md-7 mb-1" 
        data-toggle="tooltip" 
        value={this.state.oldpassword}
         onChange={(event) => this.handleOnChangeoldPassword(event)}
        >
   </input> </div>
    <div className="form-group required form-inline  ml-2 mt-1 mb-0">
      <label name="newpassword" className="col-md-5 c-mw-px-200 control-label">New Password</label>
      <input  type="password" className="form-control col-md-7 mb-1" minLength="3" maxLength="50"
         value={this.state.password}
         onChange={(event) => this.handleOnChangePassword(event)}
         
        pattern="(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])(?=.*[@#$%!]).{8,30}" data-toggle="tooltip"
       >
  </input>  </div>
    <div className="form-group required form-inline  ml-2 mt-1 mb-0">
      <label name="newpassword2" className="col-md-5 c-mw-px-200 control-label">Confirm_Password</label>
      <input type="password" className="form-control col-md-7 mb-1" minLength="3" maxLength="50"
         data-toggle="tooltip"value={this.state.password2}
         onChange={(event) => this.handleOnChangePassword2(event)}
        >
    </input></div>
  </div>
  <div className="col-md-1"></div>
  <div className="col-md-4 alert-danger small">
    <p className="mt-4">
      Password length from 8 to 30 characters.
    </p>
    <p className="mt-0">
      Combination of alphabet, digit and special character(@#$%!)
    </p>
    <p className="mt-0">
      Atleast 1 capital letter, 1 small letter, 1 digit and 1 special character .
    </p>
    <p className="mt-0">
      Start with alphabet.
    </p>
  </div>
</div>
<div className="row mt-4">
  <div className="col-md-8 mt-0 mb-2 d-flex justify-content-center span2">
    <button className="btn btn-outline-primary shadow" onClick={(event)=> this.ChangePassPassword(event)}>Change Password</button>
  </div>
</div>

</form>
                       
</React.Fragment>      
       
          );
     }

}
export default ChangePass;