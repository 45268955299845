
import React, { Component } from "react";
import {Modal, Button,Row,Col} from 'react-bootstrap';
//import {BsX} from react-icon;
import { BsX } from "react-icons/bs";
import { BsChevronDown,BsChevronRight } from "react-icons/bs";
import { confirmAlert } from 'react-confirm-alert';
import {tofix_2} from '../../common/utility.jsx'
import { Multiselect } from 'multiselect-react-dropdown';
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
//pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';


class TemplateComponent extends Component {
    blankSource = {
        url: undefined,username: undefined,password: undefined}
    state = {
        addModalShow :false ,
        data: [],
        templateFields:[],
        name:"",
        field : "-",
        pdffile:"",
        pdf:"",
        record : "",
        pageTitle : "Data Source",
        headerTitle: "Category Details",

    }
    render() { 
        return(
            <React.Fragment>   
                 <div style={{maxHeight : '95vh',overflowX : 'hidden'}} className="row pl-1 ml-1 pr-1 mr-1 justify-content-center">  
                <div className="row border bg_color col-lg-12 pt-1 pb-2 ml-0 pl-0 mr-0 pr-0 mt-2 mb-1 mr-0 justify-content-center">
                <h2 className="col-lg-12 mt-0 pl-0 ml-2 pt-0 text-center" > Template </h2>
                <span className="col-lg-12 mt-0 pt-0 ml-0 pl-1 text-primary text-center"><h3 className="text-primary text-center">Add a new Data Extraction Template </h3>  </span>    
                </div>

                <div style={{maxHeight : '80vh', overflowX : 'hidden'}} className="row col-lg-7 bg_color card pt-0 mt-0 mr-0 pr-1 pb-2">
                <div className="row col-lg-12 pt-1 pb-0 ml-1 pl-1 mt-2 mb-0 mr-0 justify-content-center">
                <div className=" row col-lg-4 pt-0 pb-2 ml-1 pl-1 mt-0 mb-0 mr-1 pr-1 justify-content-center">
                <span className="control-label  pb-0 mb-0 text-bold small text-left col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0">Upload PDF
                <input type="file" accept=".pdf" id="file-input" name="PDFStyle" onChange={(event)=>this.onFileChange(event)}/>       </span>
               
               </div> 
               <div>
               {this.loadPDFPreview()}
               </div>
                </div>
                </div>

                <div style={{maxHeight : '80vh'}}  className="row col-lg-5 bg_color card pt-0 mt-0 pb-2 ml-1 ">
                <div className="row col-lg-12 pt-1 pb-0 ml-1 pl-1 mt-2 mb-0 mr-0 justify-content-center">
                <div className=" row col-9 pt-0 pb-2 ml-1 pl-1 mt-0 mb-0 mr-1 pr-1 justify-content-center">
                <span className="control-label text-bold text-left col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0">Template Name</span>
            <input type="text" className="h-30px form-control col-lg-12 col-md-12 mb-2 col-sm-12 col-12 small text-sm required" required
               value={this.state.name} onChange={(event) => this.handleOnChangeName(event)}></input>
                
                <div className="row col-lg-12 pt-1 pb-0 ml-0 pl-0 pr-0 mt-2 mb-0 mr-0">
                <span className="control-label text-bold col-lg-12 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0">Fields</span>
                <div className="row col-lg-12 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0">
                <span className="control-label text-bold col-lg-12 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0">
                       
                <select className="h-30px form-control col-lg-12 col-md-12 col-sm-12 col-12 small ml-0 mr-0 mb-0 required" required
                           value={this.state.field} onChange={(event) => this.handleonChangeFields(event)}>
                            <option>-</option>
                           <option value="PNR">PNR</option>
                           <option value="Sector">Sector</option>
                           <option value="Invoice No">Invoice No</option>
                           <option value="Invoice Amt">Invoice Amt.</option>
                           <option value="Status">Status</option>
                           </select>
                  </span>
                </div>
                  
               {this.loadTemplatesField()}
               
                  </div>
                  
               </div> 
              
               <div className="row col-lg-12 pt-0 pb-0 ml-0 pl-0 mt-0 mb-0 mr-0 justify-content-center">
                <button onClick={() => this.onSubmitHandle()} className="col-lg-4 pl-0 ml-0 mr-0 pr-0 pt-0 pb-0 mt-0 mb-0 btn btn-sm confirm_btn_popup">Save</button>
                 </div>
                </div>
                
                
                </div>

                </div>
         </React.Fragment> 
        )
    }

    itemAlert=()=>{
      confirmAlert({
         title: "Alert", message: "Item already exist",
         buttons: [{ label: "Ok", className:"confirm_btn_popup" ,onClick : ()  => {return false} } 
         ],
         });
        }
  onSubmitHandle=()=>{
    if (this.state.pdf===""){
      confirmAlert({
        title: "Alert", message: "Please select a file",
        buttons: [{ label: "Ok", className:"confirm_btn_popup" ,onClick : ()  =>{return false}  } 
        ],
        });
    }
    else if(this.state.name===""){
      confirmAlert({
        title: "Alert", message: "Please enter a template Name",
        buttons: [{ label: "Ok", className:"confirm_btn_popup" ,onClick : ()  =>{return false}  } 
        ],
        });
    }
    else if(this.state.field==="-" && this.state.templateFields.length<=0){
      confirmAlert({
        title: "Alert", message: "Please select at least 1 field",
        buttons: [{ label: "Ok", className:"confirm_btn_popup" ,onClick : ()  =>{return false}  } 
        ],
        });
    }
    else{
  confirmAlert({
     title: "Success", message: "Template saved successfully",
     buttons: [{ label: "Ok", className:"confirm_btn_popup" ,onClick : ()  => {window.location.reload()} } 
     ],
     });
    }
    }
    onSelectPrivs = (OptionList, selectedItem) => {
      this.setPrivs(OptionList);
    // console.log(OptionList);
   }

   onRemovePrivs = (OptionList, removeItem) => {
      this.setPrivs(OptionList);
   }

   setPrivs = OptionList => {
      let selectedValues = [];
      
      for (let i = 0; i < OptionList.length; i++) {
         selectedValues.push(OptionList[i].id)
       // console.log(selectedValues[i].id);
      
      }
      this.setState(selectedValues);
       
   //  console.log(selectedValues.join(","));
      let RawTypeValue = selectedValues.join(",");
     // console.log(RawTypeValue)
     let TypeValue =  RawTypeValue.replace(/^,/g,'');
     //console.log(TypeValue)
     this.setState({TypeValue});
   //console.log(TypeValue);
     
    
   }
   popField=(field)=>{
     let templateField = this.state.templateFields;
     if(templateField.includes(field))
     {
       templateField.splice(field,1);
     }
     this.setState({ templateField,field:"-"});

   }
   addToTemplates=(field)=>{
    let templateFields = this.state.templateFields;
    if(templateFields.includes(field))
    this.itemAlert();
    else{
      templateFields.push(field)
    }
  }
  
    loadTemplatesField=()=>{
      let templateFields=this.state.templateFields;
      let htmlele =[];
      if(templateFields.length>0){
        for(let i=0;i<templateFields.length;i++){
          htmlele.push(
            <div className="bg_color row pl-0 ml-0 mr-0 pr-0 mt-2 pt-0 card col-lg-12">
              <span className="pl-0 ml-0 mr-0 pr-0 mt-2 pt-0 col-lg-12">
              <label className="pl-2 ml-0 mr-0 pr-0 col-lg-11">{templateFields[i]}</label>
              <span className="pl-0 ml-0 pr-0 mr-0 col-lg-1" onClick={() => this.popField(templateFields[i])} ><BsX/> </span>
              </span>
            </div>
          )
        }
      }
      return htmlele;
    }


    onFileChange =(event)=> { 
        // Update the state 
        let files  = event.target.files[0];
        //console.log( files );
      let reader = new FileReader();
      reader.readAsDataURL(files);
       // this.setState({ selectedFile:  }); 
  
     reader.onload=(e)=>{
      let pdf = this.state.pdf;
  
      let itemImageraw=e.target.result
      console.log(itemImageraw);
      var itemImagerawarray=itemImageraw.split(";");
       let  itemImagearray = itemImagerawarray[1].split(",");
        pdf = itemImagearray[1];
        console.log(pdf);
   this.setState({pdf,pdffile:files});
     }      
      } 


  loadSourceData=()=>{
      let htmlele = [];
      let source= this.state.source;
      console.log(source);
      if (source === "Email"){
          htmlele.push(
              <div>
            <span className="control-label small  text-bold  text-left col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0">EmailId</span>
            <input type="text" className="h-30px form-control col-lg-12 col-md-12 mb-2 col-sm-12 col-12 small text-sm required" required
               value={this.state.record.url || ""} onChange={(event) => this.handleOnChangeURL(event)}></input>
                 <span className="control-label small  text-bold  text-left col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0">UserName</span>
            <input type="text" className="h-30px form-control col-lg-12 col-md-12 mb-2 col-sm-12 col-12 small text-sm required" required
               value={this.state.record.username || ""} onChange={(event) => this.handleOnChangeUserName(event)}></input>
                 <span className="control-label small  text-bold  text-left col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0">Password</span>
            <input type="text" className="h-30px form-control col-lg-12 col-md-12 mb-2 col-sm-12 col-12 small text-sm required" required
               value={this.state.record.password || ""} onChange={(event) => this.handleOnChangePassword(event)}></input>
        </div>
          )

      }else if(source === "Web"){
        htmlele.push(
            <div>
          <span className="control-label small  text-bold  text-left col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0">Portal URL</span>
          <input type="text" className="h-30px form-control col-lg-12 col-md-12 mb-2 col-sm-12 col-12 small text-sm required" required
             value={this.state.record.url || ""} onChange={(event) => this.handleOnChangeURL(event)}></input>
               <span className="control-label small  text-bold  text-left col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0">UserName</span>
          <input type="text" className="h-30px form-control col-lg-12 col-md-12 mb-2 col-sm-12 col-12 small text-sm required" required
             value={this.state.record.username || ""} onChange={(event) => this.handleOnChangeUserName(event)}></input>
               <span className="control-label small  text-bold  text-left col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0">Password</span>
          <input type="text" className="h-30px form-control col-lg-12 col-md-12 mb-2 col-sm-12 col-12 small text-sm required" required
             value={this.state.record.password || ""} onChange={(event) => this.handleOnChangePassword(event)}></input>
      </div>
        )

      }else if (source === "SFTP"){
        htmlele.push(
            <div>
          <span className="control-label small  text-bold  text-left col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0">SFTP Server</span>
          <input type="text" className="h-30px form-control col-lg-12 col-md-12 mb-2 col-sm-12 col-12 small text-sm required" required
             value={this.state.record.url || ""} onChange={(event) => this.handleOnChangeURL(event)}></input>
               <span className="control-label small  text-bold  text-left col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0">UserName</span>
          <input type="text" className="h-30px form-control col-lg-12 col-md-12 mb-2 col-sm-12 col-12 small text-sm required" required
             value={this.state.record.username || ""} onChange={(event) => this.handleOnChangeUserName(event)}></input>
               <span className="control-label small  text-bold  text-left col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0">Password</span>
          <input type="text" className="h-30px form-control col-lg-12 col-md-12 mb-2 col-sm-12 col-12 small text-sm required" required
             value={this.state.record.password || ""} onChange={(event) => this.handleOnChangePassword(event)}></input>
      </div>
        )

      }
      return htmlele;
  }

  loadPDFPreview=()=>{
    let htmlele = [];
    let pdf=this.state.pdf;
    console.log(pdf)
    if(pdf!=""){
  //  let  pdfdoc = atob(pdf);
htmlele.push(

  <Document  file={this.state.pdffile} >
  <Page pageNumber={1} />
</Document>

)
    }
return htmlele;
  }
  handleOnChangeName = (event) => {
    let name = { ...this.state.name }
    name=event.target.value;
    this.setState({ name }) ;
}
handleonChangeFields = (event) => {
    let field = { ...this.state.field }
    field=event.target.value;
    let templateFields=[];
    templateFields= this.addToTemplates(field);
    this.setState({ field,templateFields}) 

}
addToTemplates=(field)=>{
  let templateFields = this.state.templateFields;
  if(templateFields.includes(field))
  this.itemAlert();
  else{
    templateFields.push(field)
  }
  
  return templateFields;

}
  handleOnChangeURL = (event) => {
    let record = { ...this.state.record }
    record.url = event.target.value;
    this.setState({ record });
 }
 handleOnChangePassword = (event) => {
    let record = { ...this.state.record }
    record.password = event.target.value;
    this.setState({ record });
 }
 handleOnChangeUserName = (event) => {
    let record = { ...this.state.record }
    record.username = event.target.value;
    this.setState({ record });
 }
}

export default TemplateComponent;