import React, { Component } from 'react';
//import { confirmAlert } from 'react-confirm-alert'; // Import
//import PageTitle from '../page.title';
import './recon.css';
import moment from 'moment';
import { withRouter } from 'react-router'
//import {tofix_2} from '../../common/utility.jsx'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//import { PaginationComponent, adjustInitPageButton } from '../GridTable/pagination.component';
import GridTableComponent from '../GridTable/grid.table.component';
//import DetailsComponent  from './details.component';

class QueueViewComponent extends Component {
    state = {
        dataset:"DSR",
        startTime:new Date(),
        stopTime:new Date(),
        sendnotificationvalue:"",
        recordsData:[]
    }

    render() { 
        return ( 
           <React.Fragment>            
             <div className="row pl-1 ml-1 pr-1 mr-1 justify-content-center"> 
             <div className="row border bg_color col-lg-12 pt-1 pb-2 ml-0 pl-0 mr-0 pr-0 mt-2 mb-1 mr-0 justify-content-center">
                <h2 className="col-lg-12 mt-0 pl-0 ml-2 pt-0 text-center" > Queue View  </h2>
                <span className="col-lg-12 mt-0 pt-0 ml-0 pl-1 text-primary text-center"><h3 className="text-primary text-center"> View file processing Status </h3>  </span>    
                </div> 
                
                <div className="row col-lg-12 pt-0 mt-0 pb-2">
                 <div className="col-lg-12 pt-0 pb-0 ml-0 pl-0 mt-0 mb-0 mr-0 pr-0">
                <div className="row col-lg-12 pt-0 pb-0 ml-0 pl-0 mt-0 mb-0 mr-0 pr-0">
                <span className="control-label text-bold col-lg-3 col-md-3 col-sm-3 pt-1 mt-1 col-12 ml-0 mr-0 pl-0 pr-0">
                <label className="control-label text-bold col-lg-12 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0">Select Data Set</label>
                <select className="h-30px form-control col-lg-11 col-md-12 col-sm-12 col-12 small ml-0 mr-0 mb-2 required" required value={this.state.dataset} onChange={(event) => this.handleonChangeFields(event)}>
                            <option>-</option>
                           <option value="DSR">DSR</option>
                           <option value="Ticket Copy">Ticket Copy</option>
                           <option value="Bulk Ticket Copy">Bulk Ticket Copy</option>
                           </select>
                </span>
                <span className="control-label col-lg-4 col-md-3 col-sm-3 pt-1 mt-1 col-12 ml-0 mr-0 pl-0 pr-0">
                    <label  className="ml-0 text-bold mr-0 pr-0 pl-0 mt-0 col-lg-11 col-md-12 col-sm-12 col-12">Search</label>
                    <input type="textbox" className="h-30px form-control ml-0 mr-0 pr-0 pl-1 mt-0 col-lg-11 col-md-12 col-sm-12 col-12 "value={this.state.sendnotificationvalue} onChange={(event) => this.handleSearch(event)}></input>    
                </span>
                
                <span className="row control-label col-lg-5 col-md-3 col-sm-3 pt-1 mt-1 col-12 ml-0 mr-0 pl-0 pr-0">

                <span className="col-lg-6  form-inline mt-1 mb-1 pl-0 ml-0 mr-0 pr-0">
                <span  className="control-label text-bold align-left ml-0 mr-0 pr-0 pl-0 mt-0 col-lg-12 col-md-12 col-sm-12 col-12">From</span>
                 <DatePicker className="form-control form-control-sm col-lg-12" selected={this.state.startTime}  datePickerType="single" popperPlacement="left-start" value={this.state.startTime} name="startTime" onChange={this.handlechangeDate}  scrollableYearDropdown showYearDropdown />
                 </span>

                <span className=" col-lg-6 form-inline mt-1 mb-1 pl-0 ml-0 mr-0 pr-0">
                <span  className="control-label text-bold ml-0 mr-0 pr-0 pl-0 mt-0 col-lg-12 col-md-12 col-sm-12 col-12">To</span>
                <DatePicker   className="form-control form-control-sm col-lg-12" selected={this.state.stopTime} value={this.state.stopTime} popperPlacement="left-start" name="stopTime" onChange={this.handlechangeEndDate} scrollableYearDropdown showYearDropdown />
               </span>

               </span>

                </div>
                </div>

                <div className="row col-lg-12 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0 mt-2 pt-2 justify-content-center">
                <span className="control-label text-bold col-lg-12 col-md-12 col-sm-12 col-12 ml-0 mr-0 pl-0 pr-0"> 
                <GridTableComponent cols={this.getCols()} data={this.state.recordsData}  onRowClick={this.onSelectRecord} disable={this.state.disableTable} _this={this} />
               </span>
                </div>
                </div>
                
                </div>
                <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
             
           </React.Fragment>
         );
     }
     handleSearch=(event)=>{
        let sendnotificationvalue = this.state.sendnotificationvalue
        sendnotificationvalue =event.target.value;
        this.setState({ sendnotificationvalue }) ;
      }
     getCols = () => {
        return [{ header: "FileName", key: "fileName", rowclasses : "text-center", headerclasses: "w-20 text-center", },
           { header: "Status", key: "status",rowclasses : "text-center", headerclasses: "w-20 text-center",fnValue:this.getStatus },
           { header: "Time to complete", key: "AirlineInvoiceId", headerclasses: "w-20",rowclasses : "mr-0"},
           { header: "Created at" , key: "savedDate", headerclasses : "w-10",rowclasses : "pr-2 "},
           { header: " Details" , key:"fileName", headerclasses : "w-10",rowclasses : "pr-2 ",fnValue:this.getViewDetails}];
     }
     handleonChangeFields = (event) => {
        let dataset = { ...this.state.dataset }
        dataset=event.target.value;
        this.setState({ dataset}) 
    }
    handlechangeDate=date=>{
        const startTime =this.formatDateUs(date)
         this.setState({startTime:startTime})
          console.log(startTime)

      }
     handlechangeEndDate=date=>{
        const stopTime =this.formatDateUs(date)
        this.setState({stopTime:stopTime})
         console.log(stopTime)

       }
       getViewDetails=(fileName)=>{
         console.log(fileName)
          return(
            <label className="text-primary font_14 text-bold mr-0 ml-0 pr-0 pl-0 mt-0 col-lg-11 col-md-12 col-sm-12 col-12 cursorpointer" onClick={(event)=>this.toDetails(fileName)} >View</label> 
          );
       }
       toDetails = (fileName) => {
         // sessionStorage.setItem('items',JSON.stringify(this.state.itemsList));
          this.props.history.push({pathname:'/portal/details', filename:fileName,fileselected:true})
      }
       formatDateUs=(dateValue)=>{
        let val=Date.parse(dateValue);
        if(val) {
              const datev = new Date(dateValue);
              //const strDate =  this.getMonthname(datev.getMonth()+1)+"-"+datev.getDate()  + "-" + datev.getFullYear()
             // return strDate;
              return datev;
           } else {
              return dateValue;
           }            
     }
     getMonthname =  (month) => {
        switch (month) {
           case 1:
              return "Jan";
           case 2:
              return "Feb";
           case 3:
              return "Mar";
           case 4:
              return "Apr";
           case 5:
              return "May";
           case 6:
              return "Jun";
           case 7:
              return "Jul";
           case 8:
              return "Aug";
           case 9:
              return "Sep";
           case 10:
              return "Oct";                  
           case 11:
              return "Nov";
           case 12:
              return "Dec";
           default:
              return month;
        }
     }

     getStatus=(status)=>{
      if(status==="N"){
         return "Uploaded";
      }else if(status==="P"){
         return "Processing";
      }else if(status==="UP"){
         return "Under Process";
      }else if(status==="C"){
         return "Fully Processed";
         
      }else if(status==="F"){
      return "Failed(File Formet Wrong)"}
      else
      {
         return status;
      }
         }

     getFormattedDate = (val) => {
      let date;
        if(val){
        // console.log(val.toString())
         console.log(moment(val).format('DD-MM-YYYY '+'hh:MM:ss'))
         date= moment.tz(val,"Asia/Kolkata").format('DD-MM-YYYY '+'hh:MM:ss');
        }
        else{
         date="";
        } 
        return date;
      }

     getFileProcessData = () => {
    //console.log(tableCode);
       //POST request using fetch inside useEffect React hook
       const requestOptions = {
          method: 'GET',
          headers: {
             'Content-Type': 'application/json; charset=utf-8',
             'Accept' : "application/json",
             'Authorization' : "JWT-" + localStorage.getItem("ros_token")
          }
       };
       //const queryString = "rid=" + rid + "&tablenumber=" + tableCode;
       fetch(process.env.REACT_APP_ROS_BASE_URL + '/getfilesstatus', requestOptions)
          .then(response => response.json())
          .then(res => this.refreshProcessData(res))
          
          .catch(err => alert(err));
 
 // empty dependency array means this effect will only run once (like componentDidMount in classes)
    
    }
    refreshProcessData = (res) => {
       //console.log(res)
       var record = res;
      console.log(record)
       if (record.success) {
         let recordsData  = record.results;
          this.setState({recordsData});
          //this.getConfigData();
         
       }
      }
      componentDidMount=()=>{
         this.getFileProcessData();
         console.log(this.props)
      }
   

}
export default withRouter(QueueViewComponent);