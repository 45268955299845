import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import PageTitle from '../page.title';
import './recon.css';
import {tofix_2} from '../../common/utility.jsx'
import { PaginationComponent, adjustInitPageButton } from '../GridTable/pagination.component';
import GridTableComponent from '../GridTable/grid.table.component';


class AirlinevsGSTRComponent extends Component {
   blankCate = {
      itemId: undefined, itemName: undefined, type: undefined, price: undefined,sellingPrice:undefined,
      description: undefined,itemMetadata:undefined, category: { itemCategoryId: undefined }, subItems : []
   }
   blankSubItem = { SubItem: undefined, subItemName: undefined, price: undefined, description: undefined };
   boxClass = { totalAirlineInvoice: 'recon_less_btn ', AiInWithnoGSTR: 'recon_less_btn ', partiallyMatched: 'recon_less_btn ', GSTRwithNoAiIn: 'recon_less_btn ', fullyMatched: 'recon_less_btn '  };
   
   state = { 
      addModalShow :false ,
      sortFieldName:"itemName",
      sortDirection:"A",
      detail: false,
      reconClass:"recon_less_btn ",
      data: [],
      record: { ...this.blankCate },
      boxRecord: { ...this.boxClass },
      pageTitle : "",
      page : {offset :  1, /*Start record from*/
         totalPages : 1, /*number of pages*/
         totalElements : 0, /*number of records*/
         last : true, /*is last page*/
         first: true, /*is first page*/
         size: 20, /*page size*/
         number : 1, /* current page*/
         numberOfElements: 0 /*Total number of record*/
      },
      pagebuttons: [],
      headerTitle:"Item Details",
      type:[],
      searchText: "",
      errorMessage: "",
      successMessage : "",
      disableTable: false,
      categories: [],
      mode : "none",
      selectedTeam:"",
   }

   currencies = {
    "USD": "$",
    "INR": "₹"
 }
 
 currency = "INR";

 getCurrent() {
    return this.currencies[this.currency]
 }
   
   componentDidMount() {
      this.getData(this.state.page);
   
    

   }
   render() { 
      return ( 
         <React.Fragment>            
           
            {this.state.errorMessage && <h5 className="alert-danger mt-0 ml-2 mr-4"> {this.state.errorMessage} <span className="float-right pointer-cursor" onClick={() => { this.setState({ errorMessage: "" }) }}>&#x2716;</span> </h5>}
            {this.state.successMessage && <h5 className="alert-success mt-0 ml-2 mr-4"> {this.state.successMessage} <span className="float-right pointer-cursor" onClick={() => { this.setState({ successMessage: "" }) }}>&#x2716;</span> </h5> }
            {this.loadPageElement()}
            {this.showHideDetails()}
         </React.Fragment>
       );
   }
   showHideDetails=() => {
    //  console.log(this.state.record)
      if(this.state.addModalShow) {
         //console.log("addModalShow")
         return <div ></div>
      }
   }

   addModalClose =() => {
      this.setState({addModalShow:false});
   }

   openModalwithDetails=(event)=>{
      const headerTitle = "Item Details"
      event.preventDefault();
      this.setState({addModalShow:true});
      this.setState({ mode : "update"})
      this.setState({headerTitle})
     // console.log(this.state.addModalShow);
      }

   itemView = () => {
      window.location.reload(); 
   }

   loadpageTitle = ()=>
     {
        let htmlele=[];
            htmlele.push(
               <div key={"div"+this.state.record.tableID} className="col-12 mr-1 pl-0 ml-0 pr-1 pb-0 pr-0">
               <span className="col-12 pl-0 ml-0 mr-0 pr-0 page-title"> {this.state.pageTitle}</span> 
               </div>
              )    
           return htmlele;
        }

   loadPageElement = () => {
      if (this.state.detail) {
         
         return (
            <div className="row card pt-1 pb-2 ml-0 mr-2 pr-2">
               <span className="col-12 pl-2 ml-3">
               <PageTitle  title={this.loadpageTitle()} />
               </span> 
            <p className="mb-2 col-12 mr-0 pr-0 mb-2 mt-0 pt-0 ml-2 pl-3 page-title-text">Edit, update items/subitems for your restaurant</p>
         </div>
         )
      } else {
         return (
         <React.Fragment>
            {this.state.errorMessage && <h5 className="alert-danger mt-0 ml-2 mr-4"> {this.state.errorMessage} <span className="float-right pointer-cursor" onClick={() => { this.setState({ errorMessage: "" }) }}>&#x2716;</span> </h5>}
             {this.state.successMessage && <h5 className="alert-success mt-0 ml-2 mr-4"> {this.state.successMessage} <span className="float-right pointer-cursor" onClick={() => { this.setState({ successMessage: "" }) }}>&#x2716;</span> </h5> }
             
         <div className="row col-lg-12 pt-1 pb-2 ml-0 pl-0 mt-2 mb-1 pr-0 mr-0 d-flex justify-content-center">
         <PageTitle title={this.loadpageTitle()} />
         
        <div className="form form-inline row pl-0 ml-0 col-lg-12 col-md-12 col-sm-12 col-12 pt-2 mr-0 pr-0 " > 
        <span className={this.state.boxRecord.totalAirlineInvoice+"btn ml-2 pl-2 row pt-1 pb-1"}   onClick={() => this.onBoxClick("totalAirlineInvoice")}>
        <label className="pl-0 ml-0 col-md-12 col-lg-12 d-flex  justify-content-center font_14 mr-0 pr-0 mb-2">Total <br/>Airline Invoices</label> 
        <label className="col-md-12 col-lg-12 mb-1 mt-2 font_24"><b>0</b>  </label> 
        <label className="col-md-12 col-lg-12 mb-1 mt-2">{this.getCurrent()}</label>
        </span>
        <span className={this.state.boxRecord.AiInWithnoGSTR+"btn ml-2 pl-2 row pt-1 pb-1"}   onClick={() => this.onBoxClick("AiInWithnoGSTR")}>
        <label className="pl-0 ml-0 col-md-12 col-lg-12 d-flex  justify-content-center font_14 mr-0 pr-0 mb-2">Airline Invoices with <br/>No GSTR2A</label> 
        <label className="col-md-12 col-lg-12 mb-1 mt-2 font_24"><b>0</b>  </label> 
        <label className="col-md-12 col-lg-12 mb-1 mt-2">{this.getCurrent()}</label>
        </span>
        <span className={this.state.boxRecord.partiallyMatched+"btn ml-2 pl-2 row pt-1 pb-1"}   onClick={() => this.onBoxClick("partiallyMatched")}>
        <label className="pl-0 ml-0 col-md-12 col-lg-12 d-flex  justify-content-center font_14 mr-0 pr-0 mb-2">Partially<br/> Matched</label> 
        <label className="col-md-12 col-lg-12 mb-1 mt-2 font_24"><b>0</b>  </label> 
        <label className="col-md-12 col-lg-12 mb-1 mt-2">{this.getCurrent()}</label>
        </span>
        <span className={this.state.boxRecord.GSTRwithNoAiIn+"btn ml-2 pl-2 row pt-1 pb-1"}   onClick={() => this.onBoxClick("GSTRwithNoAiIn")}>
        <label className="pl-0 ml-0 col-md-12 col-lg-12 d-flex  justify-content-center font_14 mr-0 pr-0 mb-2">GSTR2A With <br/>No Airline Invoices</label> 
        <label className="col-md-12 col-lg-12 mb-1 mt-2 font_24"><b>0</b>  </label> 
        <label className="col-md-12 col-lg-12 mb-1 mt-2">{this.getCurrent()}</label>
        </span>
        
        <span className={this.state.boxRecord.fullyMatched+"btn ml-2 pl-2 row pt-1 pb-1"}   onClick={() => this.onBoxClick("fullyMatched")}>
        <label className="pl-0 ml-0 col-md-12 col-lg-12 d-flex  justify-content-center font_14 mr-0 pr-0 mb-2">Fully<br/> Matched</label> 
        <label className="col-md-12 col-lg-12 mb-1 mt-2 font_24"><b>0</b>  </label> 
        <label className="col-md-12 col-lg-12 mb-1 mt-2">{this.getCurrent()}</label>
        </span>
      </div> 
      <div className="col-lg-12 form-group form-inline mt-1 mb-1 pr-0 mr-0 pl-0 ml-2 ">
      <PaginationComponent page={this.state.page} pagebuttons={this.state.pagebuttons} loadData={this.getData} disable={this.state.disableTable} _this={this} />
      </div>
      </div>
      <div className="col-lg-12 row mt-1 pt-1 pb-2 ml-1 pl-1 mr-1 pr-1">
      <span className="col-lg-6 bg_color pb-0 mb-0 pt-2 pl-2 ml-0 font_14"> <b>Airline Invoices</b> </span><span className="col-lg-6 bg_color pt-2 pl-3 font_14"> <b>GSTR2A Invoices </b></span>
      <span className="col-lg-6 mt-0 pt-0 pb-2 ml-0 pl-0 mr-0 pr-0">
      <GridTableComponent cols={this.getColsTickets()} data={this.state.data} onRowClick={this.onSelectRecord} disable={this.state.disableTable} _this={this} />
      </span>
      <div className="col-lg-6 mt-0 pt-0 pb-2 ml-0 pl-0 mr-0 pr-0">
      <GridTableComponent cols={this.getColsAirline()} data={this.state.data} onRowClick={this.onSelectRecord} disable={this.state.disableTable} _this={this} />
      </div>
      </div>
         </React.Fragment>);

      }
   }

   onBoxClick = (boxName) => {
      let boxRecord={};
      if (boxName==="totalAirlineInvoice"){
         boxRecord={...this.boxClass};
         boxRecord.totalAirlineInvoice='recon_less_btn_click ';
         
      } else  if (boxName==="AiInWithnoGSTR"){
         boxRecord={...this.boxClass};
         boxRecord.AiInWithnoGSTR='recon_less_btn_click ';
         
      } else  if (boxName==="partiallyMatched"){
         boxRecord={...this.boxClass};
         boxRecord.partiallyMatched='recon_less_btn_click ';

      } else  if (boxName==="GSTRwithNoAiIn"){
         boxRecord={...this.boxClass};
         boxRecord.GSTRwithNoAiIn='recon_less_btn_click ';
         
      }else  if (boxName==="fullyMatched"){
         boxRecord={...this.boxClass};
         boxRecord.fullyMatched='recon_less_btn_click ';
      }
      console.log(boxRecord);
     this.setState({addModalShow:true});
     this.setState({ boxRecord, errorMessage: "", successMessage: "",mode : "add" })
  }
   getActionLabel=(itemId)=>{
      return(
         <div classNme="row d-flex justify-content-center"> 
 
         <span className="col-lg-3 btn btn-small btn-primary-outline ml-0 pl-0 pr-0 pt-0 pb-0 mt-0 mr-0"
            onClick={(event)=>this.openModalwithDetails(event)}>
            <img alt="Remove" src={process.env.PUBLIC_URL + "/assets/images/edit-row.png"}>
            </img>
         </span> 

<span className="col-lg-3 btn btn-small btn-primary-outline ml-0 pl-0 pr-0 pt-0 pb-0 mt-0  mr-0"
onClick={(event)=>this.handleDeleteItem(event,itemId)}>
<img alt="Remove" src={process.env.PUBLIC_URL + "/assets/images/big-trash.png"}>
</img>
</span>  

</div>
  )
}
  handleDeleteItem(event,itemId){
   //console.log("item id == " + itemId);
   confirmAlert({
       title: "Confirm", message: "Are you sure to delete Item?",
       buttons: [{ label: "Yes", className:"confirm_btn", onClick : ()  => this.handleItem(itemId) }, {
          label : "No",className:"confirm_btn","onClick" : () => {return false}
       }]
    })
   }

   handleItem=(itemId)=>{     
      //let idx = this.state.itemId.findIndex(s => s.id === record.itemId);
         //   console.log(idx);
                  const data = [...this.state.data];
                  if(data) {
                     const idx = data.findIndex(obj => obj.itemId===itemId);
                     if(idx > -1) {
                        let record = data[idx]; 
                        // console.log(this.props.itemId );
                           const availibility="R";
                     // record["itemId"]={...this.state.record.itemId}
                        record["availibilty"]=availibility;
                        this.setState({record})
                        //console.log(record);
                        
                        
                        const requestOptions = {
                           method: 'POST',
                           body : JSON.stringify(record),
                           headers: {
                              'Content-Type': 'application/json; charset=utf-8',
                              'Accept': "application/json",
                              'Authorization' : "JWT-" + localStorage.getItem("ros_token")
                           }
                        }
                        fetch(process.env.REACT_APP_ROS_BASE_URL + '/item', requestOptions)
                        .then(response => response.json())
                        .then(res => this.handleDeleteRecord(res))
                        .catch(err => console.error(err));
                     }
                  }
               
                  }
            handleDeleteRecord=(res) => {
      
               if (res["success"]) {
                  this.handleMsg("Item delete successfully!", "s");
                  this.getData(this.state.page)
         
               } else {
                 return false;
                  }
                  this.handleMsg("", "e");
            }
            handleSortFieldname=(event)=>{
               const sortFieldName = event.target.value;
               this.setState({ sortFieldName: sortFieldName });
            }
            handleSortDirection=(event)=>{
               const sortDirection = event.target.value;
               this.setState({ sortDirection: sortDirection });
            }
   handleSearchTextOnChange = (event) => {
      const searchText = event.target.value;
      this.setState({ searchText: searchText });
   }

   handleSearchClick() {
      
      let page = { ...this.state.page }
      page.number = 1;
      this.setState({page})
      this.getData(page);
      
   }

   getColsTickets = () => {
      return [{ header: "Invoice Date", key: "itemName", rowclasses : "text-center", headerclasses: "w-20 text-center" },
         { header: "PNR/Ticket No", key: "category.categoryName", headerclasses: "w-20" },
         { header: "Sector", key: "description", headerclasses: "w-20",rowclasses : "mr-2" },
         { header: "Amount" , key: "price", headerclasses : "w-15",rowclasses : "pr-2 ",fnValue:tofix_2},
         { header :"Status",key:"itemId",rowclasses : "text-center", headerclasses: "w-20 text-center" }];
   }

   getColsAirline = () => {
      return [{ header: "Invoice Date", key: "itemName", rowclasses : "text-center", headerclasses: "w-20 text-center" },
         { header: "PNR/Ticket No", key: "category.categoryName", headerclasses: "w-20" },
         { header: "Invoice No.", key: "description", headerclasses: "w-20",rowclasses : "mr-2" },
         { header: "Amount" , key: "price", headerclasses : "w-15",rowclasses : "pr-2 ",fnValue:tofix_2}];
   }
   getData=page => {
      const requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json",
            'Authorization' : "JWT-" + localStorage.getItem("ros_token")
         }
      };

      const queryString = "page=" + (page.number - 1) + "&size=" + page.size + "&searchText=" + this.state.searchText +"&sortDirection="+this.state.sortDirection +"&sortFieldName="+this.state.sortFieldName;

      fetch(process.env.REACT_APP_ROS_BASE_URL + '/items?' + queryString, requestOptions)
         .then(response => response.json())
         .then(res => this.refreshData(res))
         .catch(err => console.error(err));
   }

   refreshData = (res) => {
      if (res["success"]) {
         const data = res["result"].content;
         this.setPage(res["result"]);
         this.setState({ data });
        if (data && data.length > 0) {
            const record = { ...data[0] }
            this.setState({record})
        }
      } else {
         const data = [];
         this.setState({ data,errorMessage : "Failed to get item category list!",successMessage :"" });
         console.log("Error");
      }
   }

   setPage = (result) => {
      let page = { ...this.page }
      page.number = result["number"] + 1;
      page.first = result["first"];
      page.last = result["last"];
      page.numberOfElements = result["numberOfElements"];
      page.size = result["size"];
      page.totalElements = result["totalElements"];
      page.totalPages = result["totalPages"];
      this.setState({ page })
      if (page.number === 1) {
         adjustInitPageButton(this);
      }   
   }

   addNew = () => {
       const reconClass = "recon_btn_click "
      const headerTitle = "Add New Item"
      this.setState({addModalShow:true});
      this.setState({ reconClass,headerTitle, errorMessage: "", successMessage: "",mode : "add" })
   }
   onSelectRecord = record => {
      const headerTitle = "Item Detail"
      this.setState({ headerTitle, errorMessage: "", successMessage: ""})
      this.setState({record : {...record} })
      //this.setState({detail : true})
   }
   
   onDetailBack = (isModified) => {
      this.setState({ detail: false });
      if (isModified) {
         this.getData(this.state.page)
      }
   }

   handleMsg = ( msg,type="") => {
      if (type === "e") {
         this.setState({ errorMessage: msg })
      } else if (type === "s") {
         this.setState({ successMessage: msg })
      } else {
         this.setState({errorMessage : "",successMessage : ""})
      }
   }

   loadCategories = () => {
      const requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json",
            'Authorization': "JWT-" + localStorage.getItem("ros_token")
         }
      };

      fetch(process.env.REACT_APP_ROS_BASE_URL + '/categoryidname', requestOptions)
         .then(response => response.json())
         .then(res => this.refreshCategories(res))
         .catch(err => console.error(err));
   }

   refreshCategories(res) {
      if (res["success"]) {
      
         this.setState({categories : res["result"]})
       
       
      } else {
         this.setState({ errorMessage: "Error to get category list" })
      }
   }
}
 
export default AirlinevsGSTRComponent;