import React, { Component } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import { confirmAlert } from 'react-confirm-alert';

class UserDetailComponent extends Component {
   blankUser = {
      userId: undefined, name: undefined, username: undefined,userEmail:undefined,password : undefined,repassword : undefined, roleId: undefined,roleName:undefined };

   state = { 
      initRecord : this.blankUser,
      record: this.blankUser,
      allRoles: [],
   }

   constructor() {
      super();
      this.multiselectRef = React.createRef();
   }

   componentDidMount() {
      console.log(this.props.baseRecord);
      if (this.props.mode === "update") {
         if (this.props.baseRecord && this.props.baseRecord.roleId) {
           // this.fetchData(this.props.baseRecord.userId)
            this.fetchData(this.props.baseRecord._id)
         }
      } else if(this.props.mode==="add") {
         this.setState({ record: { ...this.blankRole }, roles: [] });
         
      }
      this.loadRoles();
   }
   render() { 
      return ( 
         <div className="card pt-1 pb-2 ml-2">
            <form>
               <div className="row ml-2 mr-2 mt-4 d-flex justify-content-center">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-2 mb-0">
                     <div className="form-group form-inline required">
                        <label className="control-label col-lg-3 col-md-3 col-sm-10 col-10">Name</label>
                        <input type="text" className="form-control col-9 small text-sm required" required
                           value={this.state.record.name || ""} onChange={(event) => this.handleOnChangeName(event)}></input>
                     </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-1 mb-0">
                     <div className="form-group form-inline required">
                        <label className="control-label col-lg-3 col-md-3 col-sm-10 col-10">User Name</label>
                        <input type="text" className="form-control col-9 small text-sm required" required
                           value={this.state.record.username || ""} onChange={(event) => this.handleOnChangeUserName(event)}></input>
                     </div>
                  </div>
                  {this.passwordComponent()}
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-1 mb-0">
                     <div className="form-group form-inline required">
                        <label className="control-label col-lg-3 col-md-3 col-sm-10 col-10 ">Email</label>
                        <input type="email" className="form-control col-9 small text-sm required" required
                           value={this.state.record.userEmail || ""} onChange={(event) => this.handleOnChangeUserEmail(event)}></input>
                     </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-1 mb-0">
                     <div className="form form-group form-inline required">
                        <label className="control-label col-lg-3 col-md-3 col-sm-10 col-10">Roles</label>
                        <div className="col-9 small pl-0 pr-0">
                           <Multiselect
                              options= {this.state.allRoles}// Options to display in the dropdown
                              selectedValues={this.props.userRoles} // Preselected value to persist in dropdown
                              onSelect={this.onSelectRoles} // Function will trigger on select event
                              onRemove={this.onRemoveRoles} // Function will trigger on remove event
                              displayValue="roleName" // Property name to display in the dropdown options
                              showCheckbox={true}
                              closeOnSelect={false}
                              ref={this.multiselectRef}
                              
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </form>
            
            <div className="col-lg-8 col-md-8 col-sm-10 col-10 ml-2 mr-2 pt-1 mt-4 mb-2 row">
               <div className="col-lg-4 col-md-4 col-sm-4 col-4 div-sep-btn"></div>
               <div className="col-lg-8 col-md-8 col-sm-8 col-8 d-flex justify-content-center ">
               <button className="btn btn-sm confirm_btn w-100px" disabled = {this.disableSave()} onClick= {() => this.handleSaveClick()}>Save</button>
               <button className="btn btn-sm confirm_btn w-100px ml-2" onClick={()=> this.handleCancelClick()}>Cancel</button>
               </div>
            </div>
         </div>
      );
   }

   passwordComponent = () => {
      let htmlele = []
      if (this.props.mode === "add") {
         htmlele.push(
            <div key="password" className="col-lg-12 col-md-12 col-sm-12 col-12 mt-1 mb-0">
               <div className="form-group form-inline required">
                  <label className="control-label col-lg-3 col-md-3 col-sm-10 col-10">Password</label>
                  <input type="password" className="form-control col-9 small text-sm required" required
                     value={this.state.record.password || ""} onChange={(event) => this.handleOnChangePassword(event)}></input>
               </div>
            </div>
         );
         htmlele.push(
            <div key="repassword" className="col-lg-12 col-md-12 col-sm-12 col-12 mt-1 mb-0">
               <div className="form-group form-inline required">
                  <label className="control-label col-lg-3 col-md-3 col-sm-10 col-10">Confirm Password</label>
                  <input type="password" className="form-control col-9 small text-sm required" required
                     value={this.state.record.repassword || ""} onChange={(event) => this.handleOnChangeRePassword(event)}></input>
               </div>
            </div>
         );
      }
      return htmlele
   }

   handleOnChangeName = (event) => {
      this.handleMsg("", "");
      let record = { ...this.state.record }
      record.name = event.target.value;
      this.setState({record})
   }

   handleOnChangeUserName = (event) => {
      this.handleMsg("", "");
      let record = { ...this.state.record }
      record.username = event.target.value;
      this.setState({ record });
   }

   handleOnChangeUserEmail = (event) => {
      this.handleMsg("", "");
      let record = { ...this.state.record }
      record.userEmail = event.target.value;
      
      this.setState({ record });
   }

  

   handleOnChangePassword = (event) => {
      this.handleMsg("", "");
      let record = { ...this.state.record }
      record.password = event.target.value;
      this.setState({ record });
   }
   
   handleOnChangeRePassword = (event) => {
      this.handleMsg("", "");
      let record = { ...this.state.record }
      record.repassword = event.target.value;
      this.setState({ record });
   }

   handleMsg = (msg, type = "") => {
      if (this.props.handleMsg) {
         this.props.handleMsg(msg, type);
      }
   }
         
   disableSave = () => {
      if (this.isModified()) {
         return false;
      } else {
         return true;
      }
   }
   isModified = () => {
      if (this.state.record.name !== this.state.initRecord.name) {
         return true;
      } else if (this.state.record.username !== this.state.initRecord.username) {
         return true;
      } else if (this.state.record.userEmail !== this.state.initRecord.userEmail) {
         return true;
      } else if (this.isModifiedRoles()) {
         return true;
      }

      return false;
   }

   isModifiedRoles() {
      const oldRoles = this.state.initRecord.roleId;
      const newRoles = this.state.roles

      if ((!oldRoles) && (!newRoles)) {
         return false;
      } else if ((!oldRoles) && newRoles) {
         return true;
      } else if (oldRoles && (!newRoles)) {
         return true;
      } else if (oldRoles.length !== newRoles.length) {
         return true;
      } else {
         let found = true;
         for (let i = 0; i < oldRoles.length && found; i++) {
            const filteredRole = newRoles.find(itm => itm.roleId === oldRoles[i]);
            if (!filteredRole) {
               found=false
            }
         }
         if (!found) {
            return true;
         }
      }
      return false;
   }

   fetchData =(id) => {
      const requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json",
            'Authorization' : "JWT-" + localStorage.getItem("ros_token")
         }
      };

      fetch(process.env.REACT_APP_ROS_BASE_URL + '/user?userId=' + id, requestOptions)
         .then(response => response.json())
         .then(res => this.refreshData(res))
         .catch(err => console.error(err));
   }

   refreshData = (res) => {
      if (res["success"]) {
         const record = res["result"];
         const userroles = [ ...record.roleId ]
         let roles = [];
         if (userroles) {
            for (let i = 0; i < userroles.length; i++) {
               const roleId = userroles[i];
               let roleName = undefined;
               if (this.props.roles) {
                  const filterRole = this.props.roles.find(itm => itm.roleId === roleId);
                  if (filterRole) {
                     roleName = filterRole.roleName
                  }
               }
               roles.push({roleId :roleId, roleName : roleName})
            }
         }
         const initRecord = {...record,roleId : [...record.roleId] }
         this.setState({record,initRecord, roles})
      } else {
         this.handleMsg("Error to get user detail !","e")
      }
   }

   handleCancelClick = () => {
      if (this.props.onBack) {
         if (this.isModified()) {
            confirmAlert({
               title: "Confirm", message: "Are you sure to exit?",
               buttons: [{ label: "Yes", className:"confirm_btn_popup",onClick: () => this.props.onBack(false) }, {
                  label: "No", className:"confirm_btn_popup", "onClick": () => { return false }
               }]
            })
         } else {
            this.props.onBack(false);
         }
      }
   }

   onSelectRoles = (selectedList, selectedItem) => {
      this.setRoles(selectedList);
   }

   onRemoveRoles = (selectedList, removeItem) => {
      this.setRoles(selectedList);
   }

   setRoles = selectedList => {
      let roles = [];
      for (let i = 0; i < selectedList.length; i++) {
         roles.push(selectedList[i])
      }
      this.setState({ roles:roles })
   }

   handleSaveClick = () => {
      var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      let validationpass=true
      this.handleMsg("", "");
      if (!(this.state.record.name && this.state.record.name !== "")) {
         this.handleMsg("Please enter name!", "e")
         validationpass = false;
      } else if (!(this.state.record.username && this.state.record.username !== "")) {
         this.handleMsg("Please enter user name!", "e")
         validationpass = false;
      } else if (!(this.state.record.userEmail && this.state.record.userEmail !== "")) {
         this.handleMsg("Please enter email!", "e")
         validationpass=false
      }else if ((this.state.record.userEmail !== "" && reg.test(this.state.record.userEmail) === false)) {
         this.handleMsg("Please enter a valid email!", "e")
         validationpass=false
      } 
      else if (this.props.mode === "add") {
         if ((!this.state.record.password) || this.state.record.password === "") {
            this.handleMsg("Please password!", "e")
            validationpass = false;
         } else if (this.state.record.password !== this.state.record.repassword) {
             this.handleMsg("Password and Confirm password mismatch!", "e")
            validationpass = false;           
         }
      }
      if(validationpass) {
         const roles = this.multiselectRef.current.getSelectedItems();
         if (!(roles && roles.length > 0)) {
            this.handleMsg("Please select role!","e")
         } else {
            confirmAlert({
               title: "Confirm", message: "Are you sure to save user?",
               buttons: [{ label: "Yes", className:"confirm_btn_popup",onClick : ()  => this.saveRecord() }, {
                  label : "No", className:"confirm_btn_popup","onClick" : () => {return false}
               }]
            })
         }
      }
   }
   saveRecord = () => {
      const userroles = this.multiselectRef.current.getSelectedItems();
      let roleId = []
      for (let i = 0; i < userroles.length; i++) {
         roleId.push(userroles[i].roleId)
      }
      let record = { ...this.state.record }
      record.roleId = [...roleId];

      const requestOptions = {
         method: 'POST',
         body : JSON.stringify(record),
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json",
            'Authorization' : "JWT-" + localStorage.getItem("ros_token")
         }
      };

      fetch(process.env.REACT_APP_ROS_BASE_URL + '/user', requestOptions)
         .then(response => response.json())
         .then(res => this.afterSave(res))
         .catch(err => console.error(err));
   }
   afterSave = (res) => {
      if (res["success"]) {
         let record = { ...this.state.record };
         record.userId = res["result"];
         this.setState({ record });
         this.handleMsg("User saved successfully!", "s");
         if (this.props.onBack) {
            this.props.onBack(true);
         }
      } else {
         this.handleMsg("Error to save role!", "e");
      }
   }

   loadRoles = () => {
      const requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json",
            'Authorization': "JWT-" + localStorage.getItem("ros_token")
         }
      };

      fetch(process.env.REACT_APP_ROS_BASE_URL + '/roleidname', requestOptions)
         .then(response => response.json())
         .then(res => this.refreshRoles(res))
         .catch(err => console.error(err));
   }

   refreshRoles(res) {
      if (res["success"]) {
         this.setState({ allRoles: res["result"] })
      } else {
         console.error("failed to get roles!")
      }
   }

}
 
export default UserDetailComponent;