import React, { Component } from 'react';
import './login.css';
import { Modal, Button } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert'; // Import




class Login extends Component {
   state = {
      user: { username: "", password: "" },
      loginerrormsg: "",
      sendusername: "",
      modal: true,
      handleClose: false,
      modalIsOpen: false,
      setIsOpen: false,
      errorMessage: "",
      modalopen: false

   }

   handleUserChangeEvents = (event) => {
      let user = { ...this.state.user }
      user.username = event.target.value
      this.setState({ user });
      this.setState({ loginerrormsg: "" });
      //handle change events
   }
   handleSubmitevents(event) {

      const requestOption = {
         method: "POST",
         body: JSON.stringify(this.state.user),
         headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': "application/json"
         }
      }
      fetch(process.env.REACT_APP_ROS_BASE_URL + "/token/generate-token", requestOption)
         .then(response => response.json())
         .then(res => this.setLoginInfo(res))
         .catch(err => {
            const loginerrormsg = "Server error!";
            this.setState({ loginerrormsg });
            console.log(err);
         });
      // handle submit events
   }

   openModal() {
      this.state.setIsOpen = !this.state.setIsOpen;
   }



   closeModal = (event) => {

      this.setState({ sendusername: "" });
      this.setState({ modalIsOpen: false })
      this.setState({ modalopen: false })
      this.setState({ errorMessage: "" })

   }
   setLoginInfo(res) {
      if (res["success"]) {
         const result = res["result"];
         localStorage.setItem("user", JSON.stringify({ username: result.username, name: result.name }));
         localStorage.setItem("ros_token", result.token);
         this.props.history.push("/portal/home")
      } else {
         const loginerrormsg = "Invalid user or password";
         this.setState({ loginerrormsg });
      }

   }

   handlePasswordChange = (event) => {
      let user = { ...this.state.user }
      user.password = event.target.value
      this.setState({ user });
      this.setState({ loginerrormsg: "" });
   }
   handleChangeUsername = (event) => {
      event.preventDefault();
      let sendusername = event.target.value
      this.setState({ sendusername: sendusername });


   }
   render() {
      return (

         <div className="container h-100">
            <div className="d-flex justify-content-center h-100">
               <div className="user_card">
                  <p className="Login_header">Business Operations Transformation</p>
                  {/*<img className="header_logo" alt="" src={process.env.PUBLIC_URL + "/assets/images/abot_logo.png"}/>*/}
                  <div className="d-flex justify-content-center form_container mt-2">
                     <form>
                        <div className="input-group mb-3">
                           <div className="input-group-append">
                              <span className="input-group-text"><i className="fas fa-user"></i></span>
                           </div>
                           <input type="text" name="" className="form-control input_user"
                              value={this.state.user.username} placeholder="username"
                              onChange={(event) => this.handleUserChangeEvents(event)}
                           ></input>
                        </div>
                        <div className="input-group mb-2">
                           <div className="input-group-append">
                              <span className="input-group-text"><i className="fas fa-key"></i></span>
                           </div>
                           <input type="password" name="" className="form-control input_pass"
                              value={this.state.user.password} placeholder="password"
                              onChange={(event) => this.handlePasswordChange(event)}
                           />
                        </div>
                        {this.setErrorDiv()}
                        <div className="form-group">
                           {/*  <div className="custom-control custom-checkbox">
								      <input type="checkbox" className="custom-control-input" id="customControlInline" />
								      <label className="custom-control-label text-white">Remember me</label>
      </div>*/}
                        </div>
                        <div className="d-flex justify-content-center mt-3 login_container">
                           <button type="button" name="button" className="btn login_btn"
                              onClick={() => this.handleSubmitevents()}>Login</button>
                        </div>
                        <div className="d-flex justify-content-center mt-3 login_container">

                           <a href="" className="fn_12 text-bold text-danger forgetpassword" onClick={(event) => this.modalOpen(event)}>
                              Forgot Password ?</a>
                        </div>
                        <br /><br /><br />
                        <span className=" d-flex justify-content-center pt-2 mt-5 home_welcome ">Powered By Linuxmantra!</span>

                        <Modal
                           show={this.state.modalIsOpen}
                           onHide={this.closeModal}
                        >

                           <Modal.Header className="modal-header" >
                              <Modal.Title className="modal-title h5">Forgot Password</Modal.Title>
                              <span className="close-modal-btn" onClick={this.closeModal}>×</span>
                           </Modal.Header>

                           {this.setErrorDiv1()}

                           <Modal.Body>
                              <div className="form-group required form-inline  ml-2 mt-1 mb-0">
                                 <label name="User" className="col-md-5 c-mw-px-200 control-label">Enter Username</label>

                                 <input type="text" className="form-control col-md-7 mb-1"
                                    value={this.state.sendusername}
                                    onChange={(event) => this.handleChangeUsername(event)}

                                    data-toggle="tooltip"
                                 >
                                 </input>

                              </div>
                           </Modal.Body>
                           <Modal.Footer>
                              <button className="btn-cancel confirm_btn" onClick={this.closeModal}>Close</button>
                              <button className="btn-continue confirm_btn " onClick={(event) => this.sendMail(event)}>Send</button>
                           </Modal.Footer>

                        </Modal>
                        <Modal className="modal-contentname"
                           show={this.state.modalopen}


                        >

                           <Modal.Header className="modal-header" >
                              <Modal.Title className="modal-title h5">SUCCESS</Modal.Title>
                              <span className="close-modal-btn" onClick={this.closeModal}>×</span>

                           </Modal.Header>

                           <Modal.Body>
                              &nbsp;&nbsp;
                              <p>Mail Sent! Please Check Your Mail!</p>
                           </Modal.Body>
                           <Modal.Footer>
                              <button className="btn-continue confirm_btn" onClick={this.closeModal}>Close</button>

                           </Modal.Footer>

                        </Modal>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      );
   }
   /* componentWillMount(){
       Modal.setAppElement('body');
    }
   */
   sendMail = () => {
      if (this.state.sendusername === "") {
         const errorMessage = "Please Enter Username"
         this.setState({ errorMessage: errorMessage })
      }
      else {
         const requestOptions = {
            method: 'GET',
            headers: {
               'Content-Type': 'application/json; charset=utf-8',
               'Accept': "application/json",
            }
         };
         const query = "username=" + this.state.sendusername;
         fetch(process.env.REACT_APP_ROS_BASE_URL + '/token/getpasswordlink?' + query, requestOptions)
            .then(res => res.json())
            .then(res => this.afterSend(res))
            .catch(err => console.error(err));

      }
   }
   setErrorDiv1() {
      if (this.state.errorMessage !== "") {
         return (
            <div className="row mb-2 ml-2">
               {this.state.errorMessage && <h6 className="alert-danger mt-0 ml-2 mr-4"> {this.state.errorMessage} <span className="float-right pointer-cursor" onClick={() => { this.setState({ errorMessage: "" }) }}>&#x2716;</span> </h6>}
            </div>);
      } else {
         return "";
      }

   }

   afterSend = (res) => {

      if (res["message"] === "Ok") {
         this.setState({ modalIsOpen: false });

         this.setState({ modalopen: true });

      }
      else if (res["message"] === "User Not Found") {
         const errorMessage = "Please Enter Correct Username"
         this.setState({ errorMessage: errorMessage });

      }
      else {
         const errorMessage = "server Error!"
         this.setState({ errorMessage: errorMessage });
      }


   }

   modalOpen = (event) => {
      event.preventDefault();
      this.setState({ modalIsOpen: true });
   }





   setErrorDiv() {
      if (this.state.loginerrormsg !== "") {
         return (
            <div className="row mb-2 ml-2">
               <span className="alert-danger small">{this.state.loginerrormsg}</span>
            </div>);
      } else {
         return "";
      }
   }
}

export default Login;