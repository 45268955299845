import React from 'react';
import './table.css';

const PageTitle = props => {
   let classes = "col-12 mb-0 pb-0 pl-0 ml-0 float-left"
   classes += props.classes ? props.classes : "";
   return (
      <div className={classes}>
          {props.title}
      </div>
   )
}

export default PageTitle