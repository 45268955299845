
export const tofix_2 = (Amt) => {
      var amt = Amt;
      if(amt){
        var decamtraw = Math.round(amt * 100)/100;
        var decamt = decamtraw.toFixed(2);  
        
      }else if(amt===null){
        decamt="-";
      }
      else {
        decamt=0;
      }
      return  decamt;
  }

export const Capitalize = (str) => {
  if (str)
  {    var finalstr = str.replace(/,(?=[^\s])/g, ", ");
    var splitStr = finalstr.toLowerCase().split(' ');
    
    for (var i = 0; i < splitStr.length; i++) {
     
       // You do not need to check if i is larger than splitStr length, as your for does that for you
       // Assign it back to the array
       splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
   }
   // Directly return the joined string
   return splitStr.join(' '); 
  } else {
    return "";
  }
    }


export  const convertTextToUpperCase = (text) => {
  if(text)
  {
    let uppertext =   text.toUpperCase();//To convert Upper Case
    return uppertext;
  }
     
      }
      

      